import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { isEmptyObject, toOnlyNumbersAndSpacesAndDashes } from '@/infrastructure/helper';
import { useTranslation } from 'react-i18next';
import Links from './links';
import Files from './files';
import { Employee } from '@/shared/types/api/employee.type';
import { Link, ApiFile, Account, Address } from '@/shared/types/api';
import { checkAccountFieldsLength, validateEmail } from '@/shared/util';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { getAccountLengthError } from '../import-error-handler';
import { useUnsavedStatus, useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import { useProfileDesignForUnits } from '@/infrastructure/hooks/useProfileDesignForUnits';
import { AccountImportData } from '@/shared/types/global';
import { Box, Divider, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { SectionRenderer } from '../SectionRenderer';
import { TextField, InputRestrictions } from './TextField';
import {
  AccountCircleOutlined,
  EditOutlined,
  EmailOutlined,
  Phone,
  TopicOutlined,
  WorkOutline,
} from '@mui/icons-material';
import LtActionButtonBar from '@/components/LtActionButtonBar';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import { useScimBlockedFieldsById } from '@/infrastructure/hooks/useScimBlockedFields';
import { Addresses } from './Addresses';

export type InputFormSaveProps = Omit<
  AccountImportData,
  {
    [K in keyof AccountImportData]: K extends `newlink${string}` ? K : never;
  }[keyof AccountImportData]
>;

const availableLanguages = [
  { value: 'en', tName: 'english' },
  { value: 'de', tName: 'german' },
] as const;

interface Props {
  namePrefix?: string;
  firstName?: string;
  lastName?: string;
  setNamePrefix?: Dispatch<SetStateAction<string>>;
  setFirstName?: Dispatch<SetStateAction<string>>;
  setLastName?: Dispatch<SetStateAction<string>>;
  onBackClick: () => void;
  handleSave: (props: InputFormSaveProps) => void;
  employee?: Employee;
  userLinksProps?: {
    userLinks: Array<Link>;
    setUserLinks: Dispatch<SetStateAction<Array<Link>>>;
    defaultLinkId: number;
    setDefaultLinkId: Dispatch<SetStateAction<number>>;
  };
  userFilesProps?: {
    userFiles: Array<ApiFile>;
    setUserFiles: Dispatch<SetStateAction<Array<ApiFile>>>;
  };
  employeeMainUnitId?: number;
  loading?: boolean;
}

const InputsForm = (props: Props) => {
  const { t } = useTranslation();
  const { notify: toast } = useLtNotifications();
  const { onBackClick, handleSave, employee, userLinksProps, userFilesProps, employeeMainUnitId } =
    props;

  const { profileDesign } = useProfileDesignForUnits(employeeMainUnitId);

  const { activeLanguage } = useAppTranslation();

  const addingEmployee = !employee;

  const [firstName, setFirstName] = useState(employee?.firstName || '');
  const [lastName, setLastName] = useState(employee?.lastName || '');

  const [namePrefix, setNamePrefix] = useState(employee?.namePrefix || '');
  const [position, setPosition] = useState(employee?.position || '');
  const [academicDegree, setAcademicDegree] = useState(employee?.academicDegree || '');
  const [academicDegree2, setAcademicDegree2] = useState(employee?.academicDegree2 || '');
  const [role, setRole] = useState(employee?.role || '');
  const [division, setDivision] = useState(employee?.division || '');
  const [company, setCompany] = useState(employee?.company || '');
  const [costCenter, setCostCenter] = useState(employee?.costCenter || '');
  const [mobilePhone, setMobilePhone] = useState(employee?.mobilePhone || '');
  const [workPhone, setWorkPhone] = useState(employee?.workPhone || '');
  const [homePhone, setHomePhone] = useState(employee?.homePhone || '');
  const [hotlinePhone, setHotlinePhone] = useState(employee?.hotlinePhone || '');
  const [assistantPhone, setAssistantPhone] = useState(employee?.assistantPhone || '');
  const [assistantEmail, setAssistantEmail] = useState(employee?.assistantEmail || '');
  const [assistantName, setAssistantName] = useState(employee?.assistantName || '');
  const [assistantGeneric, setAssistantGeneric] = useState(employee?.assistantGeneric || '');
  const [workFax, setWorkFax] = useState(employee?.workFax || '');
  const [email, setEmail] = useState(employee?.email || '');
  const [workEmail, setWorkEmail] = useState(employee?.workEmail || '');
  const [workEmail2, setWorkEmail2] = useState(employee?.workEmail2 || '');
  const [workUrl, setWorkUrl] = useState(employee?.workUrl || '');
  const [bioText, setBioText] = useState(employee?.bioText || '');

  const [preferredLang, setPreferredLang] = useState(employee?.preferredLang || activeLanguage);

  const [addresses, setAddresses] = useState<Address[]>([]);

  const { withUnsavedSetter } = useUnsavedStatusSetter();
  const { openDialogIfUnsaved } = useUnsavedStatus();

  const [tooltipMsg, setTooltipMsg] = useState('');

  const { isFieldBlocked: isFieldBlockedByScim } = useScimBlockedFieldsById(employee.id);

  useEffect(() => {
    const addressFields = [
      'addressee',
      'addressLine1',
      'addressLine2',
      'postCode',
      'city',
      'country',
      'label',
    ];

    const hasAddress1 = addressFields.some(field =>
      Boolean(employee && employee[`address1_${field}` as keyof Employee]),
    );

    const hasAddress2 = addressFields.some(field =>
      Boolean(employee && employee[`address2_${field}` as keyof Employee]),
    );

    const addresses = [
      hasAddress1 && {
        addressee: employee?.address1_addressee,
        addressLine1: employee?.address1_addressLine1,
        addressLine2: employee?.address1_addressLine2,
        postCode: employee?.address1_postCode,
        city: employee?.address1_city,
        country: employee?.address1_country,
        label: employee?.address1_label,
        addressTemplateId: employee.addressTemplate1Id || null,
      },
      hasAddress2 && {
        addressee: employee?.address2_addressee,
        addressLine1: employee?.address2_addressLine1,
        addressLine2: employee?.address2_addressLine2,
        postCode: employee?.address2_postCode,
        city: employee?.address2_city,
        country: employee?.address2_country,
        label: employee?.address2_label,
        addressTemplateId: employee.addressTemplate2Id || null,
      },
    ].filter(Boolean);
    setAddresses(addresses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee?.id]);

  useEffect(() => {
    addingEmployee ? setTooltipMsg(t('emailTooltipCreate')) : setTooltipMsg(t('emailTooltipEdit'));
  }, [addingEmployee, t]);

  const onSaveClick = () => {
    const [_address1, _address2] = addresses;

    let inputs: InputFormSaveProps = {
      firstName,
      lastName,
      position,
      academicDegree,
      academicDegree2,
      role,
      namePrefix,
      division,
      company,
      mobilePhone,
      workPhone,
      homePhone,
      hotlinePhone,
      assistantPhone,
      assistantName,
      assistantEmail,
      assistantGeneric,
      workFax,
      email,
      workEmail,
      workEmail2,
      workUrl,
      bioText,

      address1_addressee: _address1?.addressee || '',
      address1_addressLine1: _address1?.addressLine1 || '',
      address1_addressLine2: _address1?.addressLine2 || '',
      address1_postCode: _address1?.postCode || '',
      address1_city: _address1?.city || '',
      address1_country: _address1?.country || '',
      address1_label: _address1?.label || '',
      addressTemplate1Id: _address1?.addressTemplateId || null,

      address2_addressee: _address2?.addressee || '',
      address2_addressLine1: _address2?.addressLine1 || '',
      address2_addressLine2: _address2?.addressLine2 || '',
      address2_postCode: _address2?.postCode || '',
      address2_city: _address2?.city || '',
      address2_country: _address2?.country || '',
      address2_label: _address2?.label || '',
      addressTemplate2Id: _address2?.addressTemplateId || null,

      preferredLang: preferredLang || null,
      costCenter,
    };

    if (isFormValid(inputs)) handleSave(inputs);
  };

  const isFormValid = (inputs: Partial<Account>) => {
    if (!(inputs?.firstName?.length > 0) || !(inputs?.lastName?.length > 0)) {
      toast.error(t('errorMDFullName'));
      return false;
    }

    if (
      (addingEmployee && inputs?.email?.length === 0) ||
      (email?.length && email.length > 0 && !validateEmail(email))
    ) {
      toast.error(t('errorMDMail'));
      return false;
    }

    // check for account fields length rule
    const lengthError = checkAccountFieldsLength(inputs);
    if (!isEmptyObject(lengthError)) {
      const errorText = getAccountLengthError(lengthError);
      toast.error(errorText);
      return false;
    }

    return true;
  };

  return (
    <>
      <Box px={4}>
        <SectionRenderer label={t('contactInfo')}>
          <Box display='flex' flexDirection='column' gap={2}>
            <TextField
              label={t('academicDegree')}
              value={academicDegree}
              onChange={withUnsavedSetter(e => setAcademicDegree(e.target.value))}
              maxLength={200}
              startAdornment={<AccountCircleOutlined />}
              disabled={isFieldBlockedByScim('academicDegree')}
            />
            <TextField
              label={t('academicDegree2')}
              value={academicDegree2}
              onChange={withUnsavedSetter(e => setAcademicDegree2(e.target.value))}
              maxLength={200}
              startAdornment={<AccountCircleOutlined />}
              disabled={isFieldBlockedByScim('academicDegree2')}
            />
            <TextField
              label={t('namePrefix')}
              value={namePrefix}
              onChange={withUnsavedSetter(e => setNamePrefix(e.target.value))}
              maxLength={200}
              startAdornment={<AccountCircleOutlined />}
              disabled={isFieldBlockedByScim('namePrefix')}
            />
            <Box display='flex' gap={1}>
              <TextField
                label={t('firstName')}
                value={firstName}
                onChange={withUnsavedSetter(e => setFirstName(e.target.value))}
                maxLength={70}
                required
                inputRestrictions={InputRestrictions.ALPHABETS_WITH_SPECIFIC_CHARS}
                disabled={isFieldBlockedByScim('firstName')}
                startAdornment={<AccountCircleOutlined />}
              />
              <TextField
                label={t('lastName')}
                value={lastName}
                onChange={withUnsavedSetter(e => setLastName(e.target.value))}
                maxLength={70}
                required
                inputRestrictions={InputRestrictions.ALPHABETS_WITH_SPECIFIC_CHARS}
                disabled={isFieldBlockedByScim('lastName')}
                startAdornment={<AccountCircleOutlined />}
              />
            </Box>
            <TextField
              label={t('position')}
              value={position}
              onChange={withUnsavedSetter(e => setPosition(e.target.value))}
              maxLength={200}
              multiline
              startAdornment={<EditOutlined />}
              disabled={isFieldBlockedByScim('position')}
            />

            <TextField
              label={t('role')}
              value={role}
              onChange={withUnsavedSetter(e => setRole(e.target.value))}
              maxLength={200}
              multiline
              startAdornment={<EditOutlined />}
              disabled={isFieldBlockedByScim('role')}
            />

            <TextField
              label={t('division')}
              value={division}
              onChange={withUnsavedSetter(e => setDivision(e.target.value))}
              maxLength={200}
              multiline
              startAdornment={<EditOutlined />}
              disabled={isFieldBlockedByScim('division')}
            />
            <TextField
              label={t('company')}
              value={company}
              onChange={withUnsavedSetter(e => setCompany(e.target.value))}
              maxLength={70}
              multiline
              startAdornment={<WorkOutline />}
              disabled={isFieldBlockedByScim('company')}
            />
            <TextField
              label={t('workUrl')}
              value={workUrl}
              onChange={withUnsavedSetter(e => setWorkUrl(e.target.value))}
              startAdornment={<WorkOutline />}
              disabled={isFieldBlockedByScim('workUrl')}
            />
            <TextField
              label={t('bioText')}
              value={bioText}
              onChange={withUnsavedSetter(e => setBioText(e.target.value))}
              maxLength={200}
              multiline
              startAdornment={<TopicOutlined />}
              disabled={isFieldBlockedByScim('bioText')}
            />
            <TextField
              label={t('email1')}
              value={email}
              onChange={withUnsavedSetter(e => setEmail(e.target.value))}
              tooltip={tooltipMsg}
              disabled={isFieldBlockedByScim('email')}
              required={addingEmployee}
              startAdornment={<EmailOutlined />}
            />

            <TextField
              label={t('email2')}
              value={workEmail}
              onChange={withUnsavedSetter(e => setWorkEmail(e.target.value))}
              startAdornment={<EmailOutlined />}
              disabled={isFieldBlockedByScim('workEmail')}
            />
            <TextField
              label={t('email3')}
              value={workEmail2}
              onChange={withUnsavedSetter(e => setWorkEmail2(e.target.value))}
              startAdornment={<EmailOutlined />}
              disabled={isFieldBlockedByScim('workEmail2')}
            />
            <TextField
              label={t('mobilePhone.text')}
              value={mobilePhone}
              onChange={withUnsavedSetter(e =>
                setMobilePhone(toOnlyNumbersAndSpacesAndDashes(e.target.value)),
              )}
              isPhone
              startAdornment={<Phone />}
              disabled={isFieldBlockedByScim('mobilePhone')}
            />
            <TextField
              label={t('workPhone.text')}
              value={workPhone}
              onChange={withUnsavedSetter(e =>
                setWorkPhone(toOnlyNumbersAndSpacesAndDashes(e.target.value)),
              )}
              isPhone
              startAdornment={<Phone />}
              disabled={isFieldBlockedByScim('workPhone')}
            />
            <TextField
              label={t('homePhone.text')}
              value={homePhone}
              onChange={withUnsavedSetter(e =>
                setHomePhone(toOnlyNumbersAndSpacesAndDashes(e.target.value)),
              )}
              isPhone
              startAdornment={<Phone />}
              disabled={isFieldBlockedByScim('homePhone')}
            />
            <TextField
              label={t('hotlinePhone.text')}
              value={hotlinePhone}
              onChange={withUnsavedSetter(e =>
                setHotlinePhone(toOnlyNumbersAndSpacesAndDashes(e.target.value)),
              )}
              isPhone
              startAdornment={<Phone />}
              disabled={isFieldBlockedByScim('hotlinePhone')}
            />
            <TextField
              label={t('assistantName.text')}
              value={assistantName}
              onChange={withUnsavedSetter(e => setAssistantName(e.target.value))}
              startAdornment={<AccountCircleOutlined />}
              disabled={isFieldBlockedByScim('assistantName')}
            />
            <TextField
              label={t('assistantPhone.text')}
              value={assistantPhone}
              onChange={withUnsavedSetter(e =>
                setAssistantPhone(toOnlyNumbersAndSpacesAndDashes(e.target.value)),
              )}
              isPhone
              startAdornment={<Phone />}
              disabled={isFieldBlockedByScim('assistantPhone')}
            />
            <TextField
              label={t('assistantEmail.text')}
              value={assistantEmail}
              onChange={withUnsavedSetter(e => setAssistantEmail(e.target.value))}
              startAdornment={<EmailOutlined />}
              disabled={isFieldBlockedByScim('assistantEmail')}
            />
            <TextField
              label={t('assistantGeneric.text')}
              value={assistantGeneric}
              onChange={withUnsavedSetter(e => setAssistantGeneric(e.target.value))}
              startAdornment={<TopicOutlined />}
              disabled={isFieldBlockedByScim('assistantGeneric')}
            />
            <TextField
              label={t('workFax.text')}
              value={workFax}
              onChange={withUnsavedSetter(e =>
                setWorkFax(toOnlyNumbersAndSpacesAndDashes(e.target.value)),
              )}
              isPhone
              startAdornment={<Phone />}
              disabled={isFieldBlockedByScim('workFax')}
            />
            <TextField
              label={t('costCenter')}
              value={costCenter}
              onChange={withUnsavedSetter(e => setCostCenter(e.target.value))}
              maxLength={70}
              startAdornment={<WorkOutline />}
              disabled={isFieldBlockedByScim('costCenter')}
            />
          </Box>
        </SectionRenderer>
        <Addresses
          addresses={addresses}
          onChange={setAddresses}
          unitId={employeeMainUnitId}
          themeAddressesPosition={profileDesign.themeAddressesPosition}
        />
        <Divider sx={{ my: 3.2 }} />
        <SectionRenderer label={t('accountInfo')}>
          <FormControl fullWidth>
            <InputLabel id='lang-label'>{t('language')}</InputLabel>
            <Select
              labelId='lang-label'
              id='demo-simple-select'
              value={preferredLang}
              label={t('language')}
              onChange={withUnsavedSetter(e => setPreferredLang(e.target.value))}
              disabled={isFieldBlockedByScim('preferredLang')}
            >
              {availableLanguages.map(({ value, tName }) => {
                return (
                  <MenuItem key={value} value={value}>
                    {t(tName)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </SectionRenderer>
        <Links
          unitId={employeeMainUnitId}
          themeLinksPosition={profileDesign.themeLinksPosition}
          employee={employee}
          userLinksProps={userLinksProps}
          genericWebsiteColor={profileDesign.genericWebsiteColor}
        />
        <Files
          unitId={employeeMainUnitId}
          themeFilesPosition={profileDesign.themeFilesPosition}
          fileBoxColor={profileDesign.fileBoxColor}
          employee={employee}
          userFilesProps={userFilesProps}
        />
      </Box>
      <LtActionButtonBar
        saveAction={{ onClick: onSaveClick, loading: props.loading }}
        onCancel={() => openDialogIfUnsaved(onBackClick)}
        position='sticky'
      />
    </>
  );
};

export default InputsForm;
