import {
  EmailEditor as EasyEmailEditor,
  EmailEditorProvider,
  useEditorContext,
} from 'easy-email-editor';
import { StandardLayout } from 'easy-email-extensions';
import { Button, Box, Alert, useMediaQuery, Theme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useState, useEffect, useMemo } from 'react';
import {
  defaultContent,
  locales,
  defaultFonts,
  getCategories,
  toolbarStyles,
  TEST_EMAIL_ALLOWED_TEMPLATES,
  emailTemplateAttachments,
  EMAIL_EDITOR_INITIALIZED_EVENT,
} from './constants';
import {
  FontType,
  applyTooltips,
  containsSetPasswordUrlPlaceholder,
  getHtml,
  getMergeTags,
  getUniqueFonts,
  hideArcoNavbar,
  isEqual,
} from './utils';
import 'easy-email-editor/lib/style.css';
import 'easy-email-extensions/lib/style.css';
import '@arco-themes/react-easy-email-theme/css/arco.css';
import {
  getTemplate,
  sendTestEmail,
  updateTemplate,
} from '@/infrastructure/apis/md/customization/emailTemplates';
import { useAuth0 } from '@auth0/auth0-react';
import { doS3Upload } from '@/infrastructure/apis/aws';
import { useAppSelector } from '@/application/hooks';
import { Account, EmailEditorFixedContent } from '@/shared/types/api';
import { editorLocales } from './locales';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import DuplicationPopup from './DuplicationPopup';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import {
  DEFAULT_AUTH_CONNECTION,
  EmailTemplateAttachments,
  EmailTemplatesEnum,
  FEATURE,
  THEME_CONFIG_KEYS,
} from '@/shared/constants';
import { setSidebarCollapsed } from '@/application/actions/ui';
import { useDispatch } from 'react-redux';
import TestEmailSendPopup from './TestEmailSendPopup';
import {
  getFixedContent,
  getTestHtmlString,
  withFixedPostContent,
  withFixedPreContent,
} from '@/shared/util/emailTemplate';
import globalConfig from '../../../config/config';
import { BiSend } from 'react-icons/bi';
import SaveIcon from '@mui/icons-material/Save';
import withNav from '@/infrastructure/hoc/withNav';
import { customizeBlocks } from './blockUtils';
import { LoadingButton } from '@mui/lab';
import usePrivateThemeConfig from '@/infrastructure/hooks/usePrivateThemeConfig';
import teaserImage from '../../images/teaser/email-template-teaser.png';
import FeatureTeaser from '../md/teaser/featureTeaser';
import { usePrivateFeatureFlag } from '@/infrastructure/hooks/useFeatureFlags';
import { useProfileDesign } from '@/infrastructure/hooks/useProfileDesign';
import { PageContainer } from '@/components';
import { HelpButton } from '@/components/HelpSidebar';
import { useHideRichTextEditorToolbar } from './hooks/useHideRichTextEditorToolbar';
import { UpgradeAlert } from './UpgradeAlert';
import { TemplateSelector } from './TemplateSelector';
import { LanguageSelector } from './LanguageSelector';
import { AttachmentsSelector } from './AttachmentsSelector';
import { useUnsavedStatus, useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import { themeHasSSO } from '@/shared/util';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import { useAccesebility } from './hooks/useAccesebility';
import Loader from '@/components/Loader';

const { API_BASE_URL } = globalConfig;

// should be used as a seperate component to have access to the Editor Context.
const Editor = ({ hasFullAccess }) => {
  useHideRichTextEditorToolbar(hasFullAccess);
  const { setIsUnsaved } = useUnsavedStatusSetter();
  const { formState, initialized } = useEditorContext();
  const modifed = Object.values(formState.modified || {}).some(Boolean);

  useEffect(() => {
    if (initialized) document.dispatchEvent(new Event(EMAIL_EDITOR_INITIALIZED_EVENT));
  }, [initialized]);

  useEffect(() => {
    if (modifed) {
      setIsUnsaved(true);
    }
  }, [modifed, setIsUnsaved]);

  useEffect(() => {
    if (!hasFullAccess) hideArcoNavbar();
  }, [hasFullAccess]);

  return <EasyEmailEditor />;
};

const EmailTemplates = () => {
  useAccesebility();
  const { tier, isFeatureAllowed } = useTierInfo();
  const { notify: toast } = useLtNotifications();
  const hasFullAccess = isFeatureAllowed(FEATURE.EMAIL_EDITOR_FULL);

  const { config: allowedEmailTemplates, loading: allowedEmailTemplatesLoading } =
    usePrivateThemeConfig<EmailTemplatesEnum[]>(
      THEME_CONFIG_KEYS.ALLOWED_EMAIL_TEMPLATES_IN_EDITOR,
    );
  const flag_emailTemplatesDuplication = usePrivateFeatureFlag('emailTemplatesDuplication');
  const flag_invitationEmailV2 = usePrivateFeatureFlag('invitationEmailV2');

  const [selectedOptions, setSelectedOptions] = useState({
    template: null,
    locale: 'standard',
  });
  useEffect(() => {
    if (allowedEmailTemplates?.length) {
      setSelectedOptions(prev => ({ ...prev, template: allowedEmailTemplates[0] }));
    }
  }, [allowedEmailTemplates]);

  const sendTestEmailDisabled = useMemo(
    () => !TEST_EMAIL_ALLOWED_TEMPLATES.includes(selectedOptions.template),
    [selectedOptions.template],
  );
  const { setIsUnsaved } = useUnsavedStatusSetter();

  const [loading, setLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [templateContent, setTemplateContent] = useState(defaultContent);
  const [subject, setSubject] = useState<string>('');
  const [isDuplicating, setIsDuplicating] = useState<boolean>(false);
  const [isDuplicationModalOpened, setIsDuplicationModalOpened] = useState<boolean>(false);
  const [isTestEmailSendModalOpened, setIsTestEmailSendModalOpened] = useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth0();
  const account = useAppSelector<Account>(state => state.account);
  const [testEmailRecepient, setTestEmailRecepient] = useState<string>(
    typeof account.email === 'string' ? account.email : '',
  );

  const [attachments, setAttachments] = useState<EmailTemplateAttachments[]>([]);

  const { profileDesign } = useProfileDesign();

  const { openDialogIfUnsaved } = useUnsavedStatus();

  const themeFont = useMemo(() => account.theme?.font, [account]);

  const availableAttachments = useMemo(
    () => emailTemplateAttachments[selectedOptions.template] || [],
    [selectedOptions.template],
  );

  useEffect(() => {
    const appliedFonts = templateContent.data.value.fonts || [];
    const newFonts = getUniqueFonts([
      ...appliedFonts,
      ...defaultFonts,
      ...(themeFont ? [{ href: themeFont.url, name: themeFont.name }] : []),
    ]);
    if (!isEqual<FontType>(newFonts, appliedFonts)) {
      setTemplateContent({
        ...templateContent,
        data: {
          ...templateContent.data,
          value: {
            ...templateContent.data.value,
            fonts: newFonts,
          },
        },
      });
    }
  }, [themeFont, templateContent]);

  const { t, activeLanguage } = useAppTranslation();
  const editorLocale = editorLocales[activeLanguage];
  const selectedLocale = selectedOptions.locale === 'standard' ? '' : selectedOptions.locale;

  const { config: footerConfig } = usePrivateThemeConfig(
    THEME_CONFIG_KEYS.EMAIL_DEFAULT_FOOTER,
    selectedLocale,
  );
  const footer = footerConfig?.html || '';

  const { config: fixedContent } = usePrivateThemeConfig<EmailEditorFixedContent[]>(
    THEME_CONFIG_KEYS.EMAIL_EDITOR_FIXED_CONTENT,
    selectedLocale,
  );

  useEffect(() => {
    if (loading) return;
    applyTooltips(t);
  }, [loading, t]);

  useEffect(() => {
    customizeBlocks(hasFullAccess);
  }, [hasFullAccess]);

  const initialValues = {
    content: templateContent,
    subject: subject || '',
  };

  useEffect(() => {
    const fetchTemplate = async () => {
      setLoading(true);
      try {
        const {
          data: { data },
        } = await getTemplate(getAccessTokenSilently, selectedOptions);
        setTemplateContent(Object.keys(data.content).length > 0 ? data.content : defaultContent);
        setSubject(data.subject);
        setAttachments(data.attachments || []);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    if (isDuplicating) {
      setIsDuplicating(false);
      setIsDuplicationModalOpened(false);
    } else {
      fetchTemplate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions, getAccessTokenSilently]);

  const handleSaveClick = async values => {
    setIsSaving(true);
    try {
      const html = getHtml(values);
      const content = values.content;
      const subject = values.subject || '';
      await updateTemplate(getAccessTokenSilently, {
        html,
        content,
        template: selectedOptions.template,
        locale: selectedLocale,
        subject,
        attachments,
      });
      toast.success(t('emailTemplates.successfullySaved'));
      setTemplateContent(content);
      setSubject(subject);
      setIsUnsaved(false);
    } catch (error) {
      toast.error(t('emailTemplates.errorSaving'));
    }
    setIsSaving(false);
  };

  const imageUploadHandler = async (file: File): Promise<string> => {
    try {
      return await doS3Upload(`themes/${account.theme.name}`, file, getAccessTokenSilently);
    } catch (error) {
      toast.error(t('emailTemplates.errorUploadingImage'));
      return '';
    }
  };

  const handleDuplicate = () => {
    setIsDuplicating(true);
    setIsDuplicationModalOpened(true);
  };

  const handleDuplicationModalClose = () => {
    setIsDuplicating(false);
    setIsDuplicationModalOpened(false);
  };

  const handleDuplicationModalConfirm = (values: typeof selectedOptions) => {
    setSelectedOptions(values);
  };

  const prepareHtml = (html: string) => {
    const { fixedPreContent, fixedPostContent } = getFixedContent(
      fixedContent,
      selectedOptions.template,
      tier,
      hasFullAccess,
    );

    let htmlContent = html;
    if (fixedPreContent) htmlContent = withFixedPreContent(htmlContent, fixedPreContent);
    if (fixedPostContent) htmlContent = withFixedPostContent(htmlContent, fixedPostContent);

    htmlContent = withFixedPostContent(htmlContent, footer);

    return htmlContent;
  };

  const handlePreview = (html: string) => {
    const htmlContent = prepareHtml(html);
    const vcardUrl = `${API_BASE_URL}account/download/contact?accountId=${account.id}`;
    return getTestHtmlString(htmlContent, account, profileDesign, {
      vcardUrl,
    });
  };

  const [sendTestEmailLoading, setSendTestEmailLoading] = useState(false);
  const handleSendTestEmail = async values => {
    setSendTestEmailLoading(true);
    try {
      const html = prepareHtml(getHtml(values));
      await sendTestEmail(getAccessTokenSilently, {
        html,
        email: testEmailRecepient,
        subject: values.subject || '',
        attachments,
      });
      toast.success(t('emailTemplates.testEmailSuccessfullySent'));
    } catch (error) {
      toast.error(t('emailTemplates.errorSendingTestEmail'));
    }
    setIsTestEmailSendModalOpened(false);
    setSendTestEmailLoading(false);
  };

  const templateChangeHandler = (template: EmailTemplatesEnum) => {
    openDialogIfUnsaved(() => setSelectedOptions(prev => ({ ...prev, template })));
  };

  const languageChangeHandler = (locale: string) => {
    openDialogIfUnsaved(() => setSelectedOptions(prev => ({ ...prev, locale })));
  };

  const mergeTags = useMemo(
    () =>
      (tags => (Object.keys(tags).length > 0 ? tags : undefined))(
        getMergeTags(selectedOptions.template),
      ),
    [selectedOptions.template],
  );

  const isXl = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'), { noSsr: true });

  const flag_iam = usePrivateFeatureFlag('iam');

  return loading || allowedEmailTemplatesLoading ? (
    <Loader />
  ) : (
    <PageContainer
      maxWidth='xl'
      px={2}
      pt={{ xs: 2, xl: 4.5 }}
      helpSidebarConfigKey={isXl ? 'help-sidebar-email-editor' : undefined}
      helpButtonBoxProps={{
        mb: { xs: 0, xl: 1 },
      }}
    >
      {!hasFullAccess && <UpgradeAlert description={t('emailTemplates.upgradeAlertBody')} />}
      <EmailEditorProvider
        key={`${selectedOptions.template}-${selectedOptions.locale}`}
        data={initialValues}
        height={'calc(100vh - 72px)'}
        autoComplete
        dashed={false}
        onUploadImage={imageUploadHandler}
        mergeTagGenerate={tag => (tag.startsWith('<%') ? tag : `$${tag}$`)}
        mergeTags={mergeTags}
        locale={editorLocale}
        onBeforePreview={handlePreview}
        enabledMergeTagsBadge
      >
        {({ values }) => {
          return (
            <>
              <Grid container columnSpacing={2} rowSpacing={1} mb={3} sx={toolbarStyles}>
                <Grid item xl={3} xs={5}>
                  <TemplateSelector
                    value={selectedOptions.template}
                    options={allowedEmailTemplates}
                    onChange={templateChangeHandler}
                  />
                </Grid>
                <Grid item xl={1} xs={3}>
                  <LanguageSelector
                    value={selectedOptions.locale}
                    options={locales}
                    onChange={languageChangeHandler}
                  />
                </Grid>

                {isXl ? null : (
                  <Grid item xs={4} display='flex' justifyContent='flex-end'>
                    <HelpButton configKey='help-sidebar-email-editor' />
                  </Grid>
                )}

                <Grid item xl={4} xs={5}>
                  {availableAttachments?.length > 0 && (
                    <AttachmentsSelector
                      options={availableAttachments}
                      value={attachments}
                      onChange={value => setAttachments(value)}
                    />
                  )}
                </Grid>

                <Grid item xl={4} xs={7} display='flex'>
                  <Box
                    display='flex'
                    justifyContent='flex-end'
                    alignContent='end'
                    mt='auto'
                    ml='auto'
                    gap={2}
                  >
                    {flag_emailTemplatesDuplication && (
                      <Button onClick={handleDuplicate}>{t('duplicate')}</Button>
                    )}
                    <Button
                      variant='outlined'
                      onClick={() => setIsTestEmailSendModalOpened(true)}
                      startIcon={<BiSend />}
                      disabled={sendTestEmailDisabled}
                      aria-disabled={sendTestEmailDisabled}
                    >
                      {t('emailTemplates.sendTestEmail')}
                    </Button>
                    <LoadingButton
                      loading={isSaving}
                      onClick={() => handleSaveClick(values)}
                      startIcon={<SaveIcon />}
                      loadingPosition='start'
                      aria-busy={isSaving}
                    >
                      {t('save')}
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
              {flag_invitationEmailV2 &&
                selectedOptions.template === EmailTemplatesEnum.WELCOME_EMAIL_TEMPLATE &&
                (flag_iam
                  ? !themeHasSSO(account.theme)
                  : account.theme.themeInternal?.authConnection === DEFAULT_AUTH_CONNECTION) &&
                !containsSetPasswordUrlPlaceholder(getHtml(values)) && (
                  <Alert severity='warning' variant='standard' sx={{ mb: 2 }}>
                    {t('emailTemplates.noSetPasswordUrlAlert')}
                  </Alert>
                )}
              <Box
                sx={{
                  '*': {
                    '&:focus-visible': {
                      border: '2px solid black',
                      boxSizing: 'border-box',
                    },
                  },
                }}
              >
                <StandardLayout
                  categories={getCategories(selectedOptions.template, hasFullAccess, t)}
                  showSourceCode={false}
                >
                  <Editor hasFullAccess={hasFullAccess} />
                </StandardLayout>
              </Box>
              <TestEmailSendPopup
                open={isTestEmailSendModalOpened}
                onClose={() => setIsTestEmailSendModalOpened(false)}
                onConfirm={() => handleSendTestEmail(values)}
                email={testEmailRecepient}
                setEmail={setTestEmailRecepient}
                loading={sendTestEmailLoading}
              />
            </>
          );
        }}
      </EmailEditorProvider>
      <DuplicationPopup
        open={isDuplicationModalOpened}
        onClose={handleDuplicationModalClose}
        onConfirm={handleDuplicationModalConfirm}
        allowedEmailTemplates={allowedEmailTemplates}
      />
    </PageContainer>
  );
};

const Page = () => {
  const { t } = useAppTranslation();
  const { isFeatureAllowed } = useTierInfo();
  const isEmailTemplateFeatureAllowed = useMemo(
    () => isFeatureAllowed(FEATURE.EMAIL_TEMPLATES),
    [isFeatureAllowed],
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmailTemplateFeatureAllowed) {
      dispatch(setSidebarCollapsed(true));
      return () => {
        dispatch(setSidebarCollapsed(false));
      };
    }
  }, [dispatch, isEmailTemplateFeatureAllowed]);

  if (!isEmailTemplateFeatureAllowed) {
    return (
      <FeatureTeaser text={t('upgradeTeaser.feature.emailTemplates')} imageSrc={teaserImage} />
    );
  }

  return <EmailTemplates />;
};

export default withNav(Page, { tTitle: 'emailTemplatesPageHeader' }, {});
