import { useAppTranslation } from '../../../../../../infrastructure/hooks/useAppTranslation';
import { useAppSelector } from '../../../../../../application/hooks';

import AddNewLinkPopup from '@/components/Profile/components/popups/add-new-link-popup';
import CreateEditLinkPopup from '@/components/Profile/components/popups/create-edit-link-popup';
import DeletePopup from '@/components/Profile/components/popups/delete-item-popup';

import usePopup from './hooks/usePopup';
import LinksUi from './components/links';
import { Employee } from '../../../../../../shared/types/api/employee.type';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Account, Link } from '../../../../../../shared/types/api';
import { saveCompanyDetailsLinks } from '../../../../../../application/actions/md/profiles/links';
import { Link as ApiLink } from '../../../../../../shared/types/api';
import { GlobalDataPosition } from '../../../../../../shared/types/global';
import { SectionRenderer } from '../../SectionRenderer';
import { useNotificationsStore } from '@/zustand/notificationsStore';
import { getDefaultLinks } from '@/infrastructure/apis/links';

interface Props {
  unitId?: number;
  employee?: Employee;
  userLinksProps?: {
    userLinks: Array<Link>;
    setUserLinks: Dispatch<SetStateAction<Array<Link>>>;
    defaultLinkId: number;
    setDefaultLinkId: Dispatch<SetStateAction<number>>;
  };
  setThemeLinks?(themeLinks: ApiLink[]): void;
  themeLinksPosition: GlobalDataPosition;
  setThemeLinksPosition?(position: GlobalDataPosition): void;
  loadingCompleted?: Function;
  genericWebsiteColor: string;
}
const Links = (props: Props) => {
  const { loadingCompleted, unitId } = props;
  const { t } = useAppTranslation();
  const { employee, userLinksProps } = props;

  const themeLinkTypes = useAppSelector(state => state.md.themeLinkTypes);
  const account = useAppSelector<Account>(state => state.account);
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();

  const usePopupProps = usePopup();
  const {
    showAddNewLink,
    showCreatePopup,
    showDeletePopup,
    deleteMode,
    selectedLinkRef,
    showAddNewLinkPopup,
    showCreateEditLinkPopup,
    onCreateLinkBackClick,
    onLinkTypeClick,
    onDeleteLinkClick,
    onClosePopups,
    checkForResponse,
  } = usePopupProps;

  const links = useMemo(
    () => userLinksProps?.userLinks?.sort((a, b) => a.order - b.order) || [],
    [userLinksProps?.userLinks],
  );

  const linkProps = { employee, links, userLinksProps, account, themeLinkTypes, t };

  const maxOrder = useMemo(
    () => links?.reduce((prev, cur) => (cur.order > prev ? cur.order : prev), -1) || 0,
    [links],
  );

  const [themeLinks, setThemeLinks] = useState<Link[]>([]);
  useEffect(() => {
    props.setThemeLinks && props.setThemeLinks(themeLinks);
  }, [props, themeLinks]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getDefaultLinks(getAccessTokenSilently, { unitId, applyHierarchy: true });
        setThemeLinks(res);
        loadingCompleted?.();
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [getAccessTokenSilently, loadingCompleted, unitId]);

  const saveLinks = async links => {
    const toastId = 'saveLinksDatabase';
    useNotificationsStore.getState().notify.loading('Saving to database', { id: toastId });
    setThemeLinks(links);
    await dispatch(
      saveCompanyDetailsLinks(
        links.map(item => {
          // @ts-ignore:next-line
          if (item.newItem) {
            delete item.id;
            // @ts-ignore:next-line
            delete item.newItem;
          }
          return item;
        }),
        getAccessTokenSilently,
        () => {
          useNotificationsStore.getState().notify.success('Success', { id: toastId });
        },
        () => {
          useNotificationsStore.getState().notify.error('error', { id: toastId });
        },
      ),
    );
  };

  const onSaveCompanydataSettings = async (linkItem: Link) => {
    const newLinks = [
      ...themeLinks,
      { ...linkItem, themeId: account.theme.id, order: themeLinks.length * 10 + 10, newItem: true },
    ];
    setThemeLinks(newLinks);
    saveLinks(newLinks);
  };

  return (
    <>
      {checkForResponse()}
      {showAddNewLink && (
        <AddNewLinkPopup
          newOrder={maxOrder + 10}
          onCloseClick={onClosePopups}
          onItemClick={item => showCreateEditLinkPopup(item)}
          onLinkTypeClick={linkType => onLinkTypeClick(linkType)}
          loading={false}
          linkTypes={themeLinkTypes}
          isCompanydataSettings={false}
          genericWebsiteColor={props.genericWebsiteColor}
        />
      )}
      {showCreatePopup && (
        <CreateEditLinkPopup
          employeeId={employee?.id}
          onCloseClick={onClosePopups}
          onBackClick={() => onCreateLinkBackClick()}
          onDeleteClick={() => onDeleteLinkClick()}
          item={selectedLinkRef.current}
          mode={selectedLinkRef.current.mode || selectedLinkRef.current.linkType.mode}
          isCompanydataSettings={false}
          // I SUSPECT THIS WILL FAIL
          onSaveCompanydataSettings={(linkItem: Link) =>
            onSaveCompanydataSettings({
              id: Math.floor(Math.random() * 1000),
              name: linkItem.name,
              link: linkItem.link,
              linkType: linkItem.linkType,
              linkTypeId: linkItem.linkTypeId,
            })
          }
          genericWebsiteColor={props.genericWebsiteColor}
          {...userLinksProps}
        />
      )}
      {showDeletePopup && (
        <DeletePopup
          employeeId={employee.id}
          onCloseClick={onClosePopups}
          item={selectedLinkRef.current}
          mode={deleteMode}
          {...userLinksProps}
        />
      )}
      <SectionRenderer label={t('links')} tooltipText={t('md.profileDesign.linksTooltip')}>
        <LinksUi
          unitId={unitId}
          themeLinksPosition={props.themeLinksPosition}
          maxOrder={maxOrder}
          popupProps={usePopupProps}
          showAddNewLinkPopup={showAddNewLinkPopup}
          onDeleteLinkClick={link => {
            selectedLinkRef.current = link;
            onDeleteLinkClick();
          }}
          genericWebsiteColor={props.genericWebsiteColor}
          {...linkProps}
        />
      </SectionRenderer>
    </>
  );
};

export default Links;
