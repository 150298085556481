import { Account, Address } from '../../../../shared/types/api';
import { Box, FormControl } from '@mui/material';
import { ACCOUNT_BIO_FIELDS_KEYS, AccountBioFields } from '@/shared/constants';
import { isAddressField } from '../utils';
import AddressRenderer from './AddressRenderer';
import BioTextField from './BioTextField';
import { useEffect, useState } from 'react';
import { getAddresses } from '@/infrastructure/apis/address';
import { useAuth0 } from '@auth0/auth0-react';
import { AddressBox } from '@/components/Addresses';
import { useTranslation } from 'react-i18next';
interface IProps {
  bioForm: Account;
  isFieldBlocked: (field: keyof Account, additionalConditions?: Array<boolean>) => boolean;
  unitId: number;
}

const EditBioForm: React.FC<IProps> = ({ bioForm, isFieldBlocked, unitId }) => {
  const { t } = useTranslation();

  const fields = (Object.keys(bioForm) as Array<keyof Account>)
    .filter((key): key is keyof AccountBioFields => ACCOUNT_BIO_FIELDS_KEYS.includes(key))
    .sort((a, b) => ACCOUNT_BIO_FIELDS_KEYS.indexOf(a) - ACCOUNT_BIO_FIELDS_KEYS.indexOf(b));

  const [address1Fields, address2Fields, restFields] = [
    fields.filter(field => isAddressField(field) && field.includes('address1')),
    fields.filter(field => isAddressField(field) && field.includes('address2')),
    fields.filter(field => !isAddressField(field)),
  ];

  const [defaultAddresses, setDefaultAddresses] = useState<Address[]>([]);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const addresses = await getAddresses(getAccessTokenSilently, {
          unitId,
          applyHierarchy: true,
        });
        setDefaultAddresses(addresses);
      } catch (e) {
        console.log(e);
      }
    };
    fetchAddresses();
  }, [getAccessTokenSilently, unitId]);

  return (
    <FormControl sx={{ width: '100%' }}>
      <Box display='flex' flexDirection='column' gap={3}>
        {restFields.map(field => (
          <BioTextField fieldName={field} readonly={isFieldBlocked(field)} />
        ))}
      </Box>
      {address1Fields.length && (
        <AddressRenderer addressFields={address1Fields} isFieldBlocked={isFieldBlocked} />
      )}
      {address2Fields.length && (
        <AddressRenderer addressFields={address2Fields} isFieldBlocked={isFieldBlocked} />
      )}
      <Box display='flex' flexDirection='column' gap={2} mt={2}>
        {defaultAddresses.map((address, index) => (
          <AddressBox key={index} address={address} chips={[t('default')]} />
        ))}
      </Box>
    </FormControl>
  );
};

export default EditBioForm;
