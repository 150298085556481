import { Auth0ContextInterface } from '@auth0/auth0-react';
import { withSilentAccessToken } from '../helper';
import axios from 'axios';
import config from '@/config/config';
import { AddressPayload } from '@/shared/types/api';

export const getAddresses = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  params: { unitId: number; applyHierarchy?: boolean },
) => {
  const { data: res } = await withSilentAccessToken(getAccessTokenSilently, token =>
    axios.get(config.API_BASE_URL + `addresses`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    }),
  );
  return res;
};

export const deleteAddress = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  params: { unitId: number },
  id: number,
) => {
  const { data: res } = await withSilentAccessToken(getAccessTokenSilently, token =>
    axios.delete(config.API_BASE_URL + `addresses/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    }),
  );

  return res;
};

export const createAddress = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  params: { unitId: number },
  payload: AddressPayload,
) => {
  const { data: res } = await withSilentAccessToken(getAccessTokenSilently, token =>
    axios.post(config.API_BASE_URL + `addresses`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    }),
  );
  return res;
};

export const updateAddress = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  params: { unitId: number },
  payload: AddressPayload,
) => {
  const { data: res } = await withSilentAccessToken(getAccessTokenSilently, token =>
    axios.put(config.API_BASE_URL + `addresses/${payload.id}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    }),
  );
  return res;
};

export const sortAddresses = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  payload: { ids: number[] },
) => {
  const { data: res } = await withSilentAccessToken(getAccessTokenSilently, token =>
    axios.post(config.API_BASE_URL + `addresses/sort`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  );
  return res;
};
