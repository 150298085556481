import React from 'react';
import LtIconButton from './lt-icon-button';
import { ThemeBoxStyle } from '@/shared/types/global';
import nl2br from 'react-nl2br';
import { Box, Typography, styled } from '@mui/material';

interface Props {
  headerText?: string;
  themeBoxStyle?: ThemeBoxStyle;
  flat?: boolean; // whether to render a card
  noPadding?: boolean;
  actionButton?: {
    icon: React.ReactElement;
    text: string;
    onClick?: () => void;
  };
  children: React.ReactNode;
}

const WhiteLayout = (props: Props): JSX.Element => {
  const { headerText, themeBoxStyle, actionButton, flat, noPadding, children } = props;
  const content = (
    <>
      {(headerText || actionButton) && (
        <WhiteLayoutHeader>
          {headerText && <WhiteLayoutHeaderText>{nl2br(headerText)}</WhiteLayoutHeaderText>}
          <WhiteLayoutHeaderAction>
            {actionButton && (
              <LtIconButton
                text={actionButton.text}
                onClick={actionButton.onClick}
                boxStyle={themeBoxStyle}
                icon={actionButton.icon}
                style={{ fontSize: '1.4rem' }}
              />
            )}
          </WhiteLayoutHeaderAction>
        </WhiteLayoutHeader>
      )}
      <Box>{children}</Box>
    </>
  );
  if (flat) {
    return <>{noPadding ? <div>{content}</div> : <PaddedWrapper>{content}</PaddedWrapper>}</>;
  } else {
    return <WhiteLayoutWrapper themeBoxStyle={themeBoxStyle}>{content}</WhiteLayoutWrapper>;
  }
};

export default WhiteLayout;

const PaddedWrapper = styled(Box)({
  padding: '0 2rem',
});

export const WhiteLayoutWrapper = styled(Box)(({ themeBoxStyle }) => ({
  background: '#ffffff',
  boxShadow: '0px 4px 4px 2px rgba(0, 0, 0, 0.04)',
  padding: '20px',
  margin: '24px 0',
  borderRadius: themeBoxStyle === 'rounded' ? '1rem' : '0',
}));

const WhiteLayoutHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '1.7rem',
});

const WhiteLayoutHeaderText = styled(Typography)({
  color: '#55595e',
});

const WhiteLayoutHeaderAction = styled(Box)({
  marginLeft: 'auto',
});
