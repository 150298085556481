import { Box, Divider, Typography } from '@mui/material';

type Props = React.PropsWithChildren<{
  title: string;
}>;
export const ContentWrapper = ({ title, children }: Props) => {
  return (
    <Box width={'100%'} px={2}>
      <Typography variant='h2'>{title}</Typography>
      <Divider sx={{ my: 2.4 }} />
      {children}
    </Box>
  );
};
