import { DeepReadonly } from '../../../types/util';
import { HidableConceptGroup } from './types';

export const HIDABLE_CONCEPTS = [
  {
    key: 'nonAdmin',
    tKey: 'nonAdmin',
    hidableConcepts: [
      {
        key: 'home',
        tKey: 'home',
        concept: 'page',
        children: [
          {
            key: 'businessCard',
            tKey: 'homePage.digitalBusinessCard',
            concept: 'pageContent',
          },
          {
            key: 'shareProfile',
            tKey: 'homePage.shareProfile.title',
            concept: 'pageContent',
          },
          {
            key: 'addToHomescreen',
            tKey: 'homePage.addAppToPhone.title',
            concept: 'pageContent',
          },
          {
            key: 'nfcCards',
            tKey: 'homePage.nfcCard.addAndManage',
            concept: 'pageContent',
          },
          {
            key: 'analytics',
            tKey: 'homePage.analytics.title',
            concept: 'pageContent',
          },
          {
            key: 'academy',
            tKey: 'homePage.academy.title',
            concept: 'pageContent',
          },
          {
            key: 'contacts',
            tKey: 'homePage.leads.recentContacts',
            concept: 'pageContent',
          },
        ],
      },
      { key: 'editProfile', tKey: 'editProfile', concept: 'page', children: [] },
      {
        key: 'shareProfile',
        tKey: 'shareProfile.text',
        concept: 'page',
        children: [
          {
            key: 'qrCode',
            tKey: 'shareProfile.wallet.title',
            concept: 'pageContent',
          },
          {
            key: 'profileLink',
            tKey: 'shareProfile.profileLink.title',
            concept: 'pageContent',
          },
          {
            key: 'qrCodeBackground',
            tKey: 'shareProfile.qrBackgrounds.title',
            concept: 'pageContent',
          },
          {
            key: 'nfcCards',
            tKey: 'shareProfile.nfcCards.title',
            concept: 'pageContent',
          },
        ],
      },
      {
        key: 'singleUserContacts',
        tKey: 'myContacts',
        concept: 'page',
        children: [
          {
            key: 'contactsTable',
            tKey: 'contactsTable',
            concept: 'pageContent',
          },
          {
            key: 'businessCardScanner',
            tKey: 'businessCardScanner.title',
            concept: 'pageContent',
          },
        ],
      },
    ],
  },
  {
    key: 'admin',
    tKey: 'admin',
    hidableConcepts: [
      { key: 'mdGuide', tKey: 'guides', concept: 'page', children: [] },
      { key: 'mdUnits', tKey: 'units', concept: 'page', children: [] },
      { key: 'mdContacts', tKey: 'contacts', concept: 'page', children: [] },
      { key: 'mdAnalytics', tKey: 'mdAnalytics.title', concept: 'page', children: [] },
      { key: 'mdNfcCards', tKey: 'mdCards.title', concept: 'page', children: [] },
    ],
  },
] as const;

export const getConceptKey = (...args: string[]) =>
  args.filter(Boolean).join('.') as HidableConceptKey;

type ConceptItem = {
  key: string;
  tKey: string;
  children?: ConceptItem[];
};

export const getAllHidableConceptKeys = (): HidableConceptKey[] => {
  const mappedToOneShape = HIDABLE_CONCEPTS.map(x => ({
    key: x.key,
    tKey: x.tKey,
    children: x.hidableConcepts.map(concept => ({
      key: concept.key,
      tKey: concept.tKey,
      children: concept.children?.map(child => ({
        key: child.key,
        tKey: child.tKey,
      })),
    })),
  }));

  const transform = (arr: ConceptItem[], prefix = ''): HidableConceptKey[] => {
    return arr.reduce<HidableConceptKey[]>((acc, x) => {
      const currentKey = getConceptKey(prefix, x.key);
      if (x.children?.length) {
        return acc.concat(transform(x.children, currentKey));
      }
      return acc.concat(currentKey);
    }, []);
  };
  return transform(mappedToOneShape);
};

export const getParent = (key: HidableConceptKey) => {
  const parts = key.split('.');
  const parentParts = parts.slice(0, parts.length - 1);

  const parents = parentParts.reduce((acc, cur) => {
    const currentKey = getConceptKey(acc[acc.length - 1], cur);
    return [...acc, currentKey];
  }, [] as HidableConceptKey[]);

  return parents[parents.length - 1];
};

export const getChildren = (parent: string) => {
  if (!parent) return [];

  const allKeys = getAllHidableConceptKeys();

  return allKeys.filter(x => x.startsWith(parent) && x !== parent);
};

export type Concept = typeof HIDABLE_CONCEPTS[number]['hidableConcepts'][number];

// this declaration is for typechecking only
// if the declaration throws an error -> HIDABLE_CONCEPTS doesn't satisfy HidableConceptGroup[]
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const sdfk: DeepReadonly<HidableConceptGroup[]> = HIDABLE_CONCEPTS;

// allowed "paths" of keys -> this is what is to be stored in themeConfig visibilityConfig.hiddenConcepts
export type HidableConceptKey =
  | `${typeof HIDABLE_CONCEPTS[number]['key']}`
  | `${typeof HIDABLE_CONCEPTS[number]['key']}.${typeof HIDABLE_CONCEPTS[number]['hidableConcepts'][number]['key']}`
  | `${typeof HIDABLE_CONCEPTS[number]['key']}.${typeof HIDABLE_CONCEPTS[number]['hidableConcepts'][number]['key']}.${typeof HIDABLE_CONCEPTS[number]['hidableConcepts'][number]['children'][number]['key']}`;
// BETTER WOULD BE (because it returns only _valid_ paths): (but this throws an error)
// export type HidableConceptKey = {
//   [K in keyof typeof HIDABLE_CONCEPTS]: K extends `${number}` // filter actual array idc
//     ?
//         | `${typeof HIDABLE_CONCEPTS[K]['key']}`
//         | `${typeof HIDABLE_CONCEPTS[K]['key']}.${typeof HIDABLE_CONCEPTS[K]['hidableConcepts'][number]['key']}`
//         | {
//             [K2 in keyof typeof HIDABLE_CONCEPTS[K]['hidableConcepts']]: K2 extends `${number}`
//               ? `${typeof HIDABLE_CONCEPTS[K]['key']}.${typeof HIDABLE_CONCEPTS[K]['hidableConcepts'][K2]['key']}.${typeof HIDABLE_CONCEPTS[K]['hidableConcepts'][K2]['children'][number]['key']}`
//               : never;
//           }[keyof typeof HIDABLE_CONCEPTS[K]['hidableConcepts']]
//     : never;
// }[keyof typeof HIDABLE_CONCEPTS];
