import { CSS_VARS } from '@/infrastructure/constants';
import withNav from '@/infrastructure/hoc/withNav';
import useBeprint from '@/infrastructure/hooks/useBeprint';
import { Typography } from '@mui/material';
import { styled } from '@mui/styles';

interface Props {}

export function PaperCardsPage(props: Props) {
  const { ssoUrl, error } = useBeprint('product');

  if (error) return <Typography variant='body1'>{error}</Typography>;
  else return <BePrintIFrame src={ssoUrl} />;
}

export default withNav(
  PaperCardsPage,
  {
    tTitle: 'paperCards',
  },
  {},
);

const BePrintIFrame = styled('iframe')({
  width: '100%',
  minHeight: `calc(100vh - ${CSS_VARS.LT_DESKTOP_HEADER_HEIGHT_VAR})`,
});
