import { invertHex } from '@/infrastructure/helper';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { ClicksEntity } from '@/shared/types/api';
import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CardContent,
  Divider,
  Typography,
} from '@mui/material';
import folderIcon from '../../../images/folder.svg';
import TooltipInfo from '@/components/TooltipInfo';
import { visuallyHidden } from '@mui/utils';

const WithTooltipWrapper = ({
  tooltip,
  children,
}: {
  tooltip: string;
  children: JSX.Element;
}): JSX.Element =>
  tooltip && tooltip.length ? (
    <TooltipInfo text={tooltip} onHover>
      {children}
    </TooltipInfo>
  ) : (
    children
  );

const ClicksCard = ({
  mode,
  entities,
  totalClicks,
  type,
  oldClicks,
}: {
  mode: 'links' | 'files';
  entities: ClicksEntity[];
  totalClicks: number;
  type: string;
  oldClicks?: number;
}) => {
  const { t } = useAppTranslation();
  const linksMode = mode === 'links';

  return (
    <Accordion disableGutters variant='outlined' defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          display: 'flex',
          alignItems: 'center',
          '&:hover': {
            cursor: 'pointer',
          },
          pl: '1.6rem',
          pr: '1.2rem',
        }}
      >
        <Typography variant='h4' height='max-content'>
          {t(`analytics.clicks.${linksMode ? 'links' : 'files'}`)}
        </Typography>
        <Typography sx={{ ml: 'auto', mr: '0.5rem', typography: 'h4' }} variant='body2'>
          {totalClicks}
        </Typography>
      </AccordionSummary>

      <AccordionDetails sx={{ p: 0 }}>
        <Divider />
        {entities?.map(ent => (
          <Box key={ent.fullUrl + ent.name}>
            <Box display='flex' alignItems='center' justifyContent='space-between' p={1.6}>
              <Box
                color='inherit'
                display='flex'
                flexDirection='row'
                alignItems='center'
                width='95%'
                href={ent.fullUrl}
                target='_blank'
                component='a'
              >
                <WithTooltipWrapper tooltip={linksMode ? ent.fullUrl : null}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '1rem',
                      marginRight: '1rem',
                      borderRadius: '50%',
                      background: ent.backgroundCss,
                      overflow: 'visible',
                      borderStyle: 'none',
                    }}
                    aria-hidden
                  >
                    <img
                      color='#fff'
                      src={linksMode ? ent.iconColorUrl : folderIcon}
                      alt='link-icon'
                      width='25'
                      height='25'
                      style={{
                        color: '#fff',
                        fill: '#fff',
                        filter: `brightness(0) ${
                          invertHex(ent.backgroundCss, true).toLocaleLowerCase() === '#ffffff'
                            ? 'invert(100)'
                            : ''
                        }`,
                      }}
                    />
                  </Box>
                </WithTooltipWrapper>
                <Typography
                  variant='body2'
                  sx={{
                    width: '100%',
                    display: 'inline-block',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <span style={visuallyHidden}>
                    {type}
                    {': '}
                  </span>
                  {ent.name}
                </Typography>
                <Typography variant='body2'>{ent.clicks}</Typography>
              </Box>
            </Box>
            <Divider sx={{ mb: '-1px' }} />
          </Box>
        ))}
        {oldClicks > 0 && (
          <>
            <Divider />
            <CardContent sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant='body2'>
                {t(`analytics.clicks.old${linksMode ? 'Link' : 'File'}Clicks`)}
              </Typography>
              <Typography variant='body2'>
                {t('analytics.clicks.unit', { count: oldClicks })}
              </Typography>
            </CardContent>
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default ClicksCard;
