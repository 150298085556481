import React, { Suspense, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { default as HomePage } from './views/pages/homepage';
import EditProfile from './views/pages/edit-profile';
import EditBio from './views/pages/edit-bio';
import ShowProfile from './views/pages/show-profile';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import PrivateRoute from './private-route';
import { CSS_VARS, DEFAULT_COLOR, routePaths } from './infrastructure/constants';
import Home from './views/pages/home';
import Login from './views/pages/login';
import ChangePassword from './views/pages/activation/change-password';
import OnBoardingComp from './views/pages/onboarding';
import Landing from './views/pages/landing/Landing';
import LoginOrRegister from './views/pages/landing/LoginOrRegister';
import MyLeads from './views/pages/lead-gen';
import CreateEditLead from './views/pages/lead-gen/CreateEditLead';
import Helmet from 'react-helmet';
import QRCodeBG from './views/pages/qrcode-background';
import Analytics from './views/pages/analytics/index';
import Settings from './views/pages/settings/Settings';

import Signup from './views/pages/registration/signup';
import PageNotFound from './views/pages/page-not-found';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppSelector } from './application/hooks';
import { fetchUserData, checkDomainAction } from './application/actions/account';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import tapDemoPic from './views/images/tap-demo.png';
import AddCode from './components/AddCode';
import AuthEmail from './views/pages/settings/auth';
import ShareProfile from './views/pages/share-profile';
import MD2Phone from './views/pages/md/md2phone';

import useFreshworksIdentify from './infrastructure/hooks/useFreshworksIdentify';
import Logout from './views/pages/logout';
import SetFlag from './views/pages/util-lt/config/flag';
import UnsetFlag from './views/pages/util-lt/config/unsetFlag';
import useLanguageSave from './infrastructure/hooks/useLanguageSave';

import { Account } from './shared/types/api';
import 'moment/locale/de';
import moment from 'moment';
import MyCards from './views/pages/MyCards';
import Referral from './views/pages/referrals';
import UserCentricsProvider from './UserCentricsProvider';

import MdGuide from './views/pages/md/guide';
import MdProfiles from './views/pages/md/profiles';
import MdUnits from './views/pages/md/units';
import MdContacts from './views/pages/md/contacts';
import MdIntegrations from './views/pages/md/integrations';
import MdAnalytics from './views/pages/md/analytics';
import MdCards from './views/pages/md/cards';
import MdPaperOrderingSystem from './views/pages/md/cards/paperOrderingSystem';
import MdPaperCardOrder from './views/pages/md/cards/paper-cards';
import ProfileDesign from './views/pages/md/customization/profile-design';
import EditRights from './views/pages/md/customization/edit-rights';
import { FEATURE, ROLES, TIERS } from './shared/constants';
import UpgradeLeadgen from './views/pages/md/teaser/UpgradeLeadgen';
import UpgradeTeams from './views/pages/md/teaser/UpgradeTeams';
import { ContextProvider } from './utils/ContextProvider';
import { LayoutLayer } from './components/LayoutLayer';
import { invertHex } from './infrastructure/helper';
import useSidebarSettingsCollapse from './infrastructure/hooks/useSidebarSettingsCollapse';
import Organisation from './views/pages/md/customization/organisation';
import ContactFormFields from './views/pages/md/customization/contact-form';
import Export from './views/pages/md/customization/export';
import emailTemplates from './views/pages/email-templates';
import ComponentsDemo from './ComponentsDemo';
import { oldTheme } from './config/theme/useMuiTheme';
import UnitSettings from './views/pages/md/customization/unit-settings';
import useFeatureFlags, { FeatureFlagContext } from './infrastructure/hooks/useFeatureFlags';
import IDPList from './views/pages/md/Idp/IdpsList';
import MDThemeIdpSettings from './views/pages/md/Idp/ThemeIdpSettings';
import MDThemeSSOSettings from './views/pages/md/Idp/ThemeSSOSettings';

import ThemeIdpsList from './views/pages/md/Idp/ThemeIdpsList';
import BusinessCardScanner from './views/pages/lead-gen/BusinessCardScanner';
import { ProfileList } from './views/pages/admin-linkcode/ProfileList';
import { RouteWithMuiTheme } from './RouteWithMuiTheme';
import { SuccessLinkPage } from './views/pages/admin-linkcode/SuccessLinkPage';
import { ErrorLinkPage } from './views/pages/admin-linkcode/ErrorLinkPage';
import { CrmIntegrationCallbackPage } from './views/pages/CrmIntegration/CallbackPage';
import MoreSettings from './views/pages/settings/MoreSettings';
import QRCodeBGMobileView from './views/pages/qrcode-background/mobile-view';
import SharingDesign from './views/pages/md/customization/sharing-design';
import { CssBaseline } from '@mui/material';
import Loader from './components/Loader';
import PaperCards from './views/pages/paper-cards';

const Translations = React.lazy(() => import('./views/pages/md/customization/translations'));
const CrmIntegration = React.lazy(() => import('./views/pages/CrmIntegration'));

const App = ({ fetchUserData, checkDomainAction }) => {
  const { t, i18n } = useTranslation();
  const { isAuthenticated, isLoading: isAuth0Loading, logout, getAccessTokenSilently } = useAuth0();
  const isDbLoading = useAppSelector(state => state.login.isLoading);
  const account: Account = useAppSelector(state => state.account);
  const isLoading = isAuth0Loading || isDbLoading;

  const { publicFlags, privateFlags } = useFeatureFlags();

  useEffect(() => {
    if (isAuthenticated && (!account || Object.keys(account).length === 0)) {
      checkDomainAction(getAccessTokenSilently);
      fetchUserData(getAccessTokenSilently, () => logout());
    }
  }, [isAuthenticated, account, fetchUserData, checkDomainAction, getAccessTokenSilently, logout]);

  useEffect(() => {
    moment.locale(i18n.language);
    const listener = (lang: string) => moment.locale(lang);
    i18n.on('languageChanged', listener);
    return () => i18n.off('languageChanged', listener);
  }, [i18n]);

  useLanguageSave();

  // toggle sidebar settings collapse
  useSidebarSettingsCollapse();

  // identify user for crm chat
  useFreshworksIdentify();

  // update app favicon
  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = account?.theme?.iconUrl || process.env.PUBLIC_URL + '/icon.png';
  }, [account.theme?.iconUrl]);

  return (
    <FeatureFlagContext.Provider value={{ privateFlags, publicFlags }}>
      <CssBaseline />
      <ThemeProvider theme={oldTheme}>
        <Helmet htmlAttributes={{ lang: i18n.language.substring(0, 2) }}>
          <meta
            name='robots'
            content={process.env.REACT_APP_PRODUCTION === 'true' ? 'all' : 'noindex,nofollow'}
          />

          <title>
            {(account.theme?.themeInternal?.niceName
              ? `${account.theme.themeInternal.niceName} - `
              : '') + t('metaTagTitle')}
          </title>

          {/** set global css variables */}
          <style type='text/css'>{`
            :root {
                /** our default buttons/ui color */
                ${CSS_VARS.LT_DEFAULT_COLOR_NAME}: ${DEFAULT_COLOR};

                /** primary color var with a fallback value of default color */
                ${CSS_VARS.LT_PRIMARY_BUTTON_COLOR_NAME}: ${
            account.theme?.themeInternal?.primaryColor || CSS_VARS.LT_DEFAULT_COLOR_VAR
          };
                /** inverted primary color var with a fallback value of inverted default color */
                ${CSS_VARS.LT_PRIMARY_BUTTON_COLOR_INVERTED_NAME}: ${
            account.theme?.themeInternal?.primaryColor
              ? invertHex(account.theme?.themeInternal?.primaryColor, true, '#fff', true)
              : invertHex(CSS_VARS.LT_DEFAULT_COLOR_NAME, true, '#fff', true)
          };
                /** inverted default color var */
                ${CSS_VARS.LT_DEFAULT_COLOR_INVERTED_NAME}:
                ${invertHex(CSS_VARS.LT_DEFAULT_COLOR_NAME, true, '#fff', true)};
             {
        `}</style>
          {/** Primary meta tags */}
          <meta name='title' content={t('metaTagTitle')} />
          <meta name='description' content={t('metaTagDescription')} />

          {/** Open Graph/Facebook */}
          <meta property='og:type' content='website' />
          <meta property='og:url' content={window.location.pathname} />
          <meta property='og:title' content={t('metaTagTitle')} />
          <meta property='og:description' content={t('metaTagDescription')} />
          <meta property='og:image' content={tapDemoPic} />

          {/** Twitter */}
          <meta property='twitter:card' content='summary_large_image' />
          <meta property='twitter:url' content={window.location.pathname} />
          <meta property='twitter:title' content={t('metaTagTitle')} />
          <meta property='twitter:description' content={t('metaTagDescription')} />
          <meta property='twitter:image' content={tapDemoPic} />
        </Helmet>

        <BrowserRouter>
          <ContextProvider>
            <LayoutLayer>
              {!isAuthenticated && isLoading && <AddCode suppress />}
              {isAuthenticated && <AddCode />}
              <UserCentricsProvider>
                <>
                  <Switch>
                    {process.env.REACT_APP_PRODUCTION !== 'true' && (
                      <PrivateRoute
                        exact
                        path={'/demo'}
                        component={ComponentsDemo}
                        role={[ROLES.THEME_ADMIN, ROLES.UNIT_ADMIN]}
                      />
                    )}
                    <Route exact path='/' component={Home} />
                    <Route exact path={routePaths.INDEXHTML}>
                      <Redirect to='/' />
                    </Route>
                    <Route exact path={routePaths.LOGIN} component={Login} />
                    <Route exact path={routePaths.MD.ROOT}>
                      <Redirect to={routePaths.MD.PROFILES} />
                    </Route>
                    <PrivateRoute
                      exact
                      path={routePaths.MD.PROFILES}
                      role={[ROLES.THEME_ADMIN, ROLES.UNIT_ADMIN]}
                      component={MdProfiles}
                      noAuthRedirectTo={routePaths.LOGIN}
                      minTier={TIERS.ESSENTIALS}
                    />
                    <PrivateRoute
                      exact
                      path={routePaths.NFC_CARDS}
                      component={MyCards}
                      noAuthRedirectTo={routePaths.LOGIN}
                    />
                    <PrivateRoute
                      exact
                      path={routePaths.CONTACTS.SCANNER}
                      component={BusinessCardScanner}
                      noAuthRedirectTo={routePaths.LOGIN}
                    />
                    <PrivateRoute
                      exact
                      path={routePaths.MD.LINKCODE_SUCCESS}
                      component={SuccessLinkPage}
                      role={[ROLES.THEME_ADMIN, ROLES.UNIT_ADMIN]}
                      noAuthRedirectTo={routePaths.LOGIN}
                    />
                    <PrivateRoute
                      exact
                      path={routePaths.MD.LINKCODE_ERROR}
                      component={ErrorLinkPage}
                      role={[ROLES.THEME_ADMIN, ROLES.UNIT_ADMIN]}
                      noAuthRedirectTo={routePaths.LOGIN}
                    />
                    <PrivateRoute
                      exact
                      path={routePaths.MD.LINKCODE}
                      component={ProfileList}
                      role={[ROLES.THEME_ADMIN, ROLES.UNIT_ADMIN]}
                      noAuthRedirectTo={routePaths.LOGIN}
                    />
                    <PrivateRoute
                      exact
                      path={routePaths.MD.GUIDE}
                      role={[ROLES.THEME_ADMIN, ROLES.UNIT_ADMIN]}
                      component={MdGuide}
                      noAuthRedirectTo={routePaths.LOGIN}
                      minTier={TIERS.ESSENTIALS}
                    />
                    <PrivateRoute
                      exact
                      path={routePaths.MD.ANALYTICS}
                      role={[ROLES.THEME_ADMIN]}
                      component={MdAnalytics}
                      noAuthRedirectTo={routePaths.LOGIN}
                      minTier={TIERS.ESSENTIALS}
                    />
                    <PrivateRoute
                      exact
                      path={routePaths.MD.UNITS}
                      role={[ROLES.THEME_ADMIN]}
                      component={MdUnits}
                      noAuthRedirectTo={routePaths.LOGIN}
                      minTier={TIERS.ESSENTIALS}
                    />
                    <PrivateRoute
                      exact
                      path={routePaths.MD.CONTACTS}
                      role={[ROLES.THEME_ADMIN, ROLES.UNIT_ADMIN]}
                      component={MdContacts}
                      noAuthRedirectTo={routePaths.LOGIN}
                      minTier={TIERS.ESSENTIALS}
                    />
                    <PrivateRoute
                      exact
                      path={routePaths.MD.INTEGRATIONS}
                      role={[ROLES.THEME_ADMIN]}
                      component={MdIntegrations}
                      noAuthRedirectTo={routePaths.LOGIN}
                      minTier={TIERS.ESSENTIALS}
                    />
                    <PrivateRoute
                      exact
                      path={routePaths.MD.CUSTOMISESETTINGS.CRM_INTEGRATION}
                      role={[ROLES.THEME_ADMIN]}
                      component={() => (
                        <Suspense fallback={<Loader />}>
                          <CrmIntegration />
                        </Suspense>
                      )}
                      noAuthRedirectTo={routePaths.LOGIN}
                    />
                    <PrivateRoute
                      exact
                      path={routePaths.CRM_CALLBACK}
                      component={CrmIntegrationCallbackPage}
                    />
                    <PrivateRoute
                      exact
                      path={routePaths.MD.CARDS.ROOT}
                      role={[ROLES.THEME_ADMIN, ROLES.UNIT_ADMIN]}
                      component={MdCards}
                      noAuthRedirectTo={routePaths.LOGIN}
                      minTier={TIERS.ESSENTIALS}
                    />
                    <PrivateRoute
                      exact
                      path={routePaths.MD.CARDS.PAPER_CARDS.APPROVAL}
                      role={[ROLES.THEME_ADMIN, ROLES.UNIT_ADMIN]}
                      component={MdPaperOrderingSystem}
                      noAuthRedirectTo={routePaths.LOGIN}
                      minTier={TIERS.ESSENTIALS}
                    />
                    <PrivateRoute
                      exact
                      path={routePaths.MD.CARDS.PAPER_CARDS.ORDER}
                      role={[ROLES.THEME_ADMIN, ROLES.UNIT_ADMIN]}
                      component={MdPaperCardOrder}
                      noAuthRedirectTo={routePaths.LOGIN}
                      minTier={TIERS.ESSENTIALS}
                    />
                    <PrivateRoute
                      exact
                      path={routePaths.MD.CUSTOMISESETTINGS.UNITS}
                      role={[ROLES.THEME_ADMIN]}
                      component={UnitSettings}
                      noAuthRedirectTo={routePaths.LOGIN}
                      minTier={TIERS.ESSENTIALS}
                    />
                    <PrivateRoute
                      exact
                      path={routePaths.MD.CUSTOMISESETTINGS.PROFILE_DESIGN}
                      role={[ROLES.THEME_ADMIN, ROLES.UNIT_ADMIN]}
                      component={ProfileDesign}
                      noAuthRedirectTo={routePaths.LOGIN}
                      minTier={TIERS.ESSENTIALS}
                    />
                    <PrivateRoute
                      exact
                      path={routePaths.MD.CUSTOMISESETTINGS.EDIT_RIGHTS}
                      role={[ROLES.THEME_ADMIN, ROLES.UNIT_ADMIN]}
                      component={EditRights}
                      noAuthRedirectTo={routePaths.LOGIN}
                      minTier={TIERS.ESSENTIALS}
                    />
                    <PrivateRoute
                      exact
                      path={routePaths.MD.CUSTOMISESETTINGS.SHARING_DESIGN}
                      role={[ROLES.THEME_ADMIN, ROLES.UNIT_ADMIN]}
                      component={SharingDesign}
                      noAuthRedirectTo={routePaths.LOGIN}
                      minTier={TIERS.ESSENTIALS}
                    />
                    <PrivateRoute
                      exact
                      path={routePaths.MD.CUSTOMISESETTINGS.COMPANY_DATA}
                      role={[ROLES.THEME_ADMIN]}
                      component={Organisation}
                      noAuthRedirectTo={routePaths.LOGIN}
                      minTier={TIERS.ESSENTIALS}
                    />
                    <PrivateRoute
                      exact
                      path={routePaths.MD.CUSTOMISESETTINGS.CONTACT_FORM}
                      role={[ROLES.THEME_ADMIN]}
                      component={ContactFormFields}
                      noAuthRedirectTo={routePaths.LOGIN}
                      minTier={TIERS.ESSENTIALS}
                    />
                    <PrivateRoute
                      exact
                      path={routePaths.MD.CUSTOMISESETTINGS.EMAIL_TEMPLATES}
                      role={[ROLES.THEME_ADMIN]}
                      component={emailTemplates}
                      noAuthRedirectTo={routePaths.LOGIN}
                      minTier={TIERS.ESSENTIALS}
                    />
                    <PrivateRoute
                      exact
                      path={routePaths.MD.CUSTOMISESETTINGS.EXPORT}
                      role={[ROLES.THEME_ADMIN, ROLES.UNIT_ADMIN]}
                      component={Export}
                      noAuthRedirectTo={routePaths.LOGIN}
                      minTier={TIERS.ESSENTIALS}
                    />
                    <PrivateRoute
                      exact
                      path={routePaths.MD.CUSTOMISESETTINGS.TRANSLATIONS}
                      role={[ROLES.THEME_ADMIN, ROLES.UNIT_ADMIN]}
                      component={() => (
                        <Suspense fallback={<Loader />}>
                          <Translations />
                        </Suspense>
                      )}
                      noAuthRedirectTo={routePaths.LOGIN}
                      minTier={TIERS.ESSENTIALS}
                    />
                    <Route exact path={routePaths.MD.MOBILE.MD2PHONE} component={MD2Phone} />
                    <PrivateRoute path={routePaths.ONBOARDING} component={OnBoardingComp} />
                    <Route exact path={routePaths.LANDING.CHECK} component={Landing} />
                    <RouteWithMuiTheme
                      exact
                      path={routePaths.LANDING.LOGIN_REGISTER}
                      component={LoginOrRegister}
                    />
                    <Route exact path={routePaths.REGISTRATION.REGISTER}>
                      <Redirect to={routePaths.REGISTRATION.SIGNUP} />
                    </Route>
                    <Route exact path={routePaths.REGISTRATION.SIGNUP} component={Signup} />
                    <PrivateRoute
                      exact
                      path={routePaths.CHANGEPASSWORD}
                      component={ChangePassword}
                      useLegacyTheme
                    />
                    <PrivateRoute path={routePaths.HOME} component={HomePage} />
                    <PrivateRoute
                      path={routePaths.EDITPROFILEWITHSETTINGS}
                      component={EditProfile}
                      useLegacyTheme
                    />
                    <PrivateRoute
                      path={routePaths.EDITPROFILE}
                      component={EditProfile}
                      // useLegacyTheme
                    />
                    <PrivateRoute
                      path={routePaths.QR_CODE_BACKGROUND.ROOT}
                      component={QRCodeBG}
                      exact
                    />
                    <PrivateRoute
                      path={routePaths.QR_CODE_BACKGROUND.PHONE}
                      component={QRCodeBGMobileView}
                    />
                    <PrivateRoute
                      path={routePaths.QR_CODE_BACKGROUND.VIDEOCALL}
                      component={QRCodeBGMobileView}
                    />
                    <PrivateRoute
                      path={routePaths.ANALYTICS}
                      component={Analytics}
                      features={[FEATURE.ANALYTICS_INDIVIDUAL]}
                    />
                    <PrivateRoute path={routePaths.REFERRALS} component={Referral} />
                    <PrivateRoute path={routePaths.UPGRADE_TEAMS} component={UpgradeTeams} />
                    <PrivateRoute path={routePaths.UPGRADE_LEADGEN} component={UpgradeLeadgen} />
                    <PrivateRoute path={routePaths.EDITBIO} component={EditBio} />
                    <PrivateRoute
                      exact
                      path={routePaths.CONTACTS.ADD}
                      component={CreateEditLead}
                      features={[FEATURE.LEAD_GEN_INDIVIDUAL]}
                      authRedirectTo={routePaths.CONTACTS.ROOT}
                    />
                    <PrivateRoute
                      exact
                      path={routePaths.CONTACTS.EDIT}
                      component={CreateEditLead}
                      features={[FEATURE.LEAD_GEN_INDIVIDUAL]}
                      authRedirectTo={routePaths.CONTACTS.ROOT}
                    />
                    <PrivateRoute path={routePaths.CONTACTS.ROOT} component={MyLeads} />
                    <PrivateRoute path={routePaths.PAPER_CARDS} component={PaperCards} />

                    <PrivateRoute exact path={routePaths.SETTINGS.MAIN} component={Settings} />
                    <PrivateRoute exact path={routePaths.SETTINGS.MORE} component={MoreSettings} />
                    <PrivateRoute exact path={routePaths.SETTINGS.AUTH} component={AuthEmail} />

                    {/* IDP Routes */}
                    <PrivateRoute exact path={routePaths.IDP.LIST} component={IDPList} />
                    <PrivateRoute
                      exact
                      path={routePaths.IDP.THEME_IDPS}
                      component={ThemeIdpsList}
                    />
                    <PrivateRoute
                      exact
                      path={routePaths.IDP.THEME_IDP_SETTINGS}
                      component={MDThemeIdpSettings}
                    />
                    <PrivateRoute
                      exact
                      path={routePaths.IDP.THEME_SSO_SETTINGS}
                      component={MDThemeSSOSettings}
                    />

                    <PrivateRoute exact path={routePaths.SHARE_PROFILE} component={ShareProfile} />
                    {/* util routes */}
                    <PrivateRoute
                      exact
                      path={routePaths.LOGOUT}
                      component={Logout}
                      useLegacyTheme
                    />
                    <Route exact path={routePaths.UTIL_ROUTES.FLAG} component={SetFlag} />
                    <Route exact path={routePaths.UTIL_ROUTES.UNFLAG} component={UnsetFlag} />
                    <Route path={routePaths.SHOWPROFILE} component={ShowProfile} />
                    {/* <Redirect to="/" /> */}
                    <Route component={PageNotFound} />
                  </Switch>
                </>
              </UserCentricsProvider>
            </LayoutLayer>
          </ContextProvider>
        </BrowserRouter>
      </ThemeProvider>{' '}
    </FeatureFlagContext.Provider>
  );
};

// export default App;

export default connect(null, { fetchUserData, checkDomainAction })(App);
