import React, { CSSProperties } from 'react';
import { THEME_BOX_STYLES } from '@/shared/constants';
import { ThemeBoxStyle } from '@/shared/types/global';
import { Button, styled } from '@mui/material';

interface Props {
  icon: React.ReactElement;
  boxStyle: ThemeBoxStyle;
  onClick: () => void;
  text: string;
  style?: CSSProperties;
}

const LtIconButton = (props: Props): JSX.Element => {
  return (
    <ButtonStyled
      boxStyle={props.boxStyle}
      startIcon={props.icon}
      onClick={props.onClick}
      sx={props.style || {}}
    >
      {props.text}
    </ButtonStyled>
  );
};

export default LtIconButton;

const ButtonStyled = styled(Button)(({ boxStyle }) => ({
  borderRadius: boxStyle === THEME_BOX_STYLES.ANGULAR ? 0 : '10px',
}));
