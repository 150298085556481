import config from '@/config/config';
import { PERMISSIONS } from '@/infrastructure/constants';
import { withSilentAccessToken } from '@/infrastructure/helper';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import { getFullName } from '@/shared/business-logic/features/profile/getter';
import { ThemeAccount } from '@/shared/types/api';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Autocomplete,
  Avatar,
  CardHeader,
  ListItemButton,
  TextField,
  createFilterOptions,
} from '@mui/material';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VariableSizeList, ListChildComponentProps } from 'react-window';

const VISIBLE_ITEMS = 5;
const ITEM_SIZE = 72;

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: (option: ThemeAccount) => getFullName(option),
});

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top as number,
  };

  const listProps = dataSet[0];
  const option = dataSet[1];

  return (
    <li {...listProps} style={inlineStyle}>
      <ListItemButton sx={{ p: 0 }}>
        <CardHeader
          avatar={<Avatar src={option.profileImageUrl} />}
          title={getFullName(option)}
          subheader={option.email}
        />
      </ListItemButton>
    </li>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: React.ReactElement[] = [];
    (children as React.ReactElement[]).forEach(
      (item: React.ReactElement & { children?: React.ReactElement[] }) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
      },
    );

    const itemCount = itemData.length;

    const getChildSize = () => {
      return ITEM_SIZE;
    };

    const getHeight = () => {
      if (itemCount > VISIBLE_ITEMS) {
        return VISIBLE_ITEMS * ITEM_SIZE;
      }
      return itemCount * ITEM_SIZE;
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight()}
            width='100%'
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType='ul'
            itemSize={() => getChildSize()}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  },
);

const useThemeAccounts = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useAppTranslation();
  const { notify: toast } = useLtNotifications();

  const [themeAccounts, setThemeAccounts] = useState<Array<ThemeAccount>>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    withSilentAccessToken(
      getAccessTokenSilently,
      token =>
        axios.get(config.API_BASE_URL + 'theme/profiles-shallow', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      [PERMISSIONS.READ.THEME_PROFILES],
      () => setIsLoading(true),
    )
      .then(res => {
        let result: {
          isSuccess: boolean;
          data: Array<ThemeAccount>;
        } = res.data;
        if (result.isSuccess) {
          setThemeAccounts(result.data);
        } else {
          setError(true);
          toast.error(t('error.general'));
        }
      })
      .catch(err => {
        setError(true);
        toast.error(t('error.general'));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [getAccessTokenSilently, t, toast]);

  return { themeAccounts, isLoading, error };
};

type Props = {
  value: string | null;
  onSelect: (accountId: string) => void;
  label?: string;
  disabled?: boolean;
  minWidth?: number | string;
};

const LtAccountsSelector = ({ value, onSelect, disabled, label, minWidth }: Props) => {
  const { t } = useTranslation();
  const { themeAccounts, isLoading, error } = useThemeAccounts();

  const selectedAccount = useMemo(
    () => themeAccounts?.find(x => x.id === value),
    [themeAccounts, value],
  );

  return (
    <Autocomplete
      disabled={disabled || (!isLoading && error)}
      loading={isLoading}
      multiple={false}
      options={themeAccounts || []}
      disableClearable
      onChange={(_event, newValue: ThemeAccount) => {
        if (!newValue) return;
        onSelect(newValue.id);
      }}
      value={selectedAccount ? getFullName(selectedAccount) : isLoading || error ? '' : value || ''}
      filterOptions={filterOptions}
      renderInput={params => (
        <TextField
          {...params}
          label={label ?? t('select')}
          sx={{ minWidth: minWidth || '40rem' }}
        />
      )}
      renderOption={(props, option, state) => [props, option] as React.ReactNode}
      ListboxComponent={ListboxComponent}
    />
  );
};

export default LtAccountsSelector;
