// import styled from 'styled-components';
import React, { useState, useEffect, useMemo, useCallback } from 'react';
// import { DropResult } from 'react-beautiful-dnd';
// import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useAppTranslation } from '../../../infrastructure/hooks/useAppTranslation';
import withNav from '../../../infrastructure/hoc/withNav';
import { routePaths } from '../../../infrastructure/constants';
import { useAppDispatch, useAppSelector } from '../../../application/hooks';
import {
  clearApiResponse,
  getThemeLinkTypes,
  reorderLinks,
  reorderFiles,
  uploadImage,
  updateImage,
  saveLink,
  updateLinkDisplay,
  updateLinkDefault,
  updateFileDisplay,
} from '../../../application/actions/edit-profile';
import CreateEditLinkPopup from '@/components/Profile/components/popups/create-edit-link-popup';
import AddNewFilePopup from '@/components/Profile/components/popups/add-new-file-popup';
import AddNewLinkPopup from '@/components/Profile/components/popups/add-new-link-popup';
import DeletePopup, {
  DeleteModeType,
} from '@/components/Profile/components/popups/delete-item-popup';
// import ContactDetails from './contact-details';
// import LinksLayout from './links-layout';
// import FilesLayout from './files-layout';
import {
  Account,
  AccountImageState,
  Link,
  StaticProfileConfig,
  // StaticProfileCustomTextLine,
} from '../../../shared/types/api';
import {
  PRIVATE_EVENTS,
  PRIVATE_META_TYPES,
  THEME_FILE_EDIT_MODES,
  GLOBAL_DATA_POSITIONS,
  THEME_LINK_EDIT_MODES,
} from '../../../shared/constants';
// import BioText from './bio-text';
import { useAuth0 } from '@auth0/auth0-react';
// import { ReactComponent as OpenInNewTabIcon } from '../../../views/images/open-in-new-tab.svg';
import { useEditRights } from '@/infrastructure/hooks/useEditRights';
import { useProfileDesign } from '@/infrastructure/hooks/useProfileDesign';
// import { Button, IconButton } from '@mui/material';
// import {
//   HeaderAction,
//   createHeaderActions,
//   useHeaderActions,
// } from '@/components/Header/useHeaderActions';
// import { IosShare } from '@mui/icons-material';
// import {
//   getBannerImageUrl,
//   getLogoHeaderUrl,
//   getProfileImageUrl,
// } from '@/shared/business-logic/features/profile/getter';
// import { styled as styledMui } from '@mui/material';
import StaticProfileUI from '@/components/Profile/StaticProfile';
import { fetchProfile as fetchProfile_api } from '@/infrastructure/apis/profile';
import StaticProfileSkeletonPage from '@/components/Profile/components/StaticProfileSkeleton';
// import ResponsiveProfileWidthContainer from '@/components/Profile/components/ResponsiveProfileContainer';
import { PageContainer } from '@/components';
import { trackPrivateEvent } from '@/infrastructure/apis/analytics';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import { Share, Visibility } from '@mui/icons-material';
import Loader from '@/components/Loader';

// TODO: profile page optimizations
// integrate with components used in show-profile

export const getItemStyle = (isDragging: boolean, draggableStyle: any): React.CSSProperties => ({
  marginBottom: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  // background: isDragging ? 'rgba(245,245,245, 0.75)' : 'none',

  ...draggableStyle,
});

const EditProfile = (props, ref) => {
  const { notifyInfo } = useLtNotifications();
  const { t, activeLanguage } = useAppTranslation();
  // const history = useHistory();

  const { getAccessTokenSilently } = useAuth0();

  const { profileDesign } = useProfileDesign();
  const { editRights } = useEditRights();

  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(state => state.editProfile.isLoading);
  const isLinkTypesLoading = useAppSelector(state => state.editProfile.isLinkTypesLoading);
  const themeLinkTypes = useAppSelector(state => state.editProfile.linkTypes);
  const account = useAppSelector<Account>(state => state.account);
  const links = useAppSelector(state => {
    return state.account.links ? state.account.links.sort((a, b) => a.order - b.order) : [];
  });
  // const files = useAppSelector(state => {
  //   return state.account.files ? state.account.files.sort((a, b) => a.order - b.order) : [];
  // });
  const apiResponse = useAppSelector(state => state.editProfile.apiResponse);

  const [isResponseUpdated, setResponseUpdate] = useState(false);
  const [showAddNewLink, setAddNewLink] = useState(false);
  // const [showLinkTypePopup, setShowLinkTypePopup] = useState<LinkTypeProp | false>(false);
  const [showCreatePopup, setCreatePopup] = useState(false);
  const [selectedLink, setSelectedLink] = useState<Link>();
  const [showAddNewFile, setAddNewFile] = useState(false);
  const [showDeletePopup, setDeletePopup] = useState(false);
  const [deleteMode, setDeleteMode] = useState<DeleteModeType>('');
  // TODO: replace any
  // const reorder = <T extends Link | File>(
  //   list: Array<T>,
  //   startIndex: number,
  //   endIndex: number,
  // ): Array<any> => {
  //   const result = [...list];
  //   const [removed] = result.splice(startIndex, 1);
  //   result.splice(endIndex, 0, removed);

  //   return result.map((elements, idx) => ({
  //     ...elements,
  //     order: idx * 10,
  //   }));
  // };
  // const onDragEndLinks = (result: DropResult) => {
  //   const { source, destination } = result;
  //   if (!destination) return;

  //   const newItems = reorder(links, source.index, destination.index);

  //   dispatch(reorderLinks(newItems, getAccessTokenSilently));
  // };

  // const onDragEndFiles = (result: DropResult) => {
  //   const { source, destination } = result;
  //   if (!destination) return;

  //   const newItems = reorder(files, source.index, destination.index);

  //   dispatch(reorderFiles(newItems, getAccessTokenSilently));
  // };

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    if (account.theme?.id) {
      dispatch(getThemeLinkTypes(getAccessTokenSilently));
    }
  }, [account, dispatch, getAccessTokenSilently]);

  // const handleShowPreview = (newTab?: boolean) => () => {
  //   if (newTab) window.open(`/${account.username}?forceProfile=true`, '_blank');
  //   else history.push(`/${account.username}?forceProfile=true`);
  // };

  // const headerActions = useMemo<HeaderAction[]>(
  //   () =>
  //     createHeaderActions(
  //       [
  //         <IconButton
  //           component={RouterLink}
  //           to={routePaths.SHARE_PROFILE}
  //           aria-label={t('shareProfile.text')}
  //         >
  //           <IosShare />
  //         </IconButton>,
  //       ],
  //       [
  //         <Button
  //           variant='outlined'
  //           color='primary'
  //           onClick={() => history.push(`/${account.username}?forceProfile=true`)}
  //         >
  //           {t('preview')}
  //         </Button>,
  //         { mobileOnly: true },
  //       ],
  //     ),
  //   [account.username, history, t],
  // );
  // useHeaderActions(headerActions);

  const showCreateEditLinkPopup = item => {
    setResponseUpdate(false);
    setSelectedLink(item);
    setAddNewLink(false);
    setCreatePopup(true);
  };

  const onCreateLinkBackClick = () => {
    setCreatePopup(false);
    setAddNewLink(true);
  };

  // const onAddNewLink = () => {
  //   setAddNewLink(true);
  // };

  const onAddNewFileClick = () => {
    setResponseUpdate(false);
    setAddNewFile(true);
  };

  const onDeleteLinkClick = () => {
    setCreatePopup(false);
    setDeleteMode('link');
    setDeletePopup(true);
  };

  // const onDeleteFileClick = item => {
  //   setSelectedLink(item);
  //   setDeleteMode('file');
  //   setResponseUpdate(false);
  //   setDeletePopup(true);
  // };

  const checkForResponse = () => {
    if (apiResponse && apiResponse.isSuccess === true && isResponseUpdated === false) {
      setCreatePopup(false);
      setResponseUpdate(true);
      setDeletePopup(false);
      setAddNewFile(false);
      dispatch(clearApiResponse());
    }
  };

  // const navigateToEditDetails = () => {
  //   history.push(routePaths.EDITBIO);
  // };

  const maxLinkOrder = useMemo(
    () => links?.reduce((prev, cur) => (cur.order > prev ? cur.order : prev), -1) || 0,
    [links],
  );

  // const contactDetailsBox = (
  //   <ContactDetails
  //     account={account}
  //     boxStyle={profileDesign.boxStyle}
  //     editConfig={{
  //       addNewText: t('edit'),
  //       onAddNewClick: navigateToEditDetails,
  //     }}
  //   />
  // );
  // const bioTextBox = <BioText boxStyle={profileDesign.boxStyle} bioText={account.bioText} />;
  // const linksBox =
  //   !account.theme ||
  //   editRights.linkEditMode !== THEME_LINK_EDIT_MODES.DISALLOW ||
  //   links.length > 0 ? (
  //     <LinksLayout
  //       onAddNewLink={onAddNewLink}
  //       onDragEndLinks={onDragEndLinks}
  //       setShowLinkTypePopup={setShowLinkTypePopup}
  //       showCreateEditLinkPopup={showCreateEditLinkPopup}
  //       getItemStyle={getItemStyle}
  //       showDeletePopup={link => {
  //         setSelectedLink(link);
  //         onDeleteLinkClick();
  //       }}
  //     />
  //   ) : null;
  // const filesBox =
  //   !account.theme ||
  //   editRights.fileEditMode !== THEME_FILE_EDIT_MODES.DISALLOW ||
  //   files.length > 0 ? (
  //     <FilesLayout
  //       onDragEndFiles={onDragEndFiles}
  //       onDeleteFileClick={onDeleteFileClick}
  //       onAddNewFileClick={onAddNewFileClick}
  //       getItemStyle={getItemStyle}
  //     />
  //   ) : null;

  // let mainContentBoxes = [contactDetailsBox, bioTextBox, linksBox, filesBox];
  // if (account?.theme?.name === 'capgemini-invent')
  //   mainContentBoxes = [filesBox, contactDetailsBox, bioTextBox, linksBox];

  // profile fetching
  const [profile, setProfile] = useState<StaticProfileConfig>(null);
  const [profileLoading, setProfileLoading] = useState(true);
  const fetchProfile = useCallback(
    (setLoaderFct = setProfileLoading) => {
      setLoaderFct(true);
      fetchProfile_api(account.username, activeLanguage, { includeEmptyBoxes: true })
        .then(res => {
          const { status, name, profile } = res;
          if (status === 200 && name === 'profile') {
            setProfile(profile);
          }
          setLoaderFct(false);
        })
        .catch(() => setLoaderFct(false));
    },
    [account.username, activeLanguage],
  );
  useEffect(() => {
    // to force re-fetching after a change
    if (account) {
      fetchProfile();
    }
  }, [account, fetchProfile]);

  return (
    <>
      <PageContainer
        sx={{ pt: 0, px: 0 }}
        bottomActionBar={{
          customActionsPost: [
            {
              text: t('shareProfile.text'),
              asAnchor: routePaths.SHARE_PROFILE,
              variant: 'outlined',
              color: 'primary',
              startIcon: <Share />,
            },
            {
              text: t('preview'),
              asAnchor: `/${account.username}?forceProfile=true`,
              variant: 'contained',
              startIcon: <Visibility />,
              'aria-label': t('ariaPreviewProfile'),
            },
          ],
        }}
      >
        {!profile && profileLoading ? (
          <StaticProfileSkeletonPage />
        ) : !profile ? (
          'Error'
        ) : (
          <StaticProfileUI
            profile={profile}
            renderFooter={false}
            suppressLeadGen
            onContactFormSubmit={async () => {
              notifyInfo(t('onlyPreview'));
              return true;
            }}
            editProps={{
              bannerImageEditProps: {
                account: account,
                profileDesign: profileDesign,
                editRightAllowed: editRights.bannerImageEditable,
                onUpdateByFile: file =>
                  dispatch(
                    uploadImage(
                      account.username,
                      file,
                      'banner',
                      AccountImageState.DEFINED,
                      getAccessTokenSilently,
                      () => {
                        trackPrivateEvent(getAccessTokenSilently, PRIVATE_EVENTS.PROFILE_EDIT, {
                          type: PRIVATE_META_TYPES.BANNER,
                        });
                      },
                    ),
                  ),
                onUpdateByUrl: url =>
                  dispatch(
                    updateImage(
                      account.username,
                      url,
                      'banner',
                      AccountImageState.DEFINED,
                      getAccessTokenSilently,
                      () => {
                        trackPrivateEvent(getAccessTokenSilently, PRIVATE_EVENTS.PROFILE_EDIT, {
                          type: PRIVATE_META_TYPES.BANNER,
                        });
                      },
                    ),
                  ),
                onResetToDefault: () =>
                  dispatch(
                    updateImage(
                      account.username,
                      null,
                      'banner',
                      AccountImageState.DEFAULT,
                      getAccessTokenSilently,
                    ),
                  ),
              },
              profileImageEditProps: {
                account: account,
                profileDesign: profileDesign,
                editRightAllowed: editRights.profileImageEditable,
                onUpdateByFile: file =>
                  dispatch(
                    uploadImage(
                      account.username,
                      file,
                      'profile',
                      AccountImageState.DEFINED,
                      getAccessTokenSilently,
                      () => {
                        trackPrivateEvent(getAccessTokenSilently, PRIVATE_EVENTS.PROFILE_EDIT, {
                          type: PRIVATE_META_TYPES.PROFILE_PICTURE,
                        });
                      },
                    ),
                  ),
                onResetToDefault: () =>
                  dispatch(
                    updateImage(
                      account.username,
                      null,
                      'profile',
                      AccountImageState.DEFAULT,
                      getAccessTokenSilently,
                    ),
                  ),
              },
              logoImageEditProps: {
                account: account,
                profileDesign: profileDesign,
                editRightAllowed: editRights.logoImageEditable,
                onUpdateByFile: file =>
                  dispatch(
                    uploadImage(
                      account.username,
                      file,
                      'logoHeader',
                      AccountImageState.DEFINED,
                      getAccessTokenSilently,
                      () => {
                        trackPrivateEvent(getAccessTokenSilently, PRIVATE_EVENTS.PROFILE_EDIT, {
                          type: PRIVATE_META_TYPES.LOGO,
                        });
                      },
                    ),
                  ),
                onUpdateByUrl: url =>
                  dispatch(
                    updateImage(
                      account.username,
                      url,
                      'logoHeader',
                      AccountImageState.DEFINED,
                      getAccessTokenSilently,
                      () => {
                        trackPrivateEvent(getAccessTokenSilently, PRIVATE_EVENTS.PROFILE_EDIT, {
                          type: PRIVATE_META_TYPES.LOGO,
                        });
                      },
                    ),
                  ),
                onResetToDefault: () =>
                  dispatch(
                    updateImage(
                      account.username,
                      null,
                      'logoHeader',
                      AccountImageState.DEFAULT,
                      getAccessTokenSilently,
                    ),
                  ),
              },
              customBioTextEditProps: {
                isEdit: true,
                editRightAllowed: editRights.detailsEditable,
              },
              contactDetailsBoxEditProps: {
                isEdit: true,
                editRightAllowed: editRights.detailsEditable,
              },
              linksBoxEditProps: {
                linkTypes: themeLinkTypes,
                linkTemplates: account.theme.themeLinkTypes.reduce(
                  (prev, cur) => [...prev, ...cur.linkTemplates],
                  [],
                ),
                editRightAllowed: editRights.linkEditMode !== THEME_LINK_EDIT_MODES.DISALLOW,
                onAdd: async (link: Partial<Link>) => {
                  dispatch(
                    // id = 0 and order needed because of current backend implementation
                    saveLink(
                      //@ts-ignore
                      { ...link, id: 0, order: maxLinkOrder + 10 },
                      getAccessTokenSilently,
                      linkId => {
                        trackPrivateEvent(
                          getAccessTokenSilently,
                          PRIVATE_EVENTS.PROFILE_EDIT,
                          {
                            type: PRIVATE_META_TYPES.LINK_ADD,
                          },
                          null,
                          linkId,
                        );
                      },
                    ),
                  );
                },
                links: account.links,
                standardLinksBefore:
                  profileDesign.themeLinksPosition === GLOBAL_DATA_POSITIONS.BEFORE
                    ? account.theme.links
                    : undefined,
                standardLinksAfter:
                  profileDesign.themeLinksPosition === GLOBAL_DATA_POSITIONS.AFTER
                    ? account.theme.links
                    : undefined,
                genericWebsiteColor: profileDesign.genericWebsiteColor,
                defaultLinkId: account.defaultLinkId,
                onReorder: (links: Link[]) =>
                  dispatch(
                    reorderLinks(
                      // @ts-ignore
                      links.map((l, idx) => ({ ...l, order: 10 * idx })),
                      getAccessTokenSilently,
                    ),
                  ),
                onToggleDisplay: (linkId, canDisplayOnProfile) =>
                  dispatch(updateLinkDisplay(linkId, canDisplayOnProfile, getAccessTokenSilently)),
                onToggleDefault: (linkId, isDefault) =>
                  dispatch(updateLinkDefault(linkId, account, isDefault, getAccessTokenSilently)),
                onDelete: link => {
                  setSelectedLink(link);
                  onDeleteLinkClick();
                },
                onEdit: link => showCreateEditLinkPopup(link),
              },
              filesBoxEditProps: {
                editRightAllowed: editRights.fileEditMode !== THEME_FILE_EDIT_MODES.DISALLOW,
                onAddClick: onAddNewFileClick,
                files: account.files,
                standardFilesBefore:
                  profileDesign.themeFilesPosition === GLOBAL_DATA_POSITIONS.BEFORE
                    ? account.theme.files
                    : undefined,
                standardFilesAfter:
                  profileDesign.themeFilesPosition === GLOBAL_DATA_POSITIONS.AFTER
                    ? account.theme.files
                    : undefined,
                onReorder: files =>
                  dispatch(
                    reorderFiles(
                      //@ts-ignore
                      files.map((f, idx) => ({ ...f, order: 10 * idx })),
                      getAccessTokenSilently,
                    ),
                  ),
                onDelete: file => {
                  // @ts-ignore
                  setSelectedLink(file);
                  setDeleteMode('file');
                  setResponseUpdate(false);
                  setDeletePopup(true);
                },
                onToggleDisplay: (fileId, canDisplayOnProfile) =>
                  dispatch(updateFileDisplay(fileId, canDisplayOnProfile, getAccessTokenSilently)),
                genericFileBoxColor: profileDesign.fileBoxColor,
              },
            }}
          />
        )}
      </PageContainer>

      {checkForResponse()}
      <script></script>
      {/* <RelativeDiv> */}
      {/* <StyledButton onClick={handleShowPreview(true)}>
          <OpenInNewTabIcon />
          {t('preview')}
        </StyledButton> */}
      {showAddNewLink && (
        <AddNewLinkPopup
          newOrder={maxLinkOrder + 10}
          onCloseClick={() => setAddNewLink(false)}
          onItemClick={item => showCreateEditLinkPopup(item)}
          onLinkTypeClick={linkType => {
            // setShowLinkTypePopup(linkType);
            setAddNewLink(false);
          }}
          loading={isLinkTypesLoading}
          linkTypes={themeLinkTypes}
          genericWebsiteColor={profileDesign.genericWebsiteColor}
        />
      )}
      {showCreatePopup && (
        <CreateEditLinkPopup
          onCloseClick={() => setCreatePopup(false)}
          onBackClick={() => onCreateLinkBackClick()}
          onDeleteClick={() => onDeleteLinkClick()}
          item={selectedLink}
          mode={selectedLink.linkType?.mode}
          genericWebsiteColor={profileDesign.genericWebsiteColor}
        />
      )}
      {showDeletePopup && selectedLink && (
        <DeletePopup
          onCloseClick={() => setDeletePopup(false)}
          item={selectedLink}
          mode={deleteMode}
        />
      )}
      {showAddNewFile && <AddNewFilePopup onCloseClick={() => setAddNewFile(false)} />}
      {isLoading && <Loader />}
      {/* <Header showHowToTap={showHowToTap} />
        <ProfileDetails
          username={account.username}
          imageStates={{
            bannerImageState: account.bannerImageState,
            logoImageState: account.logoHeaderState,
            profileImageState: account.profileImageState,
          }}
          config={{
            bannerImageUrl: getBannerImageUrl(account, profileDesign),
            logoImageUrl: getLogoHeaderUrl(account, profileDesign),
            profileImageUrl: getProfileImageUrl(account, profileDesign),
            bioContent: [
              {
                text: account.academicDegree,
                color: '#7a7a7a',
                size: 'normal',
                fontWeight: 400,
                italic: false,
                lineHeight: 'normal',
              },
              {
                text: account.academicDegree2,
                color: '#7a7a7a',
                size: 'normal',
                fontWeight: 400,
                italic: false,
                lineHeight: 'normal',
              },
              {
                text: [account.namePrefix, account.firstName, account.lastName]
                  .filter(Boolean)
                  .join(' '),
                color: '#55595E',
                size: 'big',
                fontWeight: 700,
                italic: false,
                lineHeight: 'small',
              },
              {
                text: account.position,
                color: '#7a7a7a',
                size: 'normal',
                fontWeight: 400,
                italic: false,
                lineHeight: 'normal',
              },
              {
                text: account.role,
                color: '#7a7a7a',
                size: 'normal',
                fontWeight: 400,
                italic: false,
                lineHeight: 'normal',
              },
              {
                text: account.division,
                color: '#7a7a7a',
                size: 'normal',
                fontWeight: 400,
                italic: false,
                lineHeight: 'normal',
              },
              {
                text: account.company,
                color: '#7a7a7a',
                size: 'normal',
                fontWeight: 400,
                italic: true,
                lineHeight: 'normal',
              },
            ].filter((e): e is StaticProfileCustomTextLine => !!e.text),
          }}
          boxStyle={profileDesign.boxStyle}
          canEdit
          canEditLogo={editRights.logoImageEditable}
        />
        <EditProfileWrapper>{mainContentBoxes}</EditProfileWrapper>
      </RelativeDiv> */}
    </>
  );
};

export default withNav(
  EditProfile,
  {
    tTitle: 'editProfile',
    // showShareButton: true,
  },
  {
    activeScreen: routePaths.EDITPROFILE,
  },
);

// const RelativeDiv = styled.div`
//   position: relative;
// `;

// const EditProfileWrapper = styledMui(ResponsiveProfileWidthContainer)({
//   position: 'relative',
//   zIndex: 1,
//   paddingBottom: '5rem',
//   margin: '0 auto',
// });

// const StyledButton = styled.button`
//   position: absolute;
//   top: 10px;
//   right: 20px;
//   z-index: 1;
//   box-shadow: none;
//   font-size: 15px;
//   padding: 5px 10px;
//   border: 1px solid #191a1c;
//   border-radius: 4px;
//   line-height: 1.4;
//   color: #191a1c;
//   font-family: inherit, Arial;
//   font-weight: 500;
//   background-color: white;
//   transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
//     box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
//     border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
//     color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

//   &:hover {
//     background-color: #e3e4e4;
//   }

//   &:focus {
//     background-color: #e3e4e4;
//   }

//   @media (max-width: 1024px) {
//     font-size: 13px;
//   }
//   @media (max-width: 767px) {
//     font-size: 11px;
//   }

//   svg {
//     margin-right: 5px;
//     margin-bottom: -1px;
//   }
//   @media (max-width: 767px) {
//     display: none;
//   }
// `;
