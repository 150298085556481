import React from 'react';

import folderIcon from '../../images/folder.svg';
import { invertHex } from '../../../infrastructure/helper';
import { ApiFile } from '../../../shared/types/api';
import { THEME_BOX_STYLES } from '../../../shared/constants';
import { ThemeBoxStyle } from '@/shared/types/global';
import { Box, Link, Typography, styled } from '@mui/material';

interface Props {
  item: ApiFile;
  fileBoxColor: string;
  boxStyle: ThemeBoxStyle;
  trackHandler: () => void;
}

const FileBox: React.FC<Props> = ({ item, boxStyle, fileBoxColor, trackHandler }) => {
  const txtColor = invertHex(fileBoxColor, true);
  return (
    <LinkStyled href={item.link} target='_blank' rel='noopener noreferrer' onClick={trackHandler}>
      <FileWrapper fileBoxColor={fileBoxColor} boxStyle={boxStyle} txtColor={txtColor}>
        <Image component='img' src={folderIcon} alt='folder-icon' txtColor={txtColor} />
        <Typography sx={{ textOverflow: 'clip', overflowWrap: 'anywhere', color: txtColor }}>
          {item.fileName}
        </Typography>
      </FileWrapper>
    </LinkStyled>
  );
};

export default FileBox;

const LinkStyled = styled(Link)({
  textDecoration: 'none',
});

const FileWrapper = styled(Box)(({ fileBoxColor, boxStyle }) => ({
  position: 'relative',
  width: '100%',
  marginBottom: '20px',
  boxSizing: 'border-box',
  background: fileBoxColor,
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
  height: 'fit-content',
  minHeight: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 5rem',
  borderRadius: boxStyle === THEME_BOX_STYLES.ANGULAR ? 0 : '30px',
}));

const Image = styled(Box)(({ theme, txtColor }) => ({
  position: 'absolute',
  left: '1.75rem',
  top: '50%',
  transform: 'translateY(-50%)',
  width: '2.5rem',
  height: '2.5rem',
  objectFit: 'cover',
  filter: `brightness(0) ${txtColor.toLowerCase() === '#ffffff' && 'invert(1)'}`,
  [theme.breakpoints.down(280)]: {
    height: '20px',
  },
}));
