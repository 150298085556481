import { Address } from '@/shared/types/api';
import LocationSelector from './LocationSelector';
import { Box, Divider } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { AddEditAddressDialog, AddressBox } from '@/components/Addresses';
import { getAddresses } from '@/infrastructure/apis/address';
import { useAuth0 } from '@auth0/auth0-react';
import { GlobalDataPosition } from '@/shared/types/global';
import { useTranslation } from 'react-i18next';
import { GLOBAL_DATA_POSITIONS } from '@/shared/constants';

type Props = {
  unitId?: number;
  addresses: Address[];
  onChange: (addresses: Address[]) => void;
  themeAddressesPosition: GlobalDataPosition;
};

export const Addresses = ({ addresses, onChange, unitId, themeAddressesPosition }: Props) => {
  const { t } = useTranslation();
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [addressIndexToEdit, setAddressIndexToEdit] = useState<number | null>(null);
  const [defaultAddresses, setDefaultAddresses] = useState<Address[]>([]);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const addresses = await getAddresses(getAccessTokenSilently, {
          unitId,
          applyHierarchy: true,
        });
        setDefaultAddresses(addresses);
      } catch (e) {
        console.log(e);
      }
    };
    fetchAddresses();
  }, [getAccessTokenSilently, unitId]);

  const defaultAddressesElems = useMemo(
    () =>
      Boolean(defaultAddresses?.length) ? (
        <Box display='flex' flexDirection='column' gap={2}>
          {defaultAddresses.map((address, index) => (
            <AddressBox key={index} address={address} chips={[t('default')]} />
          ))}
        </Box>
      ) : null,
    [defaultAddresses, t],
  );

  return (
    <>
      <LocationSelector
        isDisabled={addresses.length > 1}
        onChange={address => onChange([address, ...addresses])}
        selectedTemplateIds={
          addresses.map(address => address.addressTemplateId).filter(Boolean) as number[]
        }
        setShowAddAddress={setShowAddAddress}
      />
      {themeAddressesPosition === GLOBAL_DATA_POSITIONS.BEFORE && defaultAddressesElems && (
        <>
          {defaultAddressesElems}
          <Divider sx={{ my: 2 }} />
        </>
      )}
      <Box display='flex' flexDirection='column' gap={2} mt={2}>
        {addresses?.map((address, index) => (
          <AddressBox
            address={address}
            chips={address.addressTemplateId && [t('template')]}
            onDelete={() =>
              onChange(
                addresses.slice(0, index).concat(addresses.slice(index + 1, addresses.length)),
              )
            }
            onEdit={!address.addressTemplateId && (() => setAddressIndexToEdit(index))}
          />
        ))}
      </Box>
      {themeAddressesPosition === GLOBAL_DATA_POSITIONS.AFTER && defaultAddressesElems && (
        <>
          <Divider sx={{ my: 2 }} />
          {defaultAddressesElems}
        </>
      )}
      <AddEditAddressDialog
        onClose={() => setShowAddAddress(false)}
        open={showAddAddress}
        onSave={address => {
          onChange([...addresses, address]);
          setShowAddAddress(false);
        }}
        hideTemplateSelector
      />
      <AddEditAddressDialog
        onClose={() => setAddressIndexToEdit(null)}
        open={addressIndexToEdit !== null}
        onSave={address => {
          onChange(addresses.map((a, index) => (index === addressIndexToEdit ? address : a)));
          setAddressIndexToEdit(null);
        }}
        initialValues={
          (addressIndexToEdit !== null && addresses[addressIndexToEdit as number]) || {}
        }
        hideTemplateSelector
      />
    </>
  );
};
