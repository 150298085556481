import TooltipInfo from '@/components/TooltipInfo';
import { useEffect, useRef, useState } from 'react';
import { Box, styled } from '@mui/material';

interface Props {
  text?: string;
  children?: React.ReactNode;
  styles?: React.CSSProperties;
  lineCount: number;
}

export const LineClamp = (props: Props) => {
  const [overflow, setOverflow] = useState(false);
  const contentRef = useRef<HTMLDivElement>();

  const childrenToRender = props.children ?? props.text;

  useEffect(() => {
    if (contentRef.current) {
      //+2 to consider the borders
      if (contentRef.current.scrollHeight > contentRef.current.clientHeight + 2) {
        setOverflow(true);
      }
    }
  }, []);

  const content = (
    <LineClampDiv ref={contentRef} style={{ ...(props.styles || {}) }} $lineCount={1}>
      {childrenToRender}
    </LineClampDiv>
  );

  if (overflow) {
    return (
      <TooltipInfo onHover text={props.text} placement='top' onHoverCursor='unset'>
        {content}
      </TooltipInfo>
    );
  } else {
    return content;
  }
};

const LineClampDiv: AddProps<typeof Box, { $lineCount: number }> = styled(Box)(
  ({ $lineCount }: { $lineCount: number }) => ({
    display: '-webkit-box',
    boxOrient: 'vertical',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineClamp: $lineCount.toString(),
    '-webkit-line-clamp': $lineCount.toString(),
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  }),
);
