import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AutoComplete, { AutoCompleteValue } from '../autoComplete';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { StyledLink } from '../styles';
import { GoArrowUpRight } from 'react-icons/go';
import { getAddressFromEntity } from '@/shared/util';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import { Box, Button, Typography } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import { withSilentAccessToken } from '@/infrastructure/helper';
import { Address, AddressTemplate } from '@/shared/types/api';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { PERMISSIONS, routePaths } from '@/infrastructure/constants';
import FeatureTeaser from '../../../teaser/featureTeaser';
import { FEATURE } from '@/shared/constants';
import config from '@/config/config';

interface Props {
  setShowAddAddress: Dispatch<SetStateAction<boolean>>;
  isDisabled?: boolean;
  selectedTemplateIds?: number[];
  onChange?: (address: Address) => void;
}

const LocationSelector = (props: Props) => {
  const { isDisabled } = props;
  const { notify: toast } = useLtNotifications();
  const { isFeatureAllowed, isThemeAdmin } = useTierInfo();
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();

  const [addressTemplates, setAddressTemplates] = useState<AddressTemplate[]>([]);

  useEffect(() => {
    if (isFeatureAllowed(FEATURE.DATA_TEMPLATES)) {
      withSilentAccessToken(
        getAccessTokenSilently,
        token =>
          axios.get(config.API_BASE_URL + `theme/address-templates`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        [PERMISSIONS.READ.THEME_GLOBAL],
      )
        .then(res => {
          setAddressTemplates(res.data.data);
        })
        .catch(err => {
          toast.error(t('addressesTab.errorReadAddress'));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, t, isFeatureAllowed]);

  const handleChange = (selectedValue: AutoCompleteValue) => {
    if (isDisabled || !selectedValue) return;

    const { id } = selectedValue;
    if (props.selectedTemplateIds?.includes(id)) return;

    const selectedAddressTemplate = addressTemplates.find(address => address.id === id);
    const { id: addressTemplateId, ...restFields } = selectedAddressTemplate;
    props.onChange({
      ...restFields,
      addressTemplateId,
    });
  };

  const mappedTemplates = (() => {
    const nonSelectedAdrressTemplates = addressTemplates.filter(
      ({ id }) => !props.selectedTemplateIds?.includes(id),
    );

    const duplicates = nonSelectedAdrressTemplates.filter((addressOut, idxOut) =>
      nonSelectedAdrressTemplates.some(
        (addressIn, idxIn) => idxOut !== idxIn && addressOut?.label === addressIn?.label,
      ),
    );

    return nonSelectedAdrressTemplates.map((address, index) => ({
      id: address.id,
      description: duplicates.some(dup => dup?.label === address?.label)
        ? `${address?.label} (${getAddressFromEntity(address, ', ')})`
        : address?.label,
      index,
      otherData: { ...address },
    }));
  })();

  const isAutocompleteDisabled = isDisabled || mappedTemplates?.length === 0;
  const noTemplates = isAutocompleteDisabled && addressTemplates.length === 0;

  return (
    <>
      <FeatureTeaser condition={!isFeatureAllowed(FEATURE.DATA_TEMPLATES)} text='' fullWidth>
        <Typography variant='h3'>{t('locationSelector.header')}</Typography>
        <Typography variant='body2' color='secondary'>
          {t('locationSelector.max2Label')}
        </Typography>
        <Box display='flex' mt={2} mb={2}>
          <AutoComplete
            options={mappedTemplates}
            onSave={handleChange}
            placeHolder={t(
              noTemplates ? 'locationSelector.noTemplates' : 'locationSelector.searchAddress',
            )}
            disabled={isAutocompleteDisabled}
          />
        </Box>
        {noTemplates && isThemeAdmin && (
          <StyledLink to={routePaths.MD.CUSTOMISESETTINGS.PROFILE_DESIGN}>
            {t('locationSelector.createTemplate')}
            <GoArrowUpRight size={17} />
          </StyledLink>
        )}
      </FeatureTeaser>
      <Box display='flex' flexDirection='column' alignItems='center' mb={2}>
        <Typography variant='body2' color='secondary'>
          {t('locationSelector.orCreateIndividual')}
        </Typography>
        <Button
          startIcon={<AddCircleOutline />}
          disabled={isDisabled}
          onClick={() => props.setShowAddAddress(true)}
          sx={{ mt: 0.5 }}
        >
          {t('addressesTab.addAddresses')}
        </Button>
      </Box>
    </>
  );
};

export default LocationSelector;
