import { AddCircleOutline } from '@mui/icons-material';
import { Box, BoxProps, Button, Typography } from '@mui/material';
import useId from '@mui/material/utils/useId';
import { useTranslation } from 'react-i18next';

type Props = {
  title: string;
  onAdd: () => void;
  children: React.ReactNode;
  btnAriaLabel?: string;
} & BoxProps;

export const SectionRenderer = ({ title, onAdd, children, btnAriaLabel, ...rest }: Props) => {
  const { t } = useTranslation();
  const id = useId();
  return (
    <Box component='section' {...rest}>
      <Box display='flex' alignItems='center' justifyContent='space-between' mb={2.4}>
        <Box display='flex' alignItems='center' gap={1.6}>
          <Typography id={id} variant='h3'>
            {title}
          </Typography>
        </Box>
        <Button
          onClick={onAdd}
          startIcon={<AddCircleOutline />}
          aria-describedby={id}
          aria-label={btnAriaLabel}
        >
          {t('add')}
        </Button>
      </Box>
      {children}
    </Box>
  );
};
