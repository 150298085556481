import { fetchNfcCardConfig } from '@/infrastructure/apis/md/customization/jsonConfigs';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import withNav from '../../../../infrastructure/hoc/withNav';
import { useAppTranslation } from '../../../../infrastructure/hooks/useAppTranslation';
import useTierInfo from '../../../../infrastructure/hooks/useTierInfo';
import cardsImage from '../../../images/nfc-card/lt-cards.png';
import paperCardsImage from '../../../images/paper-card.webp';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import { Box, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { LtDialog, MarkdownRenderer, PageContainer } from '@/components';
import { NFCCard } from '@/shared/types/api';
import LtActionCard from '@/components/LtActionCard';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import useHashState from '@/infrastructure/hooks/useHashState';
import { Link } from 'react-router-dom';
import { routePaths } from '@/infrastructure/constants';
import { useIsBeprintAvailable } from '@/infrastructure/hooks/useBeprint';

const MdCards = () => {
  const [cardsInfo, setCardsInfo] = useState<NFCCard[]>([]);
  const { getAccessTokenSilently } = useAuth0();

  const { isThemeAdmin, isUnitAdmin, isTrial } = useTierInfo();
  const { t, activeLanguage } = useAppTranslation();
  const { notify: toast } = useLtNotifications();

  const isGerman = activeLanguage.startsWith('de');

  const [selectedLinkByCountry, setSelectedLinkByCountry] = useState(null);

  const [hashState, setHashState] = useHashState();
  const countrySelectionPopup = cardsInfo.find(
    card => card.linksByCountry && card.key === hashState,
  );
  const staticPopup = cardsInfo.find(card => card.staticPopup && card.key === hashState);

  const noBoxText = isThemeAdmin
    ? t('mdCards.noBoxThemeAdmin')
    : isUnitAdmin
    ? t('mdCards.noBoxUnitAdmin')
    : '';

  const [loadingConfig, setLoadingConfig] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      try {
        const { data: { data = [] } = {} } = await fetchNfcCardConfig(getAccessTokenSilently);
        setCardsInfo(data);
      } catch (error) {
        toast.error(t('error.general'));
      }
      setLoadingConfig(false);
    })();
  }, [getAccessTokenSilently, t, toast]);

  const handleMenuItemClick = (card: NFCCard) => {
    if (card.linksByCountry) {
      setHashState(card.key);
    } else if (card.staticPopup) {
      setHashState(card.key);
    } else if (card.link) {
      window.open(isGerman ? card.link.de : card.link.en, '_blank');
    }
  };

  const onPopupClose = () => {
    setHashState(null);
    setSelectedLinkByCountry(null);
  };

  const onCountrySelectionPopupNextClick = () => {
    setHashState(null);
    if (selectedLinkByCountry) window.open(selectedLinkByCountry, '_blank');
    setSelectedLinkByCountry(null);
  };

  const {
    beprintAvailability: { isAdminOrderAvailable, isApprovalAvailable },
    isBeprintLoading,
  } = useIsBeprintAvailable();

  useDeferredLoader(loadingConfig || isBeprintLoading, 'config-loader-toast');

  if (loadingConfig || isBeprintLoading) return null;

  return (
    <>
      {countrySelectionPopup && (
        <LtDialog
          open
          onClose={onPopupClose}
          title={t('mdCards.order.byCountryPopup.title')}
          confirmAction={{ text: t('next'), onClick: onCountrySelectionPopupNextClick }}
          disabled={!selectedLinkByCountry}
          id={countrySelectionPopup.key}
        >
          <Box display='flex'>
            <RadioGroup
              aria-labelledby='demo-controlled-radio-buttons-group'
              name='controlled-radio-buttons-group'
              value={selectedLinkByCountry}
              onChange={e => setSelectedLinkByCountry(e.target.value)}
            >
              {countrySelectionPopup.linksByCountry?.map(conf => (
                <FormControlLabel
                  value={isGerman ? conf.de : conf.en}
                  control={<Radio />}
                  label={isGerman ? conf.label.de : conf.label.en}
                />
              ))}
            </RadioGroup>
          </Box>
          <Typography variant='body1' mt={3}>
            {t('mdCards.order.byCountryPopup.other')}
          </Typography>
        </LtDialog>
      )}
      {staticPopup && (
        <LtDialog
          open
          onClose={onPopupClose}
          onDone={onPopupClose}
          title={staticPopup.staticPopup?.title?.[isGerman ? 'de' : 'en']}
          id={staticPopup.key}
        >
          <MarkdownRenderer content={staticPopup.staticPopup?.content[isGerman ? 'de' : 'en']} />
        </LtDialog>
      )}
      <PageContainer maxWidth='m'>
        {cardsInfo?.length ? (
          <Grid container spacing={2} alignItems='stretch'>
            <Grid item xs={6}>
              <LtActionCard
                image={{ src: cardsImage, alt: t('mdCards.order.imageAlt') }}
                title={t('mdCards.order.title')}
                titleTypographyProps={{ variant: 'h3', component: 'h2' }}
                body={t('mdCards.order.body')}
                multipleActions={{
                  text: t('mdCards.order.actionLabel'),
                  actions: cardsInfo.map(card => ({
                    text: t(`mdCards.order.subaction.${card.key}.label`),
                    tooltipText: t(`mdCards.order.subaction.${card.key}.tooltip`),
                    onClick: () => handleMenuItemClick(card),
                    disabled: card.disableDuringTrial && isTrial,
                  })),
                }}
                sx={{ height: '100%' }}
              />
            </Grid>
            {(isAdminOrderAvailable || isApprovalAvailable) && (
              <Grid item xs={6}>
                <LtActionCard
                  image={{
                    src: paperCardsImage,
                    fill: true,
                    alt: t('mdCards.paperCardsBox.imageAlt'),
                  }}
                  title={t('mdCards.paperCardsBox.title')}
                  titleTypographyProps={{ variant: 'h3', component: 'h2' }}
                  body={t('mdCards.paperCardsBox.description')}
                  singleAction={
                    isApprovalAvailable && !isAdminOrderAvailable
                      ? {
                          text: t('mdCards.paperCardsBox.actions.approval'),
                          // @ts-ignore // for some reason the type doesn't accept this prop even though it's perfectly fine
                          component: Link,
                          to: routePaths.MD.CARDS.PAPER_CARDS.APPROVAL,
                        }
                      : isAdminOrderAvailable && !isApprovalAvailable
                      ? {
                          text: t('mdCards.paperCardsBox.actions.order'),
                          // @ts-ignore // for some reason the type doesn't accept this prop even though it's perfectly fine
                          component: Link,
                          to: routePaths.MD.CARDS.PAPER_CARDS.ORDER,
                        }
                      : undefined
                  }
                  multipleActions={
                    isApprovalAvailable && isAdminOrderAvailable
                      ? {
                          text: t('mdCards.paperCardsBox.buttonText'),
                          actions: [
                            {
                              text: t('mdCards.paperCardsBox.actions.approval'),
                              // @ts-ignore // for some reason the type doesn't accept this prop even though it's perfectly fine
                              component: Link,
                              to: routePaths.MD.CARDS.PAPER_CARDS.APPROVAL,
                            },
                            {
                              text: t('mdCards.paperCardsBox.actions.order'),
                              // @ts-ignore // for some reason the type doesn't accept this prop even though it's perfectly fine
                              component: Link,
                              to: routePaths.MD.CARDS.PAPER_CARDS.ORDER,
                            },
                          ],
                        }
                      : undefined
                  }
                  sx={{ height: '100%' }}
                />
              </Grid>
            )}
          </Grid>
        ) : (
          <Box p={3} mt={5} textAlign={'center'}>
            <Typography variant='body1'>{noBoxText}</Typography>
          </Box>
        )}
      </PageContainer>
    </>
  );
};

export default withNav(
  MdCards,
  {
    tTitle: 'mdCards.title',
  },
  {},
);
