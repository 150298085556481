import { AddOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Fade,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { routePaths } from '@/infrastructure/constants';

type Props = {
  templates: { id: number; label: string }[];
  onSelect: (id: number) => void;
  disabled?: boolean;
};

const GenericTemplateSelector = ({ templates, onSelect, disabled }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchWord, setSearchWord] = useState<string>('');

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSearchWord('');
  };

  const { t } = useTranslation();

  const options = (templates || []).filter(item =>
    item.label.toLowerCase().includes(searchWord.toLowerCase()),
  );

  const open = Boolean(anchorEl);
  return (
    <Box ml='auto'>
      <Button
        variant='outlined'
        color='primary'
        startIcon={<AddOutlined />}
        onClick={handleClick}
        disabled={disabled}
      >
        {t('useTemplate')}
      </Button>
      <Popover
        PaperProps={{ sx: { width: '30rem' } }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ px: '1.6rem', pt: '1.6rem' }}>
          {Boolean(templates.length) && (
            <TextField
              value={searchWord}
              onChange={({ target: { value } }) => setSearchWord(value)}
              label={t('search')}
              size='small'
              color='secondary'
              sx={{ mb: '1.6rem' }}
            />
          )}
        </Box>
        {options.map(item => (
          <MenuItem
            key={item.id}
            onClick={() => {
              handleClose();
              onSelect(item.id);
            }}
          >
            <ListItemText>{item.label}</ListItemText>
          </MenuItem>
        ))}
        <Divider />
        <MenuItem
          sx={{ mb: 1 }}
          onClick={() =>
            window.open(routePaths.MD.CUSTOMISESETTINGS.PROFILE_DESIGN + '?tab=contentTemplates')
          }
          role='link'
          aria-labelledby='lt-ts-new-template'
        >
          <ListItemIcon>
            <OpenInNewIcon />
          </ListItemIcon>
          <ListItemText id='lt-ts-new-template'>{t('createNewTemplate')}</ListItemText>
        </MenuItem>
      </Popover>
    </Box>
  );
};

export default GenericTemplateSelector;
