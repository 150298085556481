import React, { useState } from 'react';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { LtDialog } from '@/components';
import { Box, styled, Theme } from '@mui/material';

interface Props {
  onCancel: () => void;
  options?: string[];
  current?: string;
  onSelected: (string) => void;
}

export default function BannerChooser(props: Props): JSX.Element {
  const { onCancel, options, current, onSelected } = props;
  const { t } = useAppTranslation();
  const [selectedUrl, setSelectedUrl] = useState(current);

  return (
    <LtDialog
      open={true}
      onClose={onCancel}
      onCancel={onCancel}
      saveAction={{
        text: t('save'),
        onClick: () => onSelected(selectedUrl),
      }}
      title={t('chooseCoverPicture')}
      size='md'
      withActionDivider
    >
      <BannerOption>
        {options.map((url: string) => (
          <Image
            key={url}
            component='img'
            src={url}
            alt='Banner option'
            onClick={() => setSelectedUrl(url)}
            $selected={url === selectedUrl}
          />
        ))}
      </BannerOption>
    </LtDialog>
  );
}

const BannerOption = styled(Box)({
  display: 'grid',
  gridTemplateColumns: ' minmax(0, 1fr) minmax(0, 1fr)',
  rowGap: '1.5rem',
  columnGap: '1rem',
  margin: '1rem 0',
  boxSizing: 'border-box',
});

type ImageProps = { theme: Theme; $selected?: boolean };
const Image: AddProps<typeof Box, ImageProps> = styled(Box)(({ theme, $selected }: ImageProps) => ({
  boxSizing: 'border-box',
  objectFit: 'cover',
  maxWidth: '100%',
  border: '6px solid transparent',
  transition: 'border 0.15s ease-in',
  ...($selected && { border: `6px solid ${theme.palette.success.main}` }),
}));
