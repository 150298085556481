import { useTranslation } from 'react-i18next';
import ImageSelector from './ImageSelector';
import { Box } from '@mui/material';
import {
  defaultGlobalBanner,
  defaultGlobalProfileAvatar,
} from '@/shared/business-logic/features/profile/getter';
import { AccountImageState } from '@/shared/types/api';

interface Props {
  bannerImage: string | File;
  bannerImageState?: AccountImageState;
  profileImage: string | File;
  profileImageState?: AccountImageState;
  logoHeaderImage: string | File;
  logoHeaderImageState?: AccountImageState;
  onBannerChange: (value: { val: string | File; state: AccountImageState }) => void;
  onProfileImageChange: (value: { val: string | File; state: AccountImageState }) => void;
  onLogoChange: (value: { val: string | File; state: AccountImageState }) => void;
}

const Images = ({
  bannerImage,
  onBannerChange,
  profileImage,
  onProfileImageChange,
  logoHeaderImage,
  onLogoChange,
  bannerImageState,
  profileImageState,
  logoHeaderImageState,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Box display='flex' flexDirection='column' gap='1.6rem'>
      <ImageSelector
        value={bannerImage}
        stateValue={bannerImageState}
        defaultValue={defaultGlobalBanner}
        onChange={value => onBannerChange(value)}
        cropperProps={{ cropOptions: { width: 100, x: 0, y: 0, aspect: 16 / 9 } }}
        label={t('defaultCoverImage')}
        subLabel={t('imageSizeInfo.coverImage')}
        tooltipText={t('md.profileDesign.coverImageTooltip')}
        btnText={t('addCover')}
        settingsBtnAriaLabel={t('ariaCoverImageSettings')}
      />
      <ImageSelector
        value={profileImage}
        stateValue={profileImageState}
        defaultValue={defaultGlobalProfileAvatar}
        onChange={value => onProfileImageChange(value)}
        cropperProps={{ circularCrop: true }}
        label={t('defaultAvatar')}
        subLabel={t('imageSizeInfo.profileImage')}
        tooltipText={t('md.profileDesign.profileImageTooltip')}
        btnText={t('addAvatar')}
        settingsBtnAriaLabel={t('ariaProfileImageSettings')}
      />
      <ImageSelector
        value={logoHeaderImage}
        stateValue={logoHeaderImageState}
        onChange={value => onLogoChange(value)}
        cropperProps={{ noAspectRatio: true, cropOptions: { width: 100, height: 100 } }}
        label={t('logo')}
        subLabel={t('imageSizeInfo.logoImage')}
        tooltipText={t('md.profileDesign.logoImageTooltip')}
        btnText={t('addLogo')}
        settingsBtnAriaLabel={t('ariaLogoImageSettings')}
      />
    </Box>
  );
};

export default Images;
