import { LtDialog, LtSorter } from '@/components';
import { Address, AddressTemplate } from '@/shared/types/api';
import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionRenderer } from '../../SectionRenderer';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import { AddressBox } from '@/components/Addresses/AddressBox';
import { AddEditAddressDialog } from '@/components/Addresses/AddEditAddressDialog';
import {
  createAddress,
  deleteAddress,
  sortAddresses,
  updateAddress,
} from '@/infrastructure/apis/address';
import { getAddressTemeplates } from '@/infrastructure/apis/addressTemplates';
import { getAddressAsOneLine } from '@/shared/business-logic/features/profile/getter';

type Props = {
  selectedUnitId?: number | null;
  defaultAddresses?: Address[];
  refetchAddresses?: () => void;
  mutateDefaultAddresses?: (addresses: Address[]) => void;
};

export const AddressSection = ({
  selectedUnitId,
  defaultAddresses,
  mutateDefaultAddresses,
  refetchAddresses,
}: Props) => {
  const { notify: toast } = useLtNotifications();
  const [addAddressDialogOpened, setAddAddressDialogOpened] = useState(false);
  const [addressIdToDelete, setAddressIdToDelete] = useState<number | null>(null);
  const [addressToEdit, setAddressToEdit] = useState<Address | null>(null);
  const [addressTemplates, setAddressTemplates] = useState<AddressTemplate[]>([]);

  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const templates = await getAddressTemeplates(getAccessTokenSilently);
        setAddressTemplates(templates);
      } catch {
        toast.error(t('md.defaultContent.errorLoadingLinks', { id: 'default-content-loading' }));
      }
    };

    fetchData();
  }, [getAccessTokenSilently, t, toast]);

  const handleAddressAdd = async (address: Address) => {
    setSaving(true);
    try {
      await createAddress(getAccessTokenSilently, { unitId: selectedUnitId }, address);
      refetchAddresses();
      setAddAddressDialogOpened(false);
      toast.success(t('md.defaultContent.successSaveAddress'));
    } catch {
      toast.error(t('md.defaultContent.errorSavingAddress'));
    }
    setSaving(false);
  };

  const handleAddressEdit = async (address: Address) => {
    setSaving(true);
    try {
      await updateAddress(getAccessTokenSilently, { unitId: selectedUnitId }, address);
      refetchAddresses();
      setAddressToEdit(null);
      toast.success(t('md.defaultContent.successSaveAddress'));
    } catch {
      toast.error(t('md.defaultContent.errorSavingAddress'));
    }
    setSaving(false);
  };

  const handleAddressDelete = async () => {
    setDeleting(true);
    try {
      await deleteAddress(getAccessTokenSilently, { unitId: selectedUnitId }, addressIdToDelete);
      mutateDefaultAddresses(defaultAddresses.filter(address => address.id !== addressIdToDelete));
      setAddressIdToDelete(null);
      toast.success(t('md.defaultContent.successDeleteAddress'));
    } catch {
      toast.error(t('md.defaultContent.errorDeleteAddress'));
    }
    setDeleting(false);
  };

  const handleSortEnd = async (addresses: Address[]) => {
    try {
      mutateDefaultAddresses(addresses);
      await sortAddresses(getAccessTokenSilently, { ids: addresses.map(({ id }) => id) });
    } catch (error) {
      toast.error(t('md.defaultContent.errorSavingAddress'));
    }
  };

  return (
    <SectionRenderer
      title={t('md.defaultContent.defaultAddresses')}
      btnAriaLabel={t('ariaAddAddress')}
      onAdd={() => setAddAddressDialogOpened(true)}
    >
      <LtSorter
        items={defaultAddresses}
        getItemAriaLabel={item => `${t('md.defaultContent.defaultAddress')}: ${item.addressLine1}`}
        renderer={address => (
          <AddressBox
            address={address}
            onDelete={() => setAddressIdToDelete(address.id)}
            onEdit={!address.addressTemplateId ? () => setAddressToEdit(address) : null}
            chips={[t('default'), address.addressTemplateId && t('template')].filter(Boolean)}
          />
        )}
        onEnd={handleSortEnd}
        getItemKey={item => item.id + getAddressAsOneLine(item)}
      />
      {/* Add Address Dialog */}
      <AddEditAddressDialog
        open={addAddressDialogOpened}
        onClose={() => setAddAddressDialogOpened(false)}
        onSave={handleAddressAdd}
        loading={saving}
        templates={addressTemplates}
      />
      {/* Edit Address Dialog */}
      <AddEditAddressDialog
        open={Boolean(addressToEdit)}
        onClose={() => setAddressToEdit(null)}
        onSave={handleAddressEdit}
        initialValues={addressToEdit}
        loading={saving}
        templates={addressTemplates}
      />
      <LtDialog
        size='sm'
        title={t('requestDelete')}
        open={Boolean(addressIdToDelete)}
        onClose={() => setAddressIdToDelete(null)}
        onCancel={() => setAddressIdToDelete(null)}
        onDelete={handleAddressDelete}
        loading={deleting}
      >
        <Typography>{t('deleteMessageAddress')}</Typography>
      </LtDialog>
    </SectionRenderer>
  );
};
