import appleWalletImageDE from '../../images/add-to-apple-wallet-de.png';
import appleWalletImageEN from '../../images/add-to-apple-wallet-en.png';
import googleWalletImageEN from '../../images/add-to-google-wallet-en.png';
import googleWalletImageDE from '../../images/add-to-google-wallet-de.png';
import { useAuth0 } from '@auth0/auth0-react';
import fileDownload from 'js-file-download';
import QRCode from 'qrcode.react';
import { getVcard, getWalletPass } from '@/infrastructure/apis/account';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useDeviceInfo from '@/infrastructure/hooks/useDeviceInfo';
import TooltipInfo from '@/components/TooltipInfo';
import {
  Box,
  CardContent,
  MenuItem,
  Select,
  Typography,
  IconButton,
  Button,
  Divider,
  CardMedia,
  Skeleton,
} from '@mui/material';
import { LtDialog } from '@/components';
import { CardRenderer } from '@/components/CardRenderer';
import useDesktopView from '@/infrastructure/hooks/useDesktopView';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import { FileDownloadOutlined, InfoOutlined } from '@mui/icons-material';
import { useAppSelector } from '@/application/hooks';
import { Account } from '@/shared/types/api';
import LtVideo from '@/components/LtVideo';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';

type WalletQRType = 'online' | 'offline';

const QR_TYPES = ['online', 'offline'] as Array<WalletQRType>;

type Props = {
  profileUrl: string;
  cardLayout?: boolean;
  oneQrType?: WalletQRType;
  downloadCallback?: () => void;
  description?: string;
  showCardActions?: boolean;
  showQrCode?: boolean;
  uidForProfileLink?: number;
};

const WalletGenerator = (props: Props) => {
  const { cardLayout = true, showQrCode = true, showCardActions = true } = props;

  const { getAccessTokenSilently } = useAuth0();

  const account = useAppSelector<Account>(state => state.account);

  const { t, activeLanguage } = useAppTranslation();
  const { notify: toast } = useLtNotifications();

  const qrRef = useRef(null);

  const isDE = activeLanguage === 'de';

  const [qrType, setQrType] = useState<WalletQRType>(props.oneQrType || 'online');
  const [showInfoDialog, setShowInfoDialog] = useState(false);

  const { isIOS, isAndroid, isChrome, isSafari } = useDeviceInfo();

  const { desktopView } = useDesktopView();

  const company = isIOS ? 'Apple' : isAndroid ? 'Google' : 'Apple/Google';

  const clickHandler = useCallback(
    async (wallet: 'google' | 'apple') => {
      try {
        toast.loading(t('Loading'), { id: 'aw-toastid' });
        const response = await getWalletPass(
          getAccessTokenSilently,
          qrType,
          wallet,
          props.uidForProfileLink,
        );
        if (wallet === 'apple') {
          fileDownload(response.data, `pass.pkpass`, 'application/vnd.apple.pkpass');
        } else if (wallet === 'google') {
          window.open(response.data, '_blank');
        }
        toast.dismiss('aw-toastid');
        props?.downloadCallback && props.downloadCallback();
      } catch {
        toast.error(t('error.general'), { id: 'aw-toastid' });
      }
    },
    [getAccessTokenSilently, props, qrType, t, toast],
  );

  const handleDownloadQRImage = () => {
    const canvas = qrRef.current?.children[0] as HTMLCanvasElement;
    if (!canvas) return;
    var link = document.createElement('a');
    link.download = 'qr-code.png';
    link.href = canvas.toDataURL();
    link.click();
    link.remove();
  };

  // info video config goes here
  const videoConfig = useMemo(
    () => ({
      title: t('shareProfile.wallet.dialog.title'),
      text: t('shareProfile.wallet.dialog.description'),
      videoUrl: isDE
        ? 'https://www.youtube.com/embed/L_eYWNHHwCc'
        : 'https://www.youtube.com/embed/uwh2ERfqv8M',
    }),
    [isDE, t],
  );

  const [vcardString, setVcardString] = useState('');
  useEffect(() => {
    if (qrType === 'offline') {
      getVcard(account.id, activeLanguage, 'qr-code')
        .then(res => {
          setVcardString(res.data);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [account?.id, activeLanguage, qrType]);

  const hideButtonsTooltip = props?.description;

  const cardActions = useMemo(
    () =>
      showCardActions
        ? [
            <Select
              value={qrType}
              onChange={({ target: { value } }) => setQrType(value as WalletQRType)}
              size={desktopView ? 'small' : 'medium'}
              sx={{ mr: 1, flex: desktopView ? undefined : '1' }}
            >
              {QR_TYPES.map(option => (
                <MenuItem key={option} value={option}>
                  {t(`shareProfile.wallet.${option}`).concat(!desktopView ? ' QR-Code' : '')}
                </MenuItem>
              ))}
            </Select>,
            videoConfig?.videoUrl?.length ? (
              <IconButton
                onClick={() => setShowInfoDialog(true)}
                aria-haspopup='dialog'
                aria-label={t('ariaShareQrInfo')}
              >
                <InfoOutlined />
              </IconButton>
            ) : (
              <TooltipInfo
                text={t('shareProfile.wallet.titleTooltip')}
                icon={{ color: 'text.primary' }}
              />
            ),
          ]
        : undefined,
    [desktopView, qrType, showCardActions, t, videoConfig?.videoUrl?.length],
  );

  const content = useMemo(
    () => (
      <>
        {props?.description && <Typography variant='body1'>{props.description}</Typography>}
        {showQrCode && (
          <>
            <Box display='flex' flexDirection='column' alignItems='center'>
              <Box ref={qrRef} display='flex' justifyContent='center'>
                {qrType === 'offline' && !vcardString ? (
                  <Skeleton variant='rectangular' width={164} height={164} />
                ) : (
                  <QRCode
                    value={qrType === 'online' ? props.profileUrl : vcardString}
                    size={164}
                    includeMargin
                    style={{ margin: '0rem auto' }}
                  />
                )}
              </Box>
              <Button
                variant='text'
                startIcon={<FileDownloadOutlined />}
                onClick={handleDownloadQRImage}
                aria-label={t('ariaQrDownload', { type: qrType })}
              >
                {t('download')}
              </Button>
            </Box>
            <Divider />
          </>
        )}
        <Box display='flex' justifyContent='center' width='100%'>
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            maxWidth={hideButtonsTooltip ? '100%' : '95%'}
            gap={1}
            flexWrap={desktopView ? 'nowrap' : 'wrap'}
          >
            {!isAndroid && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mb: desktopView ? 'auto' : 1,
                  gap: 0.5,
                }}
              >
                <Box
                  component='button'
                  onClick={() => clickHandler('apple')}
                  aria-label={t('ariaQrDownloadAppleWallet', { type: qrType })}
                  width={hideButtonsTooltip ? '90%' : '80%'}
                >
                  <CardMedia
                    component='img'
                    src={isDE ? appleWalletImageDE : appleWalletImageEN}
                    alt='add-to-apple-wallet'
                    sx={{
                      width: '100%',
                      maxWidth: '24rem',
                      minWidth: '15rem',
                      alignSelf: 'center',
                      cursor: 'pointer',
                    }}
                  />
                </Box>
                {!props.oneQrType && (
                  <Typography variant='caption' sx={{ textTransform: 'capitalize' }}>
                    {qrType} QR-Code
                  </Typography>
                )}
              </Box>
            )}
            {!isIOS && (
              <Box
                sx={{
                  ml: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  gap: 0.5,
                }}
              >
                <Box
                  component='button'
                  onClick={() => clickHandler('google')}
                  aria-label={t('ariaQrDownloadGoogleWallet', { type: qrType })}
                  width={hideButtonsTooltip ? '88%' : '80%'}
                >
                  <CardMedia
                    component='img'
                    src={isDE ? googleWalletImageDE : googleWalletImageEN}
                    alt='add-to-google-wallet'
                    sx={{
                      width: '100%',
                      maxWidth: '28rem',
                      minWidth: '15.5rem',
                      cursor: 'pointer',
                    }}
                  />
                </Box>
                {!props.oneQrType && (
                  <Typography variant='caption' sx={{ textTransform: 'capitalize' }}>
                    {qrType} QR-Code
                  </Typography>
                )}
              </Box>
            )}
            {desktopView && !hideButtonsTooltip && (
              <TooltipInfo
                text={t('shareProfile.wallet.buttonsTooltip', { company })}
                icon={{ color: 'text.primary' }}
              />
            )}
          </Box>
        </Box>
        {isIOS && !(isChrome || isSafari) && <Typography>{t('wallet.hint.browsers')}</Typography>}
      </>
    ),
    [
      clickHandler,
      company,
      desktopView,
      hideButtonsTooltip,
      isAndroid,
      isChrome,
      isDE,
      isIOS,
      isSafari,
      props.description,
      props.oneQrType,
      props.profileUrl,
      qrType,
      showQrCode,
      t,
      vcardString,
    ],
  );

  return cardLayout ? (
    <>
      <CardRenderer
        title={desktopView ? t('shareProfile.wallet.title') : undefined}
        sx={{ flexBasis: '100%' }}
        headingActions={showCardActions && desktopView ? cardActions : undefined}
        variant={desktopView ? 'elevation' : 'outlined'}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            rowGap: 3,
          }}
        >
          {showCardActions && !desktopView && (
            <Box display='flex' justifyContent='center' alignItems='center'>
              {cardActions}
            </Box>
          )}
          {content}
        </CardContent>
      </CardRenderer>
      <LtDialog
        open={showInfoDialog}
        onClose={() => setShowInfoDialog(false)}
        title={videoConfig?.title}
        onDone={() => setShowInfoDialog(false)}
      >
        <Box>
          <Box display='flex'>
            <InfoOutlined sx={{ color: 'text.primary', mr: 2 }} />
            <Typography variant='body2' color='text.secondary' whiteSpace='pre-wrap'>
              {videoConfig?.text}
            </Typography>
          </Box>
          <Box mt={2}>
            <LtVideo embedUrl={videoConfig.videoUrl} />
          </Box>
        </Box>
      </LtDialog>
    </>
  ) : (
    <Box display='flex' flexDirection='column' rowGap={4} py={4}>
      {content}
    </Box>
  );
};

export default WalletGenerator;
