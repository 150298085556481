import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch, useAppSelector } from '../../../../application/hooks';
import { updateEmployee } from '../../../../application/actions/md/profiles';
import InputsForm from './common/inputs-form';
import ProfilePicture from './common/images/profile-picture';
import Banner from './common/images/banner';
import LogoHeader from './common/images/logo-header';
import { Employee } from '../../../../shared/types/api/employee.type';
import EmployeeTypeAssignment from './employeeTypeAssignment';

import EmployeeCards from './EmployeeCards';
import { Account, AccountImageState } from '../../../../shared/types/api';
import { useUnsavedStatus, useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import { useProfileDesignForUnits } from '@/infrastructure/hooks/useProfileDesignForUnits';
import { getMainUnitSync } from '@/shared/util';
import { Box } from '@mui/system';
import { Button, Divider, ScopedCssBaseline, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import usePrivateThemeConfig from '@/infrastructure/hooks/usePrivateThemeConfig';
import { THEME_CONFIG_KEYS } from '@/shared/constants';
import {
  GoogleFormPrefillConfig,
  getPrefilledGoogleFormLink,
} from '@/shared/business-logic/lib/google-forms';
import { openLink } from '@/util';
import {
  getBannerImageFromProfileDesign,
  getBannerImageUrl,
  getFullName,
  getLogoHeaderUrl,
  getLogoHeaderUrlFromProfileDesign,
  getProfileImageFromProfileDesign,
  getProfileImageUrl,
} from '@/shared/business-logic/features/profile/getter';
import { EditEmployeesTabs } from './EditEmployeesTabs';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import { SectionRenderer } from './SectionRenderer';

const tabs = ['profile', 'account', 'card'];

interface Props {
  employee: Employee;
  onBackClick: () => void;
}

const EditEmployee = (props: Props) => {
  const { t } = useTranslation();
  const { notify: toast } = useLtNotifications();

  const dispatch = useAppDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { employee, onBackClick } = props;
  const { withUnsavedSetter, setIsUnsaved } = useUnsavedStatusSetter();
  const { openDialogIfUnsaved } = useUnsavedStatus();

  const account = useAppSelector<Account>(state => state.account);
  const { id: adminId, theme } = account;
  const employeeMainUnitId = getMainUnitSync(employee)?.id;
  const [loading, setLoading] = useState(false);

  const [namePrefix, setNamePrefix] = useState(employee.namePrefix);
  const [firstName, setFirstName] = useState(employee.firstName);
  const [lastName, setLastName] = useState(employee.lastName);
  const nameProps = { firstName, setFirstName, lastName, setLastName, namePrefix, setNamePrefix };

  const { profileDesign } = useProfileDesignForUnits(employeeMainUnitId);

  //Profile Image
  const [profileImageFile, setProfileImageFile] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState(
    getProfileImageUrl(employee, profileDesign),
  );

  const [profileImageState, setProfileImageState] = useState(employee.profileImageState);

  const handleProfileImageChange = (file?: File, url?: string, state?: AccountImageState) => {
    setProfileImageFile(file);
    setProfileImageUrl(url);
    setProfileImageState(state);
    setIsUnsaved(true);
  };

  //Banner Image
  const [bannerImageFile, setBannerImageFile] = useState(null);
  const [bannerImageUrl, setBannerImageUrl] = useState(getBannerImageUrl(employee, profileDesign));
  const [bannerImageState, setBannerImageState] = useState(employee.bannerImageState);

  const handleBannerImageChange = (file?: File, url?: string, state?: AccountImageState) => {
    setBannerImageFile(file);
    setBannerImageUrl(url);
    setBannerImageState(state);
    setIsUnsaved(true);
  };

  //Logo Header
  const [logoHeaderFile, setLogoHeaderFile] = useState(null);
  const [logoHeaderUrl, setLogoHeaderUrl] = useState(getLogoHeaderUrl(employee, profileDesign));
  const [logoHeaderState, setLogoHeaderState] = useState(employee.logoHeaderState);

  const handleLogoUrlChange = (file?: File, url?: string, state?: AccountImageState) => {
    setLogoHeaderFile(file);
    setLogoHeaderUrl(url);
    setLogoHeaderState(state);
    setIsUnsaved(true);
  };

  const [defaultLinkId, setDefaultLinkId] = useState(employee.defaultLinkId);
  const [userLinks, setUserLinks] = useState([...employee.links]);
  const userLinksProps = {
    userLinks,
    setUserLinks: withUnsavedSetter(setUserLinks),
    defaultLinkId,
    setDefaultLinkId: withUnsavedSetter(setDefaultLinkId),
  };

  const [userFiles, setUserFiles] = useState([...employee.files]);
  const userFilesProps = { userFiles, setUserFiles: withUnsavedSetter(setUserFiles) };

  const [currentTabPage, setCurrentTabPage] = useState('profile');

  const onSuccess = () => {
    toast.success(t('changesSaved'));
    setIsUnsaved(false);
    onBackClick();
  };

  const onFailed = () => toast.error(t('error.general'));

  const handleSave = async inputs => {
    const toUpdateLinks = userLinks;
    const toUpdateFiles = userFiles;

    const toUpdateImages = {
      profileImageFile,
      profileImageUrl,
      bannerImageFile,
      bannerImageUrl,
      logoHeaderFile,
      logoHeaderUrl,
    };

    const { id, username } = employee;
    const updatedAccount = {
      id,
      username,
      defaultLinkId,
      ...inputs,
      profileImageState,
      bannerImageState,
      logoHeaderState,
    };
    const isAdmin = id === adminId;

    setLoading(true);
    await dispatch(
      updateEmployee(
        isAdmin,
        updatedAccount,
        toUpdateImages,
        toUpdateLinks,
        toUpdateFiles,
        getAccessTokenSilently,
        () => onSuccess(),
        () => onFailed(),
      ),
    );
    setLoading(false);
  };

  const { config: cardOrderFormConfig, loading: isCardOrderFormConfigLoading } =
    usePrivateThemeConfig<GoogleFormPrefillConfig | Record<string, never>>(
      THEME_CONFIG_KEYS.ADMIN_CARD_ORDER_GOOGLE_FORM,
    );
  const cardOderFormLink = useMemo(() => {
    if (
      !isCardOrderFormConfigLoading &&
      account &&
      cardOrderFormConfig &&
      'baseUrl' in cardOrderFormConfig &&
      'attributeMap' in cardOrderFormConfig
    ) {
      return getPrefilledGoogleFormLink(
        cardOrderFormConfig as GoogleFormPrefillConfig,
        employee,
        account.theme,
        { lt_medium: 'pcard' },
      );
    }
    return '';
  }, [account, cardOrderFormConfig, employee, isCardOrderFormConfigLoading]);

  const inputsFormProps = {
    ...nameProps,
    employee,
    userLinksProps,
    userFilesProps,
    onBackClick,
    handleSave,
  };

  return (
    <>
      <EditEmployeesTabs
        tabs={tabs}
        value={currentTabPage}
        onChange={setCurrentTabPage}
        onClose={() => openDialogIfUnsaved(onBackClick)}
      />
      {currentTabPage === 'profile' && (
        <>
          <Box px={4}>
            <SectionRenderer
              label={t('profilePicture')}
              subLabel={t('imageSizeInfo.profileImage')}
              mt={1.5}
            >
              <Box display='flex' alignItems='center'>
                <ProfilePicture
                  profileImageUrl={profileImageUrl}
                  defaultProfileImageUrl={getProfileImageFromProfileDesign(profileDesign)}
                  defaultProfileImageState={profileDesign.defaultProfileImageState}
                  onChange={handleProfileImageChange}
                  profileImageState={profileImageState}
                />
                <Typography variant='h3'>
                  {getFullName({ namePrefix, firstName, lastName })}
                </Typography>
              </Box>
            </SectionRenderer>

            <SectionRenderer label={t('coverPicture')} subLabel={t('imageSizeInfo.coverImage')}>
              <Banner
                bannerImgOptions={profileDesign.bannerImgOptions}
                bannerImageState={bannerImageState}
                defaultBannerImageUrl={getBannerImageFromProfileDesign(profileDesign)}
                defaultBannerImageState={profileDesign.defaultBannerImageState}
                bannerImageUrl={bannerImageUrl}
                onChange={handleBannerImageChange}
              />
            </SectionRenderer>
            <SectionRenderer
              label={t('editHeaderLogo')}
              subLabel={t('imageSizeInfo.logoImage')}
              tooltipText={t('editLogoTooltip')}
            >
              <LogoHeader
                defaultLogoHeaderUrl={getLogoHeaderUrlFromProfileDesign(profileDesign)}
                logoHeaderUrl={logoHeaderUrl}
                logoHeaderState={logoHeaderState}
                defaultLogoHeaderState={profileDesign.logoHeaderState}
                onChange={handleLogoUrlChange}
              />
            </SectionRenderer>
          </Box>
          <InputsForm
            {...inputsFormProps}
            employeeMainUnitId={employeeMainUnitId}
            loading={loading}
          />
        </>
      )}
      {currentTabPage === 'account' && (
        <EmployeeTypeAssignment
          employee={employee}
          onBackClick={onBackClick}
          onSaveSuccess={() => {
            onBackClick();
          }}
        />
      )}
      {currentTabPage === 'card' && (
        <>
          {(isCardOrderFormConfigLoading || cardOderFormLink) && (
            <ScopedCssBaseline>
              <Box display='flex' alignItems='center' justifyContent='center' my={4}>
                <Button
                  variant='text'
                  endIcon={<ArrowForwardIcon />}
                  onClick={cardOderFormLink ? () => openLink(cardOderFormLink, '_blank') : () => {}}
                >
                  <Typography
                    color='text.primary'
                    sx={{ textTransform: 'capitalize', fontSize: '1.6rem' }}
                  >
                    {t('toPaperCardOrderForm')}
                  </Typography>
                </Button>
              </Box>
              <Divider />
            </ScopedCssBaseline>
          )}
          <EmployeeCards
            employee={employee}
            onBackClick={onBackClick}
            themeDomain={theme?.domain}
          />
        </>
      )}
    </>
  );
};

export default EditEmployee;
