import { useAppSelector } from '@/application/hooks';
import LtAccountsSelector from '@/components/LtAccountsSelector';
import { CSS_VARS, routePaths } from '@/infrastructure/constants';
import withNav from '@/infrastructure/hoc/withNav';
import useBeprint from '@/infrastructure/hooks/useBeprint';
import useQueryState from '@/infrastructure/hooks/useQueryState';
import { Account } from '@/shared/types/api';
import { Typography } from '@mui/material';
import { Box, styled } from '@mui/system';
import { useTranslation } from 'react-i18next';

const MdPaperCardOrder = () => {
  const { t } = useTranslation();
  const authAccount = useAppSelector<Account>(state => state.account);
  const [selectedAccountId, setSelectedAccountId] = useQueryState('acc', authAccount.id || '');

  const { ssoUrl, error } = useBeprint('product', selectedAccountId || undefined);

  if (error) return <Typography variant='body1'>{error}</Typography>;

  return (
    <Box>
      <Box height={HEADER_HEIGHT} display='flex' alignItems='center' padding={2}>
        <LtAccountsSelector
          value={selectedAccountId}
          onSelect={setSelectedAccountId}
          label={t('selectUser')}
        />
      </Box>
      <BePrintIFrame src={ssoUrl} />
    </Box>
  );
};

export default withNav(
  MdPaperCardOrder,
  {
    tTitle: 'paperCards',
    showBackButton: { linkTo: routePaths.MD.CARDS.ROOT },
  },
  {},
);

const HEADER_HEIGHT = '10rem';
const BePrintIFrame = styled('iframe')({
  width: '100%',
  minHeight: `calc(100vh - ${CSS_VARS.LT_DESKTOP_HEADER_HEIGHT_VAR} - ${HEADER_HEIGHT} - 5px)`,
});
