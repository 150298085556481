import React from 'react';
import { Link } from '../../../shared/types/api';
import { themeCss } from '../../../util';
import { getFullLink, invertHex } from '@/infrastructure/helper';
import { ThemeBoxStyle } from '@/shared/types/global';
import { Box, Typography, styled } from '@mui/material';

interface Props {
  item: Link;
  boxStyle: ThemeBoxStyle;
  trackHandler: () => void;
  genericWebsiteColor: string;
}

const LinkBoxThemed: React.FC<Props> = ({ item, boxStyle, trackHandler, genericWebsiteColor }) => {
  const displayName = item.name;
  const bgCss = item.linkType.backgroundCss || genericWebsiteColor || '#152570';
  const txtColor = invertHex(bgCss, true);
  return (
    <a
      href={getFullLink(item.link, item.linkType)}
      target='_blank'
      rel='noopener noreferrer'
      onClick={trackHandler}
    >
      <SocialMediaWrapper theme={themeCss(boxStyle)} backgroundCss={bgCss} txtColor={txtColor}>
        <ImageStyled component='img' src={item.linkType.iconUrl} alt='' txtColor={txtColor} />
        <Typography sx={{ textOverflow: 'clip', overflowWrap: 'anywhere', color: 'inherit' }}>
          {displayName}
        </Typography>
      </SocialMediaWrapper>
    </a>
  );
};

export default LinkBoxThemed;

const SocialMediaWrapper = styled(Box)(({ backgroundCss, txtColor, theme }) => ({
  position: 'relative',
  width: '100%',
  marginBottom: '20px',
  color: txtColor,
  boxSizing: 'border-box',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
  height: 'fit-content',
  minHeight: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 5rem',
  background: backgroundCss,
  borderRadius: theme === 'theme-rounded' ? '30px' : 0,
}));

const ImageStyled = styled(Box)(({ txtColor }) => ({
  position: 'absolute',
  left: '1.75rem',
  top: '50%',
  transform: 'translateY(-50%)',
  width: '2.5rem',
  height: '2.5rem',
  objectFit: 'cover',
  filter: `brightness(0) ${txtColor.toLowerCase() === '#ffffff' && 'invert(1)'}`,
}));
