import { useTranslation } from 'react-i18next';
import LtDialog from '../LtDialog';
import { Address, AddressTemplate } from '@/shared/types/api';
import { useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import GenericTemplateSelector from '../GenericTemplateSelector';
import { getAddressAsOneLine } from '@/shared/business-logic/features/profile/getter';
import { AddressBox } from './AddressBox';

export type AddEditAddressDialogProps = {
  open: boolean;
  onClose: () => void;
  onSave: (address: Address) => void;
  initialValues?: Address;
  loading?: boolean;
  templates?: AddressTemplate[];
  hideTemplateSelector?: boolean;
};

/**
 * On save always produces Address type object, but the dialog can be used for Address and AddressTemplates as well
 */
export const AddEditAddressDialog = ({
  onClose,
  open,
  initialValues,
  onSave,
  loading,
  hideTemplateSelector,
  templates,
}: AddEditAddressDialogProps) => {
  const { notify } = useLtNotifications();
  const [values, setValues] = useState<Address>(initialValues || {});
  const isEdit = Boolean(values.id);
  const { t } = useTranslation();

  useEffect(() => {
    if (!initialValues) return;
    setValues(initialValues);
  }, [initialValues]);

  /** need timeout to keep smooth dialog close/open animation */
  useEffect(() => {
    let timeoutId;
    if (!open) {
      timeoutId = setTimeout(() => setValues({}), 500);
      return () => clearTimeout(timeoutId);
    }
  }, [open]);

  const generateTextFieldProps = field => ({
    label: t(`address.${field}`),
    value: values[field] || '',
    onChange: e => setValues(prev => ({ ...prev, [field]: e.target.value })),
    fullWidth: true,
  });

  const handleTemplateSelect = (templateId: number) => {
    const linkTemplate = templates?.find(t => t.id === templateId);
    if (!linkTemplate) return;

    setValues({
      addressee: linkTemplate.addressee,
      addressLine1: linkTemplate.addressLine1,
      addressLine2: linkTemplate.addressLine2,
      postCode: linkTemplate.postCode,
      city: linkTemplate.city,
      country: linkTemplate.country,
      label: linkTemplate.label,
      addressTemplateId: templateId,
    });
  };

  const handleSave = () => {
    if (
      !values.addressee &&
      !values.addressLine1 &&
      !values.addressLine2 &&
      !values.postCode &&
      !values.city &&
      !values.country &&
      !values.label
    ) {
      notify.error(t('addressesTab.emptyAddressFieldsError'));
      return;
    }

    onSave(values);
  };

  const isTemplateSelected = Boolean(values.addressTemplateId);

  return (
    <LtDialog
      open={open}
      onClose={onClose}
      onCancel={onClose}
      saveAction={{
        text: t('save'),
        onClick: handleSave,
        loading,
      }}
      title={isEdit ? t('md.defaultContent.editAddress') : t('md.defaultContent.addAddress')}
      size='md'
      withActionDivider
    >
      {!hideTemplateSelector && (
        <Box display='flex' mb={2} ml='auto'>
          <GenericTemplateSelector
            onSelect={handleTemplateSelect}
            templates={(templates || []).map(x => ({ id: x.id, label: getAddressAsOneLine(x) }))}
            disabled={isTemplateSelected || isEdit}
          />
        </Box>
      )}
      {isTemplateSelected ? (
        <AddressBox address={values as Address} onDelete={isEdit ? null : () => setValues({})} />
      ) : (
        <Box display='flex' flexDirection='column' gap={2}>
          <TextField {...generateTextFieldProps('label')} />
          <TextField {...generateTextFieldProps('addressee')} />
          <TextField {...generateTextFieldProps('addressLine1')} />
          <TextField {...generateTextFieldProps('addressLine2')} />

          <Box display='flex' gap={2}>
            <TextField {...generateTextFieldProps('postCode')} />
            <TextField {...generateTextFieldProps('city')} />
          </Box>
          <TextField {...generateTextFieldProps('country')} />
        </Box>
      )}
    </LtDialog>
  );
};
