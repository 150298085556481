import { Account, ProfileDesignConfig } from '@/shared/types/api';
import { Edit } from '@mui/icons-material';
import { Avatar, Box, Button, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { routePaths } from '@/infrastructure/constants';
import { useTranslation } from 'react-i18next';
import { useIsDesktopMui } from '@/infrastructure/hooks/useIsDesktopMui';
import {
  getFullName,
  getProfileImageUrlWithGlobalDefault,
} from '@/shared/business-logic/features/profile/getter';
import { CardRenderer } from '@/components/CardRenderer';

type Props = {
  account: Account;
  profileDesign: ProfileDesignConfig;
};

export const BusinessCardCard = ({ account, profileDesign }: Props) => {
  const { t } = useTranslation();
  const avatar = getProfileImageUrlWithGlobalDefault(account, profileDesign);
  const fullName = getFullName(account);

  const isDesktop = useIsDesktopMui();

  const buttonProps = {
    component: Link,
    to: `/${account.username}?forceProfile=true&backTo=${routePaths.HOME}`,
    target: isDesktop ? '_blank' : '_self',
  };

  const actions = [
    <Button
      {...buttonProps}
      color='primary'
      variant='outlined'
      aria-label={t('ariaPreviewProfile')}
    >
      {t('preview')}
    </Button>,
    <Button component={Link} to={routePaths.EDITPROFILE} startIcon={<Edit />}>
      {t('editProfile')}
    </Button>,
  ];

  const { breakpoints } = useTheme();

  return (
    <CardRenderer
      title={t('homePage.digitalBusinessCard')}
      actions={actions}
      sx={{ [breakpoints.up('md')]: { height: '32rem' } }}
    >
      <Box display='flex' alignItems='center'>
        <Avatar
          sx={{
            width: isDesktop ? '11rem' : '7rem',
            height: isDesktop ? '11rem' : '7rem',
            mr: '2.4rem',
          }}
          src={avatar}
          {...buttonProps}
        />
        <Box>
          <Box lineHeight={1.5} typography={isDesktop ? 'h2' : 'h3'}>
            {fullName}
          </Box>
          <Typography
            lineHeight={1.5}
            color='text.secondary'
            variant={isDesktop ? 'body1' : 'body2'}
          >
            {account.position}
          </Typography>
          <Typography
            lineHeight={1.5}
            color='text.secondary'
            variant={isDesktop ? 'body1' : 'body2'}
          >
            {account.company}
          </Typography>
        </Box>
      </Box>
    </CardRenderer>
  );
};
