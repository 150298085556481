import { routePaths } from '@/infrastructure/constants';
import { CardGiftcardOutlined } from '@mui/icons-material';
import { Box, Button, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const NFCCardConnector = ({ sidebarCollapsed }) => {
  const { t } = useTranslation();
  return (
    <Box px={1} mb={'1rem'} display='flex' justifyContent='center'>
      {sidebarCollapsed ? (
        <IconButton
          component={Link}
          to={routePaths.REFERRALS}
          sx={theme => ({
            color: theme.palette.primaryButton.contrastText,
            bgcolor: theme.palette.primaryButton.main,
            '&:hover, &:focus-visible': {
              bgcolor: theme.palette.primaryButton.main,
            },
          })}
        >
          <CardGiftcardOutlined />
        </IconButton>
      ) : (
        <Button component={Link} to={routePaths.REFERRALS} startIcon={<CardGiftcardOutlined />}>
          {t('referrals.menuButton')}
        </Button>
      )}
    </Box>
  );
};
