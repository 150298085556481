import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export default function useQueryState(
  key: string,
  defaultValue: string,
  onValidate?: (val: string) => boolean,
  onChangeCallback?: (newVal: string) => void,
) {
  const getValueFromQuery = useCallback(() => {
    const query = new URLSearchParams(window.location.search);
    if (onValidate && !onValidate(query.get(key))) return defaultValue;
    return query.get(key) || defaultValue;
  }, [onValidate, key, defaultValue]);

  const [value, setValue] = useState(getValueFromQuery());

  const history = useHistory();
  const updateValue = (val: string) => {
    setValue(val);
    const query = new URLSearchParams(window.location.search);
    if (!onValidate || onValidate(val)) query.set(key, val);
    history.replace(window.location.pathname + '?' + query.toString());
    onChangeCallback?.(val);
  };

  useEffect(() => {
    const handleQueryChange = () => {
      const newValue = getValueFromQuery();
      if (newValue !== value) {
        setValue(newValue);
        onChangeCallback?.(newValue);
      }
    };

    const unlisten = history.listen(location => {
      if (location.search) {
        handleQueryChange();
      }
    });

    return () => unlisten();
  }, [history, key, defaultValue, value, onChangeCallback, getValueFromQuery]);

  return [value, updateValue] as const;
}
