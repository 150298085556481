import {
  Account,
  AccountImageState,
  Address,
  LinkType,
  ProfileDesignConfig,
} from '../../../types/api';
import { base64UrlEncode, removeDiacritics } from '../../../util';

export const defaultGlobalProfileAvatar =
  'https://lemontaps.s3.eu-central-1.amazonaws.com/themes/defaults/profile.png';

export const defaultGlobalBanner =
  'https://lemontaps.s3.eu-central-1.amazonaws.com/themes/defaults/banner.jpg';

export const getBannerImageFromProfileDesign = (profileDesign: ProfileDesignConfig) => {
  if (profileDesign.defaultBannerImageState === AccountImageState.DELETED) return '';
  if (profileDesign.defaultBannerImageState === AccountImageState.DEFAULT)
    return defaultGlobalBanner;
  return profileDesign.defaultBannerImageUrl || defaultGlobalBanner;
};

export const getProfileImageFromProfileDesign = (profileDesign: ProfileDesignConfig) => {
  if (profileDesign.defaultProfileImageState === AccountImageState.DELETED) return '';
  if (profileDesign.defaultProfileImageState === AccountImageState.DEFAULT)
    return defaultGlobalProfileAvatar;
  return profileDesign.defaultProfileImageUrl || defaultGlobalProfileAvatar;
};

export const getLogoHeaderUrlFromProfileDesign = (profileDesign: ProfileDesignConfig) => {
  if (profileDesign.logoHeaderState === AccountImageState.DELETED) return '';
  return profileDesign.logoHeaderUrl || '';
};

export function getProfileImageUrl(account: Account, profileDesign: ProfileDesignConfig) {
  if (account.profileImageState === AccountImageState.DELETED) return '';

  if (account.profileImageState === AccountImageState.DEFAULT)
    return getProfileImageFromProfileDesign(profileDesign);

  return account.profileImageUrl || '';
}

export function getBannerImageUrl(account: Account, profileDesign: ProfileDesignConfig) {
  if (account.bannerImageState === AccountImageState.DELETED) return '';

  if (account.bannerImageState === AccountImageState.DEFAULT)
    return getBannerImageFromProfileDesign(profileDesign);

  return account.bannerImageUrl || '';
}

export function getLogoHeaderUrl(account: Account, profileDesign: ProfileDesignConfig) {
  if (account.logoHeaderState === AccountImageState.DELETED) return '';

  if (account.logoHeaderState === AccountImageState.DEFAULT)
    return getLogoHeaderUrlFromProfileDesign(profileDesign);

  return account.logoHeaderUrl || '';
}

export const getProfileImageUrlWithGlobalDefault = (
  account: Account,
  profileDesign: ProfileDesignConfig,
) => {
  if (account.profileImageState === AccountImageState.DELETED) return defaultGlobalProfileAvatar;

  if (account.profileImageState === AccountImageState.DEFAULT)
    return getProfileImageFromProfileDesign(profileDesign) || defaultGlobalProfileAvatar;

  return (
    account.profileImageUrl ||
    getProfileImageFromProfileDesign(profileDesign) ||
    defaultGlobalProfileAvatar
  );
};

// compatible with leads
export function getFullName(
  entity:
    | Partial<
        Pick<
          Account,
          'namePrefix' | 'academicDegree' | 'academicDegree2' | 'firstName' | 'lastName'
        >
      >
    | undefined,
  options: {
    replaceDiacritics?: boolean;
    removeNonHttpHeaderCompliantChars?: boolean;
  } = {},
) {
  if (!entity) return '';

  const parts = [
    entity.academicDegree,
    entity.academicDegree2,
    entity.namePrefix,
    entity.firstName,
    entity.lastName,
  ]
    .filter(Boolean)
    .map(s => (s ? s.trim() : s));

  let str = parts.join(' ').replace(/\n/g, ' ').trim();

  if (options.replaceDiacritics) str = removeDiacritics(str);
  if (options.removeNonHttpHeaderCompliantChars) str = str.replace(/([^a-z0-9 ]+)/gi, '');

  return str;
}

export function getFullLinkUrl(baseLink: string, linkType: LinkType | undefined) {
  let url = baseLink;
  switch (linkType?.mode) {
    case 'username':
    case 'phone':
      url = linkType.modeMeta + baseLink;
      break;
    default:
      if (baseLink?.startsWith('https')) url = baseLink;
      else url = 'https://' + baseLink;
      break;
  }
  return url;
}

export function generateProfileLink(
  account: Account,
  options: {
    lt?: { lt_medium?: string };
    unit?: number | { id: number }; // id
  } = {},
): string {
  const domain = account.theme?.domain?.trim() || 'https://lemontaps.com';
  const profileLink = new URL(`${domain}/${account.username}`);

  if (options.lt) {
    Object.entries(options.lt)
      .filter(([key, value]) => value !== undefined)
      .forEach(([key, value]) => profileLink.searchParams.append(key, value));
  }
  if (options.unit) {
    const unitId = typeof options.unit === 'number' ? options.unit : options.unit.id;
    profileLink.searchParams.append('uid', base64UrlEncode(unitId.toString()));
  }

  return profileLink.toString();
}

export function getAddressAsOneLine(address: Address) {
  return [
    address.label,
    address.addressee,
    address.addressLine1,
    address.addressLine2,
    [address.postCode, address.city].filter(Boolean).join(' '),
    address.country,
  ]
    .filter(Boolean)
    .join(', ');
}
