import LtActionButtonBar from '@/components/LtActionButtonBar';
import { routePaths } from '@/infrastructure/constants';
import { withSilentAccessToken } from '@/infrastructure/helper';
import useQuery from '@/infrastructure/hooks/useQuery';
import { EastOutlined, WestOutlined } from '@mui/icons-material';
import { Box, Alert, AlertTitle, Button, Typography } from '@mui/material';
import { useMemo, useRef, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import config from '@/config/config';
import { Account } from '@/shared/types/api';
import { trackPublicEvent } from '@/infrastructure/apis/analytics';
import { PUBLIC_EVENTS } from '@/shared/constants';
import { useTranslation } from 'react-i18next';
import ResponsiveProfileWidthContainer from '@/components/Profile/components/ResponsiveProfileContainer';
import useDeviceInfo from '@/infrastructure/hooks/useDeviceInfo';

interface Props {
  isAuthenticated: boolean;
  isOwnProfile?: boolean;
  isOwnTheme?: boolean;
  account?: Account;
  profileUsername: string;
  lt2LtSuccessCallback?: () => void;
  goBackTarget?: string;
}

// renders 'back to platform' button when authenticated
// and handles l2lt logic + UI
export default function BottomAuthBar({
  isAuthenticated,
  isOwnProfile,
  isOwnTheme,
  account,
  profileUsername,
  lt2LtSuccessCallback,
  goBackTarget,
}: Props) {
  const { t } = useTranslation();

  const query = useQuery();
  const history = useHistory();
  const { getAccessTokenSilently, isLoading: isAuth0Loading } = useAuth0();

  const { isStandalone } = useDeviceInfo();

  const onBackButtonClickHandler = useCallback(() => {
    const redirectUrl =
      goBackTarget ??
      (isOwnProfile
        ? routePaths.EDITPROFILE
        : isOwnTheme
        ? routePaths.MD.PROFILES
        : routePaths.HOME);
    console.log({ redirectUrl, goBackTarget });
    return history.push(redirectUrl);
  }, [goBackTarget, history, isOwnProfile, isOwnTheme]);

  // lt2lt
  const accountDataLoaded = useMemo(() => account && Object.keys(account).length > 0, [account]);
  const addedContactId = useRef<string>(null);

  const lt2ltDisabled = useMemo(
    () => query.get('lt2lt') === 'false' || localStorage.getItem('lt2lt') === 'false',
    [query],
  );

  const isLt2LtFlow = useMemo(
    () =>
      !addedContactId.current &&
      !lt2ltDisabled &&
      isAuthenticated &&
      accountDataLoaded &&
      getAccessTokenSilently &&
      !isOwnProfile,
    [accountDataLoaded, getAccessTokenSilently, isAuthenticated, isOwnProfile, lt2ltDisabled],
  );

  useEffect(() => {
    if (!isLt2LtFlow) return;

    withSilentAccessToken(getAccessTokenSilently, token =>
      axios.post(
        config.API_BASE_URL + `lead-gen/accounts/${account.id}/connect`,
        {
          ltId: profileUsername,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      ),
    )
      .then(res => {
        if (res?.data?.isSuccess) {
          trackPublicEvent(
            {
              username: account?.username,
              isAuthenticated,
              isAuth0Loading,
            },
            PUBLIC_EVENTS.CONTACT_LT2LT_SAVED,
            profileUsername,
            null,
            null,
            { username: account?.username },
          );
          addedContactId.current = res.data?.data?.id;
        }
      })
      .catch(err => {
        if (err?.response?.data?.error?.code === 'exists-already') {
          addedContactId.current = err.response.data.error?.data?.id;
        }
        // do nothing (may be error, or lt2lt may be forbidden by themeInternal.lt2ltInternal)
      });
  }, [
    account.id,
    account?.username,
    getAccessTokenSilently,
    isAuth0Loading,
    isAuthenticated,
    isLt2LtFlow,
    lt2LtSuccessCallback,
    profileUsername,
  ]);

  const onViewContactClickHandler = useCallback(
    () => history.push(routePaths.CONTACTS.EDIT.replace(':leadId', addedContactId.current)),
    [history],
  );

  if (!isAuthenticated || !accountDataLoaded) return null;

  if (isLt2LtFlow) {
    return (
      <LtActionButtonBar
        renderRelativeBox
        explicitOffsets={{ left: 0, bottom: 0, right: 0 }}
        sx={{ pb: !isStandalone ? 2 : undefined }} // decrease padding to have even padding when not standalone
        contentLocation='center'
        customElemsPre={[
          <Box flex={1}>
            <ResponsiveProfileWidthContainer>
              <Alert
                severity='success'
                variant='standard'
                action={
                  <Button
                    variant='text'
                    endIcon={<EastOutlined />}
                    onClick={onViewContactClickHandler}
                  >
                    {t('publicProfile.autoConnect.button')}
                  </Button>
                }
              >
                <AlertTitle>{t('publicProfile.autoConnect.heading')}</AlertTitle>
                <Typography variant='body1'>{t('publicProfile.autoConnect.subheading')}</Typography>
              </Alert>
            </ResponsiveProfileWidthContainer>
          </Box>,
        ]}
      />
    );
  } else {
    return (
      <LtActionButtonBar
        renderRelativeBox
        explicitOffsets={{ left: 0, bottom: 0, right: 0 }}
        contentLocation='left'
        customActionsPre={[
          {
            text: t('publicProfile.actionsBar.buttonLabel'),
            variant: 'outlined',
            color: 'primary',
            onClick: onBackButtonClickHandler,
            startIcon: <WestOutlined />,
          },
        ]}
      />
    );
  }
}
