export const dispatchTypes = {
  LOGIN: {
    CHECKLOGIN: 'CHECK_LOGIN',
    LOGINSUCESS: 'LOGIN_SUCCESS',
    LOGINERROR: 'LOGIN_ERROR',
    DOMAIN_MISMATCH: 'DOMAIN_MISMATCH',
    DOMAIN_VERIFIED: 'DOMAIN_VERIFIED',
  },
  LANDING: {
    HAS_ACCOUNT_INIT: 'HAS_ACCOUNT_INIT',
    HAS_ACCOUNT_SUCCESS: 'HAS_ACCOUNT_SUCCESS',
    HAS_ACCOUNT_ERROR: 'HAS_ACCOUNT_ERROR',
    ADD_CODE_INIT: 'ADD_CODE_INIT',
    ADD_CODE_SUCCESS: 'ADD_CODE_SUCCESS',
    ADD_CODE_ERROR: 'ADD_CODE_INIT',
    SET_AUTH_CALLBACK_STATE: 'SET_AUTH_CALLBACK_STATE',
    RESET_AUTH_CALLBACK_STATE: 'RESET_AUTH_CALLBACK_STATE',
  },
  ONBOARDING: {
    SHOWLOADER: 'ONBOARDING_SHOW_LOADER',
    ERROR: 'ONBOARDING_ERROR',
    ONBOARDINGOPERATIONS: {
      VALIDATESUCCESS: 'VALIDATE_SUCCESS',
      VALIDATEERROR: 'VALIDATE_ERROR',
      SUCCESS: 'ADD_FIRST_NAME_SUCCESS',
      ERROR: 'ADD_FIRST_NAME_ERROR',
    },
    ADDCODE: {
      SUCCESS: 'ADD_CODE_SUCCESS',
      ERROR: 'ADD_CODE_ERROR',
    },
    ADDPHOTO: {
      SUCCESS: 'ADD_PHOTO_SUCCESS',
      ERROR: 'ADD_PHOTO_ERROR',
    },
  },
  ACCOUNTVALIDATION: {
    SENDMAIL: 'SEND_VALIDATION_EMAIL',
    SENDMAILSUCCESS: 'SEND_VALIDATION_EMAIL_SUCCESS',
    SENDMAILERROR: 'UPDATE_EMAIL_ERROR',
    VALIDATEACCOUNT: 'VALIDATE_ACCOUNT',
    VALIDATEACCOUNTSUCCESS: 'VALIDATE_ACCOUNT_SUCCESS',
    VALIDATEACCOUNTERROR: 'VALIDATE_ACCOUNT_ERROR',
  },
  EDITPROFILE: {
    SHOWLOADER: 'EDIT_PROFILE_SHOW_LOADER',
    CLEARAPIRESPONSE: 'EDIT_PROFILE_CLEAR_API_RESPONSE',
    SAVELINKSUCCESS: 'SAVE_LINK_SUCCESS',
    SAVELINKERROR: 'SAVE_LINK_ERROR',
    DELETELINKSUCCESS: 'DELETE_LINK_SUCCESS',
    DELETELINKERROR: 'DELETE_LINK_ERROR',
    UPLOADPROFILEPICSUCCESS: 'UPLOAD_PROFILE_PIC_SUCCESS',
    UPLOADPROFILEPICERROR: 'UPLOAD_PROFILE_PIC_ERROR',
    UPLOADBANNERSUCCESS: 'UPLOAD_BANNER_SUCCESS',
    UPLOADBANNERERROR: 'UPLOAD_BANNER_ERROR',
    UPLOADFILESUCCESS: 'UPLOAD_FILE_SUCCESS',
    UPLOADFILEERROR: 'UPLOAD_FILE_ERROR',
    HIDELOADER: 'EDIT_PROFIL_HIDE_LOADER',
    DELETEFILE: 'DELETE_FILE',
    DELETEFILESUCCESS: 'DELETE_FILE_SUCCESS',
    DELETEFILEERROR: 'DELETE_FILE_ERROR',
    UPDATEIMGURLERROR: 'UPDATE_IMG_URL_ERROR',
    SETTHEMELINKTYPES: 'SET_THEME_LINK_TYPES',
    SETTHEMELINKTYPESERROR: 'SET_THEME_LINK_TYPES_ERROR',
    SETLINKTYPESLOADING: 'SET_LINK_TYPES_LOADING',
    REORDERLINKSSUCCESS: 'REORDER_LINKS_SUCCESS',
    REORDERLINKSERROR: 'REORDER_LINKS_ERROR',
    REORDERFILESSUCCESS: 'REORDER_FILES_SUCCESS',
    REORDERFILESERROR: 'REORDER_FILES_ERROR',
    SAVEFILESUCCESS: 'SAVE_FILE_SUCCESS',
    EDITPROFILELINKS: 'EDIT_PROFILE_LINKS',
    SAVECOMPANYDETAILSLINK: 'SAVE_COMPANY_DETAILS_LINK',
  },
  EDITBIO: {
    SAVE: 'SAVE',
    SAVESUCCESS: 'SAVE_SUCCESS',
    SAVEERROR: 'SAVE_ERROR',
    CLEARAPIRESPONSE: 'EDIT_BIO_CLEAR_API_RESPONSE',
  },
  LEAD_GEN: {
    SAVELEAD: 'SAVE_LEAD',
    SAVELEADSUCCESS: 'SAVE_LEAD_SUCCESS',
    SAVELEADERROR: 'SAVE_LEAD_ERROR',
    GET_LEADS_INIT: 'GET_LEADS_INIT',
    GET_LEADS_SUCCESS: 'GET_LEADS_SUCCESS',
    NOT_ALLOWED: 'NOT_ALLOWED',
    GET_LEADS_ERROR: 'GET_LEADS_ERROR',
    LOADING: 'LOADING',
    RESET_SINGLE_LEAD: 'RESET_SINGLE_LEAD',
    RESET: 'RESET',
    ADD_LEAD: {
      SUCCESS: 'ADD_NEW_LEAD_SUCCESS',
      ERROR: 'ADD_NEW_LEAD_ERROR',
    },
    EDIT_LEAD: {
      SUCCESS: 'EDIT_LEAD_SUCCESS',
      ERROR: 'EDIT_LEAD_ERROR',
    },
    DELETE_LEAD: {
      INIT: 'DELETE_LEAD_INIT',
      SUCCESS: 'DELETE_LEAD_SUCCESS',
      ERROR: 'DELETE_LEAD_ERROR',
    },
  },
  COMMON: {
    UPDATEACCOUNT: 'UPDATE_ACCOUNT',
    FETCHUSER: 'FETCH_USER',
    UPDATEBANNERURL: 'UPDATE_BANNER_URL',
    UPDATECONTACT: 'UPDATE_CONTACT',
    USERLOGOUT: 'USER_LOGOUT',
    DELETEFILE: 'DELETE_FILE_USER',
    UPDATEBIODETAILS: 'UPDATE_BIO_DETAILS',
    DELETELINK: 'DELETE_LINK',
    UPDATEFIELD: 'UPDATE_FIELD',
    ONBOARDINGSILENTLOGIN: 'ONBOARDING_SILENT_LOGIN',
  },
  TEMP: {
    THEME: {
      SETCOLOR: 'TEMP_THEME_SET_COLOR',
      RESETCOLORS: 'TEMP_THEME_RESET_COLORS',
    },
  },
  BUSINESS: {
    SHOWLOADER: 'SHOW_LOADER',
    HIDELOADER: 'HIDE_LOADER',
    FETCHEMPLOYEES: 'FETCH_EMPLOYEES',
    SETSEARCHTERM: 'SET_SEARCH_TERM',
    CLEARSEARCHTERM: 'CLEAR_SEARCH_TERM',
    SETSELECTEDCOLUMN: 'SET_SELECTED_COLUMN',
    CLEARSELECTEDCOLUMN: 'CLEAR_SELECTED_COLUMN',
    CLEARAPIRESPONSE: 'CLEAR_API_RESPONSE',
    ADD_EMPLOYEE: 'ADD_EMPLOYEE',
    COMMON: {
      ADDLINK: 'ADD_LINK',
      ADDLINKS: 'ADD_LINKS',
      UPDATELINK: 'UPDATE_LINK',
      DELETELINK: 'DELETE_LINK',
      DELETELINKS: 'DELETE_LINKS',
      ADDFILE: 'ADD_FILE',
      ADDALLFILES: 'ADD_ALL_FILES',
      UPDATEFILE: 'UPDATE_FILE',
      DELETEFILE: 'DELETE_FILE',
      SENDEMAILSUCCESS: 'SEND_EMAIL_SUCCESS',
      SENDEMAILERROR: 'SEND_EMAIL_ERROR',
      UPDATEFIELD: 'BUSINESS_UPDATE_FIELD',
      UPDATEFIELDS: 'BUSINESS_UPDATE_FIELDS',
    },
    LINKS: {
      SHOWLOADER: 'SHOW_LOADER',
      HIDELOADER: 'HIDE_LOADER',
      SETLINKSSUCCESS: 'SET_LINKS_SUCCESS',
      SETLINKSERROR: 'SET_LINKS_ERROR',
      SAVELINKSUCCESS: 'SAVE_LINK_SUCCESS',
      SAVELINKERROR: 'SAVE_LINK_ERROR',
      DELETELINKSUCCESS: 'DELETE_LINK_SUCCESS',
      DELETELINKERROR: 'DELETE_LINK_ERROR',
      REORDER_SUCCESS: 'LINKS_REORDER_SUCCESS',
      REORDER_FAIL: 'LINKS_REORDER_FAIL',
    },
    FILES: {
      SHOWLOADER: 'SHOW_LOADER',
      HIDELOADER: 'HIDE_LOADER',
      SAVEFILESUCCESS: 'SAVE_FILE_SUCCESS',
      SAVEFILEERROR: 'SAVE_FILE_ERROR',
      UPDATEFILESUCCESS: 'UPDATE_FILE_SUCCESS',
      UPDATEFILEERROR: 'UPDATE_FILE_ERROR',
      DELETEFILESUCCESS: 'DELETE_FILE_SUCCESS',
      DELETEFILEERROR: 'DELETE_FILE_ERROR',
      REORDER_SUCCESS: 'FILES_REORDER_SUCCESS',
      REORDER_FAIL: 'FILES_REORDER_FAIL',
    },
    BULK: {
      UPDATE_SUCCESS: 'BULK_UPDATE_SUCCESS',
      UPDATE_ERROR: 'BULK_UPDATE_ERROR',
      INVITE_SUCCESS: 'BULK_INVITE_SUCCESS',
      INVITE_ERROR: 'BULK_INVITE_ERROR',
      SET_COMMON_LINKS: 'SET_COMMON_LINKS',
      SET_COMMON_FILES: 'SET_COMMON_FILES',
    },
    THEME: {
      UPDATE_SUCCESS: 'THEME_UPDATE_SUCCESS',
      UPDATE_ERROR: 'THEME_UPDATE_ERROR',
      UPLOAD_SUCCESS: 'THEME_UPLOAD_SUCCESS',
      UPLOAD_ERROR: 'THEME_UPLOAD_ERROR',
    },
    THEME_INTERNAL: {
      UPDATE_NAME_SUCCESS: 'THEME_INTERNAL_UPDATE_NAME_SUCCESS',
      UPDATE_NAME_ERROR: 'THEME_INTERNAL_UPDATE_NAME_ERROR',
      UPDATE_DESIGN_SUCCESS: 'THEME_INTERNAL_UPDATE_DESIGN_SUCCESS',
      UPDATE_DESIGN_ERROR: 'THEME_INTERNAL_UPDATE_DESIGN_ERROR',
      UPDATE_FIELDS: 'THEME_INTERNAL_UPDATE_FIELDS',
    },
  },
  EDITEMPLOYEE: {
    SAVE: 'SAVE',
    SAVESUCCESS: 'SAVE_SUCCESS',
    SAVEERROR: 'SAVE_ERROR',
    CLEARAPIRESPONSE: 'EDIT_EMPLOYEE_CLEAR_API_RESPONSE',
    SHOWLOADER: 'EDIT_EMPLOYEE_SHOW_LOADER',
    HIDELOADER: 'EDIT_EMPLOYEE_HIDE_LOADER',
    UPLOADFILEERROR: 'UPLOAD_FILE_ERROR',
    UPDATEIMGURLERROR: 'UPDATE_IMG_URL_ERROR',
    UPDATEIMAGE: 'UPDATE_IMAGE',
  },
  ADDEMPLOYEE: {
    SAVE: 'SAVE',
    SAVESUCCESS: 'SAVE_SUCCESS',
    SAVEERROR: 'SAVE_ERROR',
    CLEARAPIRESPONSE: 'EDIT_EMPLOYEE_CLEAR_API_RESPONSE',
    UPLOADIMAGE: 'UPLOAD_IMAGE',
  },
  VERIFICATION: {
    SEND_VERIFICATION_EMAIL: 'SEND_VERIFICATION_EMAIL',
    SEND_VERIFICATION_EMAIL_SUCCESS: 'SEND_VERIFICATION_EMAIL_SUCCESS',
    SEND_VERIFICATION_EMAIL_ERROR: 'SEND_VERIFICATION_EMAIL_ERROR',
  },
  UI: {
    SET_SIDEBAR_COLLAPSED: 'SET_SIDEBAR_COLLAPSED',
    SET_SIDEBAR_MY_PROFILE_COLLAPSED: 'SET_SIDEBAR_MY_PROFILE_COLLAPSED',
    SET_SIDEBAR_ADMIN_MANAGEMENT_COLLAPSED: 'SET_SIDEBAR_ADMIN_MANAGEMENT_COLLAPSED',
    SET_SIDEBAR_SETTINGS_COLLAPSED: 'SET_SIDEBAR_SETTINGS_COLLAPSED',
  },
};
