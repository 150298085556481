import { isFileValid } from '../../../../../../infrastructure/helper';

import { THEME_BOX_STYLES } from '../../../../../../shared/constants';

import { useTranslation } from 'react-i18next';
import { useProfileDesign } from '@/infrastructure/hooks/useProfileDesign';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
import { ImageSettings } from '@/components/ImageSettings';
import { DeleteOutlined, ImageOutlined, Upload } from '@mui/icons-material';
import { AccountImageState } from '@/shared/types/api';
import { Box, styled } from '@mui/material';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';

interface Props {
  logoHeaderUrl?: string;
  defaultLogoHeaderUrl?: string;
  onChange?: (file?: File, url?: string, state?: AccountImageState) => void;
  logoHeaderState?: AccountImageState;
  defaultLogoHeaderState?: AccountImageState;
}

const LogoHeader = ({
  defaultLogoHeaderUrl,
  logoHeaderUrl,
  onChange,
  logoHeaderState,
  defaultLogoHeaderState,
}: Props) => {
  let fileInput;
  const { notify: toast } = useLtNotifications();
  const { t } = useTranslation();
  const { profileDesign } = useProfileDesign();

  const onImageSelection = async () => {
    const uploadedFile = fileInput.files[0];
    if (uploadedFile) {
      const errMsg = isFileValid(uploadedFile, 'imageWithoutSvg', t);
      if (errMsg) {
        toast.error(errMsg);
        return;
      }

      let reader = new FileReader();
      reader.readAsDataURL(uploadedFile);
      reader.onload = () => {
        onChange(uploadedFile, reader.result as string, AccountImageState.DEFINED);
      };
    }
  };

  const { theme: muiTheme } = useMuiTheme();

  const items = [
    {
      icon: <Upload />,
      label: t('uploadNew'),
      onClick: () => fileInput.click(),
    },
    defaultLogoHeaderUrl &&
      logoHeaderState !== AccountImageState.DEFAULT && {
        icon:
          defaultLogoHeaderState === AccountImageState.DELETED ? (
            <DeleteOutlined />
          ) : (
            <ImageOutlined />
          ),
        label:
          defaultLogoHeaderState === AccountImageState.DELETED ? t('delete') : t('resetToDefault'),
        onClick: () => onChange(null, defaultLogoHeaderUrl, AccountImageState.DEFAULT),
      },
  ].filter(Boolean);

  return (
    <>
      {logoHeaderUrl ? (
        <LogoContainer
          showBorder
          borderColor={muiTheme.palette.primary.main}
          boxStyle={profileDesign.boxStyle}
        >
          <LogoImage src={logoHeaderUrl} alt='Logo' />
          <Box sx={{ position: 'absolute', bottom: '-2rem', right: '-2rem' }}>
            <ImageSettings actions={items} ariaLabel={t('editLogo')} />
          </Box>
        </LogoContainer>
      ) : (
        <Box sx={{ m: 'auto', width: 'max-content' }}>
          <ImageSettings actions={items} isButton buttonText={t('addLogo')} />
        </Box>
      )}

      <input
        type='file'
        ref={input => {
          fileInput = input;
        }}
        onClick={event => {
          (event.target as HTMLInputElement).value = null;
        }}
        onChange={onImageSelection}
        style={{ display: 'none' }}
        accept='image/*'
      />
    </>
  );
};

export default LogoHeader;

const LogoImage = styled(Box)({
  height: '80px',
  borderRadius: '10px',
  marginTop: '10px',
  maxWidth: '100%',
  objectFit: 'contain',
});

const LogoContainer = styled(Box)(({ theme, boxStyle, borderColor, showBorder }) => ({
  height: '100px',
  position: 'relative',
  width: '80%',
  margin: 'auto',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '3px 5px',
  [theme.breakpoints.down(435)]: {
    top: '7.5px',
    right: '7.5px',
    height: '40px',
    padding: '1.5px 2.5px',
  },
  ...(showBorder && {
    borderRadius: boxStyle === THEME_BOX_STYLES.ANGULAR ? 0 : '3px',
    border: `2px dashed ${borderColor}`,
  }),
}));
