import { routePaths } from '@/infrastructure/constants';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import {
  AccountCircleOutlined,
  AutoGraphOutlined,
  ContactPhoneOutlined,
  CreditCardOutlined,
  DirectionsOutlined,
  SettingsOutlined,
  SupervisedUserCircleOutlined,
} from '@mui/icons-material';
import { FEATURE } from '@/shared/constants';
import { useAppSelector } from '@/application/hooks';
import { NavItem } from '@/infrastructure/hoc/types';
import { Account } from '@/shared/types/api';
import { useVisibility } from '../useVisibility';

export const useMdItems = (): NavItem[] => {
  const { isUnitAdmin, isFeatureAllowed, isStarter } = useTierInfo();
  const account = useAppSelector<Account>(state => state.account);

  const { isHidden } = useVisibility();

  const settingsRoute = window.location.pathname.startsWith('/md/settings')
    ? window.location.pathname
    : isUnitAdmin
    ? routePaths.MD.CUSTOMISESETTINGS.EDIT_RIGHTS
    : routePaths.MD.CUSTOMISESETTINGS.COMPANY_DATA;

  if (isStarter) return [];

  return [
    {
      to: routePaths.MD.GUIDE,
      icon: DirectionsOutlined,
      text: 'guides',
      hide: isHidden('admin.mdGuide'),
    },
    {
      to: routePaths.MD.PROFILES,
      icon: AccountCircleOutlined,
      text: 'profiles',
    },
    {
      to: routePaths.MD.UNITS,
      icon: SupervisedUserCircleOutlined,
      text: 'units',
      hide: isUnitAdmin || isHidden('admin.mdUnits'),
      badge: !isFeatureAllowed(FEATURE.UNITS) ? 'Enterprise' : undefined,
    },
    {
      to: routePaths.MD.CONTACTS,
      icon: ContactPhoneOutlined,
      text: 'contacts',
      hide: isHidden('admin.mdContacts'),
      badge: !isFeatureAllowed(FEATURE.LEAD_GEN_MD) ? 'Enterprise' : undefined,
    },
    {
      to: routePaths.MD.ANALYTICS,
      icon: AutoGraphOutlined,
      text: 'mdAnalytics.title',
      hide: isUnitAdmin || isHidden('admin.mdAnalytics'),
    },
    {
      to: routePaths.MD.CARDS.ROOT,
      icon: CreditCardOutlined,
      text: 'mdCards.title',
      hide:
        (isUnitAdmin && account?.theme?.name === 'wacker') || // hard coded until we have solution for managing this
        isHidden('admin.mdNfcCards'),
    },
    {
      to: settingsRoute,
      icon: SettingsOutlined,
      text: 'teamSettings',
      ariaLabel: 'teamSettingsSubmenu',
    },
  ];
};
