import { Chip, ChipProps, styled } from '@mui/material';
import { green } from '@mui/material/colors';

type Props = {
  isActive: boolean;
} & ChipProps;

const chipColor = green[200];
const chipColorFocus = green[100];

export const CustomChip = ({ isActive, ...rest }: Props) => {
  return <StyledChip variant='outlined' isActive={isActive} {...rest} />;
};

const StyledChip = styled(Chip)(props => ({
  '&.MuiChip-clickable': {
    '&:hover': {
      backgroundColor: props.isActive ? chipColor : 'unset',
    },
    backgroundColor: props.isActive ? chipColor : 'unset',
  },
  '&.Mui-focusVisible': {
    backgroundColor: chipColorFocus,
  },
}));
