import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import AutoComplete, { AutoCompleteValue } from '../autoComplete';
import { Box, Button, ThemeProvider, Typography, styled } from '@mui/material';
import { AddCircleOutline, ArrowOutward } from '@mui/icons-material';
import { useMuiTheme } from '@/config/theme/useMuiTheme';
import FeatureTeaser from '@/views/pages/md/teaser/featureTeaser';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import { ApiFile } from '@/shared/types/api';
import { UploadfileProps, UploadfileTemplateProps } from './index';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { FEATURE } from '@/shared/constants';
import { withSilentAccessToken } from '@/infrastructure/helper';
import config from '@/config/config';
import { PERMISSIONS, routePaths } from '@/infrastructure/constants';
import { StyledLink } from '../styles';

interface Props {
  setSavedTemplateFiles: Dispatch<SetStateAction<ApiFile[]>>;
  savedTemplateFiles: ApiFile[];
  setShowIndividualFile: Dispatch<SetStateAction<boolean>>;
  employeeId?: string;
  scrollToBottom: () => void;
  totalFiles: ApiFile[];
  isCompanydataSettings?: boolean;
  handleCompanySettingsUpdate?: (props: {
    fileType: 'newFile' | 'templateFile';
    templateFileDetails?: UploadfileTemplateProps;
    newFileDetails?: UploadfileProps;
  }) => void;
  companyFilesList?: ApiFile[];
  userFiles?: Array<ApiFile>;
  setUserFiles?: Dispatch<SetStateAction<Array<ApiFile>>>;
}

const FileSelector = (props: Props) => {
  const { notify: toast } = useLtNotifications();
  const { isFeatureAllowed, isThemeAdmin } = useTierInfo();
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const [initialTemplates, setInitialTemplates] = useState<ApiFile[]>([]);
  const [autoCompleteValues, setAutoCompleteValues] = useState<Array<AutoCompleteValue>>([]);

  const isDisabled = autoCompleteValues.length === 0;

  useEffect(() => {
    const unselectedFiles: AutoCompleteValue[] = [];
    initialTemplates.forEach((file, index) => {
      if (
        [...props.totalFiles, ...(props.companyFilesList || [])].filter(
          _file => _file.fileTemplateId === file.id,
        ).length === 0
      ) {
        unselectedFiles.push({
          id: file.id,
          description: file.fileName,
          index,
          otherData: { ...file },
        });
      }
    });
    setAutoCompleteValues(unselectedFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.savedTemplateFiles, initialTemplates, props.totalFiles]);

  useEffect(() => {
    if (isFeatureAllowed(FEATURE.DATA_TEMPLATES)) {
      withSilentAccessToken(
        getAccessTokenSilently,
        token =>
          axios.get(config.API_BASE_URL + `theme/file-templates`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }),
        [PERMISSIONS.READ.THEME_GLOBAL],
      )
        .then(res => {
          setInitialTemplates(res.data.data);
        })
        .catch(() => toast.error(t('unexpectedError')));
    }
  }, [getAccessTokenSilently, isFeatureAllowed, t, toast]);

  const saveAutoValue = (selectedValue: AutoCompleteValue) => {
    if (selectedValue) {
      if (props.totalFiles.find(file => file.fileTemplateId === selectedValue.id)) return;
      if (props.employeeId) {
        const addedTemplateFile = {
          id: Math.floor(Math.random() * 1000),
          link: selectedValue.otherData.link,
          fileName: selectedValue.otherData.fileName,
          embed: false,
          pageCount: selectedValue.otherData.pageCount,
          canDisplayOnProfile: true,
          fileTemplateId: selectedValue.id,
          order: (props.userFiles?.length + 1) * 10,
        };

        props.setUserFiles([...props.userFiles, addedTemplateFile]);
      } else {
        props.setSavedTemplateFiles([selectedValue.otherData, ...props.savedTemplateFiles]);
        props.scrollToBottom();
        if (props.isCompanydataSettings) {
          props.handleCompanySettingsUpdate({
            fileType: 'templateFile',
            templateFileDetails: {
              id: selectedValue.otherData.id,
              url: selectedValue.otherData.link,
              fileName: selectedValue.otherData.fileName,
              pageCount: selectedValue.otherData.pageCount,
            },
          });
        }
      }
    }
  };
  const { theme: muiTheme } = useMuiTheme();

  return (
    <ThemeProvider theme={muiTheme}>
      <FeatureTeaser condition={!isFeatureAllowed(FEATURE.DATA_TEMPLATES)} text='' fullWidth>
        <AutoCompleteContainer>
          <AutoComplete
            options={autoCompleteValues}
            onSave={saveAutoValue}
            placeHolder={`${t(
              isDisabled ? 'filesTab.noTemplates' : 'filesTab.searchInputPlaceHolder',
            )}...`}
            disabled={isDisabled}
          />
        </AutoCompleteContainer>
        {isThemeAdmin && (
          <StyledLink to={routePaths.MD.CUSTOMISESETTINGS.PROFILE_DESIGN}>
            <Typography
              variant='body2'
              component='div'
              sx={{ '&:hover': { textDecoration: 'underline' } }}
            >
              {t('filesTab.createTemplate')}
            </Typography>
            <ArrowOutward color='primary' fontSize='small' />
          </StyledLink>
        )}
      </FeatureTeaser>
      <Box display='flex' flexDirection='column' alignItems='center' mb={2}>
        <Typography variant='body2' component='div' sx={{ mt: 1.5, mb: 0.5 }} color='secondary'>
          {t('filesTab.orAddIndividualFile')}
        </Typography>
        <Button startIcon={<AddCircleOutline />} onClick={() => props.setShowIndividualFile(true)}>
          {t('addFile')}
        </Button>
      </Box>
    </ThemeProvider>
  );
};

export default FileSelector;

const AutoCompleteContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});
