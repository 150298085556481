import { useAuth0 } from '@auth0/auth0-react';
import { FEATURE } from '../../../../shared/constants';
import { CSS_VARS, LOCAL_STORAGE, routePaths } from '../../../../infrastructure/constants';
import { useAppSelector } from '../../../../application/hooks';
import { useAppTranslation } from '../../../../infrastructure/hooks/useAppTranslation';
import { Account, LeadSortOption, MDLead, SyncType } from '../../../../shared/types/api';
import withNav from '../../../../infrastructure/hoc/withNav';
import { useTranslation } from 'react-i18next';
import useTierInfo from '../../../../infrastructure/hooks/useTierInfo';
import teaserImage from '../../../images/teaser/leadgen-md.png';
import { ExportButton as ExportButtonComp } from './components/ExportButton';
import FeatureTeaser from '../teaser/featureTeaser';
import { useCallback, useMemo, useRef } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import { ReactComponent as ScannerIcon } from '@/views/images/nav/scanner.svg';
import {
  Box,
  Button,
  IconButton,
  Paper,
  TablePagination,
  Theme,
  Typography,
  alpha,
  useMediaQuery,
} from '@mui/material';
import {
  deleteMdLeads,
  exportLeadsToCSV,
  exportLeadsToCrm,
  getMdLeads,
} from '@/infrastructure/apis/leadGen';
import { useState, useEffect } from 'react';
import { AddOutlined, Close, DeleteOutline } from '@mui/icons-material';
import {
  initMdColumnsDef,
  resetMdCols,
  saveContactsTableColumns,
} from '../../lead-gen/MyLeads/utils/columns';
import { useDispatch } from 'react-redux';
import { updateActionPreferences } from '@/application/actions/account';
import { Table } from '../../lead-gen/MyLeads/components/Table';
import { SelectedCountCounter } from '../../lead-gen/MyLeads/components/SelectedCountCounter';
import { ColumnDefinition } from '../profiles/table/utils/constants';
import { useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import usePrivateThemeConfig from '@/infrastructure/hooks/usePrivateThemeConfig';
import { MDLeadFormDrawer } from './components/MDLeadFormDrawer';
import { ColSortMenu, LtDialog, SearchField } from '@/components';
import { useHistory } from 'react-router-dom';
import { NoLeads } from '../../lead-gen/MyLeads/components/NoLeads';
import { useCrmExport } from './hooks/useCrmExport';
import { usePrivateFeatureFlag } from '@/infrastructure/hooks/useFeatureFlags';
import { CustomTableToolbar } from '@/components/Table';
import { useTableSelection } from '@/infrastructure/hooks/useTableSelection';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import { useFilters } from '@/components/FiltersRenderer/useFilters';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import { ExportConfirmationDialog } from '../../lead-gen/components/ExportConfirmationDialog';
import Loader from '@/components/Loader';

const ROWS_PER_PAGE = [25, 50, 100];
const DEFAULT_PER_PAGE = 25;

const MdContacts = () => {
  const { t } = useTranslation();
  const { isFeatureAllowed, loading } = useTierInfo();

  if (loading) {
    return <Loader />;
  }

  if (isFeatureAllowed(FEATURE.LEAD_GEN_MD)) {
    return <FeaturePage />;
  } else {
    return <FeatureTeaser text={t('upgradeTeaser.feature.leadgenMd')} imageSrc={teaserImage} />;
  }
};

export default withNav(
  MdContacts,
  {
    tTitle: 'contacts',
  },
  {
    activeScreen: routePaths.CONTACTS.ROOT,
  },
);

const FeaturePage = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useAppTranslation();
  const { notify: toast } = useLtNotifications();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PER_PAGE);
  const [leads, setLeads] = useState<MDLead[]>([]);

  const [leadIds, setLeadIds] = useState<{ id: number }[]>([]);

  const [total, setTotal] = useState(0);
  const [leadsLoading, setLeadsLoading] = useState(true);
  const [selectedLeadForEdit, setSelectedLeadForEdit] = useState<MDLead>(null);
  const [addEditDrawerOpened, setAddEditDrawerOpened] = useState(false);
  const [focusNotes, setFocusNotes] = useState(false);
  const [bulkDeletePopupOpened, setBulkDeletePopupOpened] = useState(false);
  const [isBulkDeleteLoading, setIsBulkDeleteLoading] = useState(false);

  const [searchWord, setSearchWord] = useState('');
  const [filterQuery, setFilterQuery] = useState<string[]>([]);

  const account: Account = useAppSelector(state => state.account);
  const optionsPreferences = account?.actionPreferences?.mdLeads_options;

  const [options, setOptions] = useState<LeadSortOption>({
    orderBy: 'createdOn',
    sort: 'DESC',
    ...(optionsPreferences || {}),
  });

  const sortOptions = useMemo(() => {
    return {
      orderBy: options.orderBy,
      sort: options.sort,
    };
  }, [options]);

  const [selectedCrmConnectorToExport, setSelectedCrmConnectorToExport] = useState<{
    serviceId: string;
    serviceName: string;
  }>(null);

  const [exporting, setExporting] = useState(false);

  const { config: allowedKeys, loading: allowedKeysLoading } =
    usePrivateThemeConfig<string[]>('lead-details-keys');

  const handleSearch = (value: string) => {
    setLeadsLoading(true);
    setPage(0);
    setSearchWord(value);
  };

  const handleFilterChange = useCallback((queryValues: string[]) => {
    setLeadsLoading(true);
    setPage(0);
    setFilterQuery(queryValues);
  }, []);

  const { openDrawer: openFilterDrawer, Component: FilterComponent } = useFilters(
    'mdLead',
    handleFilterChange,
  );

  const loading = allowedKeysLoading || leadsLoading;

  useDeferredLoader(loading, 'leads-loading');

  useEffect(() => {
    if (!allowedKeys) return;
    setColumns(initMdColumnsDef(allowedKeys));
  }, [allowedKeys]);

  const [columns, setColumns] = useState([]);

  const visibleColumns = columns.filter(x => !x.hidden);

  const { setIsUnsaved } = useUnsavedStatusSetter();
  const history = useHistory();
  const mountedRef = useRef(false);

  const dispatch = useDispatch();

  const fetchLeads = useCallback(async () => {
    try {
      const result = await getMdLeads(getAccessTokenSilently, {
        pageSize: rowsPerPage,
        page,
        searchWord,
        filter: filterQuery,
        ...options,
      });
      setLeads(result.leads);
      setLeadIds(result.leadIds);
      setTotal(result.total);
    } catch (error) {
      toast.error(t('error.general'));
    }
  }, [getAccessTokenSilently, page, t, rowsPerPage, searchWord, options, filterQuery, toast]);

  useEffect(() => {
    const fetch = async () => {
      setLeadsLoading(true);
      await fetchLeads();
      mountedRef.current = true;
      setLeadsLoading(false);
    };
    fetch();
  }, [fetchLeads]);

  const flag_filterEngine_contacts = usePrivateFeatureFlag('filterEngine_contacts');
  const flag_crmIntegration = usePrivateFeatureFlag('crmIntegration');
  const { crmConnectors, hasCallableApideckConnection, apideckConsumer, connectionsToList } =
    useCrmExport(flag_crmIntegration);

  const {
    selectedCount,
    isAllSelected,
    selectedItems,
    unselectAllItems,
    isAnySelectedOnCurrentPage,
    handleCheckboxClick,
    handleHeaderCheckboxClick,
    handleSelectAll,
    isSelectAllVisible,
  } = useTableSelection<{ id: number }>(leadIds, leads);

  const handleSortChange = (newOptions: LeadSortOption) => {
    if (newOptions.orderBy === sortOptions.orderBy && newOptions.sort === sortOptions.sort) return;
    setPage(0);
    setOptions({ ...options, ...newOptions });
    dispatch(
      updateActionPreferences(getAccessTokenSilently, {
        mdLeads_options: { ...options, ...newOptions },
      }),
    );
  };

  const handleColumnChange = (newCols: ColumnDefinition[]) => {
    setColumns(newCols);
    saveContactsTableColumns(newCols, LOCAL_STORAGE.ltMdContactsTableColumns);
  };

  const handleDrawerClose = () => {
    setAddEditDrawerOpened(false);
    setFocusNotes(false);
    setSelectedLeadForEdit(null);
  };

  const addUpdateCB = () => {
    setIsUnsaved(false);
    fetchLeads();
    if (hasCallableApideckConnection && apideckConsumer.syncType === SyncType.AUTOMATIC) {
      setTimeout(() => {
        fetchLeads();
      }, 3000);
    }
  };

  const handleEditClick = (leadId: number, focusNotes?: boolean) => {
    const lead = leads.find(x => x.id === leadId);
    setSelectedLeadForEdit(lead);
    setAddEditDrawerOpened(true);
    setFocusNotes(Boolean(focusNotes));
  };

  const bulkdDeleteLeads = async (ids: number[]) => {
    try {
      await deleteMdLeads(getAccessTokenSilently, ids);
      await fetchLeads();
      setAddEditDrawerOpened(false);
      toast.success(t('mdLeads.deleteSuccess'));
    } catch (error) {
      toast.error(t('mdLeads.deleteError'));
    }
  };

  const handleDeleteLead = (leadId: number) => bulkdDeleteLeads([leadId]);

  const handleBulkDelete = async () => {
    setIsBulkDeleteLoading(true);
    await bulkdDeleteLeads(selectedItems.map(x => x.id));
    setIsBulkDeleteLoading(false);
    setBulkDeletePopupOpened(false);
  };

  const isLg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'), { noSsr: true });
  const flag_businessCardScanner = usePrivateFeatureFlag('businessCardScanner');
  const scanCardBtn = flag_businessCardScanner ? (
    <Button
      sx={{ svg: { width: '2rem', height: '2rem' } }}
      variant='outlined'
      color='primary'
      startIcon={<ScannerIcon />}
      onClick={() =>
        history.push(routePaths.CONTACTS.SCANNER, { onFinishTo: routePaths.MD.CONTACTS })
      }
    >
      {isLg ? t('scanCard') : t('scanCardShort')}
    </Button>
  ) : null;

  const addContactBtn = (
    <Button startIcon={<AddOutlined />} onClick={() => setAddEditDrawerOpened(true)}>
      {isLg ? t('addContact') : t('addContactShort')}
    </Button>
  );

  const handleCsvExport = useCallback(async () => {
    if (selectedCount < 1) {
      toast.error(t('mdLeads.crmExport.emptySelection'));
      return;
    }

    try {
      const res = await exportLeadsToCSV(getAccessTokenSilently, {
        leadIds: (selectedItems || []).map(l => l.id),
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('id', 'company-contacts');
      link.setAttribute('download', 'company-contacts.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success(t('successfullyDownloaded'));
    } catch (error) {
      toast.error(t('error.general'));
    }
  }, [selectedCount, selectedItems, getAccessTokenSilently, t, toast]);

  const handleCrmExport = useCallback(
    async (campaignId: string, configurationValues?: Record<string, string>) => {
      if (!hasCallableApideckConnection) {
        history.push(routePaths.MD.CUSTOMISESETTINGS.CRM_INTEGRATION);
        return;
      }

      const leads = selectedItems;
      if (!leads || leads.length === 0) {
        toast.error(t('mdLeads.crmExport.emptySelection'));
        return;
      }

      try {
        setExporting(true);
        await exportLeadsToCrm(getAccessTokenSilently, {
          leadIds: leads.map(l => l.id),
          serviceId: selectedCrmConnectorToExport.serviceId,
          campaignId,
          configurationValues,
        });
        setSelectedCrmConnectorToExport(null);
        fetchLeads();
        setTimeout(() => {
          fetchLeads();
        }, 3000);

        toast.success(t('mdLeads.crmExport.startSuccess'));
      } catch (error) {
        toast.error(t('mdLeads.crmExport.startFail'));
        console.log(error);
      }
      setExporting(false);
    },
    [
      fetchLeads,
      selectedItems,
      getAccessTokenSilently,
      hasCallableApideckConnection,
      history,
      t,
      toast,
      selectedCrmConnectorToExport?.serviceId,
    ],
  );

  const ExportButton = useCallback(
    () => (
      <ExportButtonComp
        onCsvExportClick={handleCsvExport}
        onCrmExportClick={(serviceId, serviceName) =>
          setSelectedCrmConnectorToExport({ serviceId, serviceName })
        }
        crmList={connectionsToList}
      />
    ),
    [connectionsToList, handleCsvExport],
  );

  const filtersApplied = Boolean(searchWord || Boolean(filterQuery?.length));

  if (!mountedRef.current) return null;

  const noLeadsAreAdded = !loading && total === 0 && !filtersApplied;

  return (
    <>
      {noLeadsAreAdded ? (
        <NoLeads actions={[addContactBtn]} />
      ) : (
        <Box p={2} sx={{ height: `calc(100vh - ${CSS_VARS.LT_DESKTOP_HEADER_HEIGHT_VAR})` }}>
          <Paper
            variant='outlined'
            sx={{
              display: loading && !filtersApplied && !mountedRef.current ? 'none' : 'flex',
              height: '100%',
              flexDirection: 'column',
            }}
          >
            <CustomTableToolbar sx={{ py: '1.6rem' }}>
              <SearchField
                sx={{ flexGrow: 1 }}
                onSearch={handleSearch}
                placeholder={t('leads.searchPlaceholder')}
              />
              {flag_filterEngine_contacts && (
                <IconButton onClick={() => openFilterDrawer()}>
                  <FilterListIcon />
                </IconButton>
              )}
              <Box sx={{ flexGrow: 1 }} />
              {scanCardBtn}
              <ExportButton />
              {addContactBtn}
            </CustomTableToolbar>
            <CustomTableToolbar
              sx={(theme: Theme) => ({
                bgcolor: alpha(theme.palette.primary.main, 0.04),
              })}
            >
              {selectedCount > 0 && (
                <>
                  <SelectedCountCounter
                    total={total}
                    selectAllVisible={isSelectAllVisible}
                    onSelectAll={handleSelectAll}
                    selectedCount={selectedCount}
                  />
                  <Button
                    variant='text'
                    color='primary'
                    startIcon={<Close />}
                    onClick={unselectAllItems}
                  >
                    {t('deselectAll')}
                  </Button>

                  <Button
                    color='error'
                    variant='outlined'
                    startIcon={<DeleteOutline />}
                    onClick={() => setBulkDeletePopupOpened(true)}
                  >
                    {t('delete')}
                  </Button>
                  <ExportButton />
                </>
              )}
              <Box flexGrow={1} />
              <ColSortMenu
                columnDefs={columns}
                setColumnDefs={handleColumnChange}
                reset={() => handleColumnChange(resetMdCols(allowedKeys))}
              />
            </CustomTableToolbar>
            {Boolean(leads.length) ? (
              <>
                <Table
                  leads={leads}
                  onCheckboxClick={handleCheckboxClick}
                  onHeaderCheckboxClick={handleHeaderCheckboxClick}
                  selectedItems={selectedItems}
                  isAllSelected={isAllSelected}
                  isAnySelectedOnCurrentPage={isAnySelectedOnCurrentPage}
                  onDelete={handleDeleteLead}
                  onLeadEditClick={handleEditClick}
                  columns={visibleColumns}
                  sortOptions={sortOptions}
                  onSortOptionsChange={handleSortChange}
                  showCrmExport
                  crmConnectors={crmConnectors}
                />
                <TablePagination
                  rowsPerPageOptions={ROWS_PER_PAGE}
                  component='div'
                  count={total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  labelRowsPerPage={t('rowsPerPage')}
                  onPageChange={(_, page) => setPage(page)}
                  onRowsPerPageChange={({ target: { value } }) => {
                    setRowsPerPage(+value);
                    setPage(0);
                  }}
                  nextIconButtonProps={{ 'aria-label': t('showNextPage') }}
                  backIconButtonProps={{ 'aria-label': t('showPreviousPage') }}
                />
              </>
            ) : (
              <Typography variant='h2' textAlign='center' py={5}>
                {t('leads.noLeadsSearchResult')}
              </Typography>
            )}
          </Paper>
        </Box>
      )}
      <MDLeadFormDrawer
        opened={addEditDrawerOpened}
        onClose={handleDrawerClose}
        lead={selectedLeadForEdit}
        account={account}
        onSave={addUpdateCB}
        focusNotes={focusNotes}
        onDelete={handleDeleteLead}
      />

      <LtDialog
        title={t('requestDelete')}
        open={bulkDeletePopupOpened}
        onClose={() => setBulkDeletePopupOpened(false)}
        onCancel={() => setBulkDeletePopupOpened(false)}
        onDelete={handleBulkDelete}
        loading={isBulkDeleteLoading}
      >
        {t('mdLeads.deleteLeadsMsg', { count: selectedCount })}
      </LtDialog>

      {/* Crm Export Confirmation Dialog */}
      <ExportConfirmationDialog
        exporting={exporting}
        onConfirm={handleCrmExport}
        isAllSelected={isAllSelected}
        onCancel={() => setSelectedCrmConnectorToExport(null)}
        selectedCount={selectedCount}
        selectedCrmConnectorToExport={selectedCrmConnectorToExport}
      />

      {flag_filterEngine_contacts ? FilterComponent : null}
    </>
  );
};
