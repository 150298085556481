import { routePaths } from '@/infrastructure/constants';
import { AccountCircleOutlined, ContactsOutlined, HomeOutlined, Menu } from '@mui/icons-material';
import { NavItem } from '@/infrastructure/hoc/types';
import { usePrivateFeatureFlag } from '../useFeatureFlags';
import { ReactComponent as ScannerIcon } from '@/views/images/nav/scanner.svg';
import { useVisibility } from '../useVisibility';

export const useBottomBarItems = (): NavItem[] => {
  const flag_businessCardScanner = usePrivateFeatureFlag('businessCardScanner');
  const { isHidden } = useVisibility();

  return [
    {
      to: routePaths.HOME,
      icon: HomeOutlined,
      text: 'home',
      hide: isHidden('nonAdmin.home'),
    },
    {
      to: routePaths.EDITPROFILE,
      icon: AccountCircleOutlined,
      text: 'profile',
      hide: isHidden('nonAdmin.editProfile'),
    },
    {
      to: routePaths.CONTACTS.SCANNER,
      icon: ScannerIcon as React.ElementType,
      text: 'scanner',
      hide:
        !flag_businessCardScanner || isHidden('nonAdmin.singleUserContacts.businessCardScanner'),
    },
    {
      to: routePaths.CONTACTS.ROOT,
      icon: ContactsOutlined,
      text: 'contacts',
      hide: isHidden('nonAdmin.singleUserContacts'),
    },

    {
      to: routePaths.SETTINGS.MORE,
      icon: Menu,
      text: 'settings.more',
    },
  ].filter(x => !x.hide) as NavItem[];
};
