import React from 'react';
import { Link } from '@/shared/types/api';
import { TableProps } from '..';
import { invertHex } from '@/infrastructure/helper';
import TooltipInfo from '@/components/TooltipInfo';
import { getFullLinkUrl } from '@/shared/business-logic/features/profile/getter';
import { Box, styled } from '@mui/material';

const VISIBLE_LINKS_COUNT = 8;

interface Props {
  links: Link[];
  selectedLinks: TableProps['SelectedObjects']['selectedLinksProps'];
}

const LinksCell = ({ links, selectedLinks }: Props) => {
  if (!Array.isArray(links)) return null;

  const addFilter = (link: Link) => {
    if (selectedLinks.selectedLink?.id === link.id) {
      selectedLinks.setSelectedLink(null);
    } else {
      selectedLinks.setSelectedLink(link);
    }
  };

  const visibleLinks = links.slice(0, VISIBLE_LINKS_COUNT);

  return (
    <Container>
      {visibleLinks?.map(link => (
        <TooltipInfo
          text={
            <a
              href={getFullLinkUrl(link.link, link.linkType)}
              target='_blank'
              rel='noopener noreferrer'
            >
              {getFullLinkUrl(link.link, link.linkType)}
            </a>
          }
          placement='bottom'
          onHover={true}
          key={link.id}
        >
          <LinkContainer
            $bg={link?.linkType?.backgroundCss}
            $color={invertHex(link?.linkType?.backgroundCss, true)}
            $disabled={selectedLinks?.selectedLink && selectedLinks?.selectedLink?.id !== link.id}
            onClick={() => addFilter(link)}
          >
            <img
              src={link.linkType.iconUrl}
              alt='link-icon'
              style={{ width: '100%', height: '100%' }}
            />
          </LinkContainer>
        </TooltipInfo>
      ))}
    </Container>
  );
};

export default LinksCell;

const Container = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
});

type LinkContainerProps = {
  $disabled?: boolean;
  $color?: string;
  $bg?: string;
};
const LinkContainer: AddProps<typeof Box, LinkContainerProps> = styled(Box)(
  ({ $disabled, $color, $bg }: LinkContainerProps) => ({
    padding: '0.4rem',
    background: $disabled ? 'rgb(0, 0, 0)' : $bg || 'rgb(0, 0, 0)',
    color: $color || 'rgb(48, 48, 48)',
    ...($disabled && { opacity: 0.3 }),
    margin: '1px',
    borderRadius: '8px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '25px',
    height: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  }),
);
