import { AutoGraph, East } from '@mui/icons-material';
import { Avatar, Box, Button, Paper, Skeleton, Typography } from '@mui/material';
import { AnalyticsTeaser, FontWeight } from '@/shared/types/api';
import { Link } from 'react-router-dom';
import { routePaths } from '@/infrastructure/constants';
import { useTranslation } from 'react-i18next';
import { CardRenderer } from '@/components/CardRenderer';

type Props = {
  data: AnalyticsTeaser;
  isLoading: boolean;
};

const PaperCard = ({ title, number }) => {
  return (
    <Paper variant='outlined' sx={{ px: 0.8, py: 0.6, width: '100%' }}>
      <Typography lineHeight={1.5} variant='body2' color='secondary'>
        {title}
      </Typography>
      <Typography
        lineHeight={1.5}
        variant='body2'
        fontSize={'1.8rem'}
        fontWeight={FontWeight.Medium}
      >
        {number}
      </Typography>
    </Paper>
  );
};

export const AnalyticsCard = ({ data, isLoading }: Props) => {
  const { t } = useTranslation();

  return (
    <CardRenderer
      sx={{ height: '15rem' }}
      actions={[
        <Button
          component={Link}
          to={routePaths.ANALYTICS}
          variant='text'
          aria-label={t('ariaViewAllAnalytics')}
          endIcon={<East />}
        >
          {t('homePage.viewAll')}
        </Button>,
      ]}
    >
      <Box sx={{ display: 'flex' }}>
        <Box>
          <Avatar variant='rounded' sx={{ borderRadius: '0.4rem', bgcolor: 'secondary.main' }}>
            <AutoGraph />
          </Avatar>
          <Typography mt={1} variant='h4' component='h2'>
            {t('homePage.analytics.title')}
          </Typography>
        </Box>
        <Box ml={6.4} sx={{ flex: 1, display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 1.6 }}>
          {isLoading ? (
            <>
              <Skeleton
                sx={{ borderRadius: '0.4rem' }}
                variant='rectangular'
                width={'100%'}
                height={'100%'}
              />
              <Skeleton
                sx={{ borderRadius: '0.4rem' }}
                variant='rectangular'
                width={'100%'}
                height={'100%'}
              />
            </>
          ) : (
            <>
              <PaperCard
                title={t('homePage.analytics.profileViews')}
                number={typeof data?.totalProfileVews === 'number' ? data.totalProfileVews : 'NaN'}
              />
              <PaperCard
                title={t('homePage.analytics.leadsCollected')}
                number={typeof data?.totalLeads === 'number' ? data.totalLeads : 'NaN'}
              />
            </>
          )}
        </Box>
      </Box>
    </CardRenderer>
  );
};
