import { CSS_VARS } from '@/infrastructure/constants';
import withNav from '@/infrastructure/hoc/withNav';
import useBeprint from '@/infrastructure/hooks/useBeprint';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';

const MdPaperOrderingSystem = () => {
  const { ssoUrl, error } = useBeprint('approval');

  if (error) return <Typography variant='body1'>{error}</Typography>;
  else return <BePrintIFrame src={ssoUrl} />;
};

export default withNav(
  MdPaperOrderingSystem,
  {
    tTitle: 'mdCards.paperCardsBox.title',
    showBackButton: { linkTo: null },
  },
  {},
);

const BePrintIFrame = styled('iframe')({
  width: '100%',
  minHeight: `calc(100vh - ${CSS_VARS.LT_DESKTOP_HEADER_HEIGHT_VAR})`,
});
