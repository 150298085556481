import { AddressTemplate } from '@/shared/types/api';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  createAddressTemplate,
  deleteAddressTemplate,
  getAddressTemeplates,
  updateAddressTemplate,
} from '@/infrastructure/apis/addressTemplates';
import { AddressBox } from '@/components/Addresses/AddressBox';
import { AddEditAddressDialog } from '@/components/Addresses/AddEditAddressDialog';
import { LtDialog } from '@/components';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import { SectionRenderer } from '../../SectionRenderer';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';

export const AddressSection = () => {
  const { notify: toast } = useLtNotifications();
  const [addAddressTemplateDialogOpened, setAddAddressTemplateDialogOpened] = useState(false);
  const [addressTemplateIdToDelete, setAddressTemplateIdToDelete] = useState<number | null>(null);
  const [addressTemplateToEdit, setAddressTemplateToEdit] = useState<AddressTemplate | null>(null);

  const [addressTemplates, setAddressTemplates] = useState<AddressTemplate[]>([]);

  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();

  useDeferredLoader(loading, 'content-templates-loading');

  const fetchAddressTemplates = useCallback(async () => {
    try {
      const addressTemplates = await getAddressTemeplates(getAccessTokenSilently);
      setAddressTemplates(addressTemplates);
    } catch (error) {
      toast.error(t('md.contentTemplate.errorLoadingAddresss', { id: 'default-content-loading' }));
    }
  }, [getAccessTokenSilently, t, toast]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchAddressTemplates();
      setLoading(false);
    };
    fetchData();
  }, [fetchAddressTemplates]);

  const handleAddressDelete = async () => {
    setDeleting(true);
    try {
      await deleteAddressTemplate(getAccessTokenSilently, addressTemplateIdToDelete);
      await fetchAddressTemplates();
      setAddressTemplateIdToDelete(null);
      toast.success(t('md.contentTemplate.successDeleteAddress'));
    } catch (error) {
      toast.error(t('md.contentTemplate.errorDeleteAddress'));
    }
    setDeleting(false);
  };

  const handleAddressAdd = async (payload: AddressTemplate) => {
    setSaving(true);
    try {
      await createAddressTemplate(getAccessTokenSilently, payload);
      fetchAddressTemplates();
      setAddAddressTemplateDialogOpened(false);
      toast.success(t('md.contentTemplate.successSaveAddress'));
    } catch (error) {
      toast.error(t('md.contentTemplate.errorSavingAddress'));
    }
    setSaving(false);
  };

  const handleEditAddress = async (payload: AddressTemplate) => {
    setSaving(true);

    try {
      await updateAddressTemplate(getAccessTokenSilently, payload);
      fetchAddressTemplates();
      setAddressTemplateToEdit(null);
      toast.success(t('md.contentTemplate.successSaveAddress'));
    } catch (error) {
      toast.error(t('md.contentTemplate.errorSavingAddress'));
      console.log(error);
    }
    setSaving(false);
  };

  return (
    <SectionRenderer
      title={t('md.contentTemplate.addressTemplates')}
      btnAriaLabel={t('addAddressTemplate')}
      onAdd={() => setAddAddressTemplateDialogOpened(true)}
    >
      <Box display='flex' flexDirection='column' gap={2}>
        {addressTemplates.map((addressTemplate, index) => (
          <AddressBox
            key={index}
            address={addressTemplate}
            onDelete={() => setAddressTemplateIdToDelete(addressTemplate.id)}
            onEdit={() => setAddressTemplateToEdit(addressTemplate)}
            chips={[t('template')].filter(Boolean)}
          />
        ))}
      </Box>
      {/* Add Dialog */}
      <AddEditAddressDialog
        open={addAddressTemplateDialogOpened}
        onSave={handleAddressAdd}
        onClose={() => setAddAddressTemplateDialogOpened(false)}
        loading={saving}
        hideTemplateSelector
      />
      {/* Edit Dialog */}
      <AddEditAddressDialog
        open={Boolean(addressTemplateToEdit)}
        onSave={handleEditAddress}
        onClose={() => setAddressTemplateToEdit(null)}
        loading={saving}
        initialValues={{
          ...addressTemplateToEdit,
        }}
      />

      <LtDialog
        size='sm'
        title={t('requestDelete')}
        open={Boolean(addressTemplateIdToDelete)}
        onClose={() => setAddressTemplateIdToDelete(null)}
        onCancel={() => setAddressTemplateIdToDelete(null)}
        onDelete={handleAddressDelete}
        loading={deleting}
      >
        <Typography>{t('deleteMessageAddress')}</Typography>
      </LtDialog>
    </SectionRenderer>
  );
};
