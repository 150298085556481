import { THEME_CONFIG_KEYS } from '@/shared/constants';

import usePrivateThemeConfig from './usePrivateThemeConfig';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { VisibilityConfig } from '@/shared/business-logic/features/visibility/types';
import { HidableConceptKey } from '@/shared/business-logic/features/visibility/constants';

export const initialValues = {
  hiddenConcepts: [],
  isLoading: false,
  mutate: () => {},
};

const VisibilityConfigContext = React.createContext<{
  hiddenConcepts: VisibilityConfig['hiddenConcepts'];
  isLoading: boolean;
  mutate: (hiddenConcepts: HidableConceptKey[]) => void;
}>({
  hiddenConcepts: [],
  isLoading: false,
  mutate: () => {},
});

export const VisibilityConfigProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [hiddenConcepts, setHiddenConcepts] = useState<HidableConceptKey[]>([]);
  const { config, loading } = usePrivateThemeConfig<VisibilityConfig>(
    THEME_CONFIG_KEYS.VISIBILITY_CONFIG,
  );

  useEffect(() => {
    if (config?.hiddenConcepts) {
      setHiddenConcepts(config.hiddenConcepts);
    }
  }, [config]);

  return (
    <VisibilityConfigContext.Provider
      value={{
        hiddenConcepts: hiddenConcepts || [],
        isLoading: loading,
        mutate: setHiddenConcepts,
      }}
    >
      {children}
    </VisibilityConfigContext.Provider>
  );
};

export const useVisibility = () => {
  const context = React.useContext(VisibilityConfigContext);
  if (context === undefined) {
    throw new Error('useVisibilityConfig must be used within a VisibilityConfigProvider');
  }
  const isHidden = useCallback(
    (key: HidableConceptKey) => context.hiddenConcepts.includes(key),
    [context.hiddenConcepts],
  );
  const isVisible = useCallback(
    (key: HidableConceptKey) => !context.hiddenConcepts.includes(key),
    [context.hiddenConcepts],
  );

  const returnObj = useMemo(
    () => ({ ...context, isHidden, isVisible }),
    [context, isHidden, isVisible],
  );
  return returnObj;
};
