import { Account, LeadDataProps, ThemeLanguage } from '../types/api';

/*
##### THEME #####
*/
export const THEME_BOX_STYLES = {
  ANGULAR: 'angular',
  ROUNDED: 'rounded',
} as const;
export const DEFAULT_BOX_STYLE = THEME_BOX_STYLES.ROUNDED;

export const THEME_FILE_EDIT_MODES = {
  NORMAL: 'normal',
  DISALLOW: 'disallow',
} as const;
export const DEFAULT_FILE_EDIT_MODE = THEME_FILE_EDIT_MODES.NORMAL;

export const THEME_LINK_EDIT_MODES = {
  NORMAL: 'normal',
  DISALLOW: 'disallow',
} as const;
export const DEFAULT_LINK_EDIT_MODE = THEME_LINK_EDIT_MODES.NORMAL;

export const GLOBAL_DATA_POSITIONS = {
  BEFORE: 'before',
  AFTER: 'after',
} as const;
export const DEFAULT_GLOBAL_DATA_POSITION = GLOBAL_DATA_POSITIONS.BEFORE;

export const THEME_CONTACT_EXCHANGE_FLOWS = {
  BOTTOM_CARD_STATIC: 'bottomCardStatic',
  POPUP: 'popup',
} as const;
export const DEFAULT_THEME_CONTACT_EXCHANGE_FLOW = THEME_CONTACT_EXCHANGE_FLOWS.BOTTOM_CARD_STATIC;

/*
##### ACCOUNT #####
*/
export const ACCOUNT_STATUSES = {
  ACTIVE: 'active',
  INVITED: 'invited',
  UNINVITED: 'uninvited',
} as const;
export const DEFAULT_ACCOUNT_STATUS = ACCOUNT_STATUSES.UNINVITED;

export const ACCOUNT_ORIGINS = {
  CARD: 'card', // registered after tapping a card
  PLAIN: 'plain', // registered via signup form without a card
  SIGNUP2THEME: 'signup2theme',
  SIGNUP2UNIT: 'signup2unit',
  THEME: 'theme', // LEGACY DO NOT USE account created as part of a theme
  THEME_CSV_IMPORT: 'theme-csvimport',
  THEME_SINGLE: 'theme-single',
  SCIM: 'scim',
  AD_SYNC: 'ad-sync',
  S3_SYNC: 's3-sync',
} as const;

export const LEAD_ORIGINS = {
  MANUAL: 'manual',
  PROFILE_FORM: 'profile-form',
  LT2LT: 'lt2lt',
  SCANNER: 'scanner',
} as const;

export const ACCOUNT_FIELDS_MAX_LENGTH = {
  ID: 255,
  EMAILUSER: 64,
  EMAILDOMAIN: 256,
  EMAIL: 320,
  TEXT: 500,
  FIRSTNAME: 150,
  LASTNAME: 150,
  NUMBER: 100,
  URL: 2000,
  INTEGER: 2147483647, // integer postgress type max value
} as const;

// account roles
export const ROLES = {
  THEME_ADMIN: 'theme_admin',
  UNIT_ADMIN: 'unit_admin',
} as const;

/*
##### LINK TYPE #####
*/
export const LINK_TYPE_MODES = {
  LINK: 'link',
  USERNAME: 'username',
  PHONE: 'phone',
} as const;
export const DEFAULT_LINK_TYPE_MODE = LINK_TYPE_MODES.LINK;

/*
##### LINK TYPE #####
*/
export const LINK_CREATE_EDIT_MODES = {
  CUSTOM: 'custom',
  FROM_TEMPLATES: 'from-templates',
  ALL: 'all',
} as const;
export const DEFAULT_LINK_CREATE_EDIT_MODE = LINK_CREATE_EDIT_MODES.ALL;

/*
##### LEAD OPTION TYPE #####
*/
export const LEAD_OPTION_TYPES = {
  TEXT: 'text',
  SELECT: 'select',
  CHECKBOX: 'checkBox',
} as const;
export const DEFAULT_LEAD_OPTION_TYPE = LEAD_OPTION_TYPES.TEXT;

/*
##### TIERS PLANS
*/
export const TIERS = {
  STARTER: 'starter',
  ESSENTIALS: 'essentials',
  PRO: 'pro',
  ENTERPRISE: 'enterprise',
} as const;
export type Tier = typeof TIERS[keyof typeof TIERS];
export const DEFAULT_TIER = TIERS.ESSENTIALS;

export const TIERS_LEVEL = ['starter', 'essentials', 'pro', 'enterprise'];

/*
##### Auth0
*/

export const AUTH0_PREFIX = 'auth0|' as const;

// https://auth0.com/docs/manage-users/user-migration/bulk-user-imports
export const AUTH0_IMPORT_ERR_CODES = {
  ANY_OF_MISSING: 'ANY_OF_MISSING',
  ARRAY_LENGTH_LONG: 'ARRAY_LENGTH_LONG',
  ARRAY_LENGTH_SHORT: 'ARRAY_LENGTH_SHORT',
  CONFLICT: 'CONFLICT',
  CONFLICT_EMAIL: 'CONFLICT_EMAIL',
  CONFLICT_USERNAME: 'CONFLICT_USERNAME',
  CONNECTION_NOT_FOUND: 'CONNECTION_NOT_FOUND',
  DUPLICATED_USER: 'DUPLICATED_USER',
  ENUM_MISMATCH: 'ENUM_MISMATCH',
  FORMAT: 'FORMAT',
  INVALID_TYPE: 'INVALID_TYPE',
  MAX_LENGTH: 'MAX_LENGTH',
  MAXIMUM: 'MAXIMUM',
  MFA_FACTORS_FAILED: 'MFA_FACTORS_FAILED',
  MIN_LENGTH: 'MIN_LENGTH',
  MINIMUM: 'MINIMUM',
  NOT_PASSED: 'NOT_PASSED',
  OBJECT_REQUIRED: 'OBJECT_REQUIRED',
  PATTERN: 'PATTERN',
} as const;

// https://auth0.com/docs/manage-users/user-migration/bulk-user-imports#create-users-json-file
export const AUTH0_IMPORT_FILE_SIZE = 500000;

/*
##### SSO
*/

// supported strategies
export const SSO_STRATEGIES = {
  SAMLP: 'samlp',
  OKTA: 'okta',
  // microsoft azure entra id (active directory)
  ENTRA_ID: 'waad',
} as const;

// saml signature algorithms
export const SAML_SIGN_ALGORITHMS = {
  RSA_SHA1: 'rsa-sha1',
  RSA_SHA256: 'rsa-sha256',
} as const;

// saml digest algorithms
export const SAML_DIGEST_ALGORITHMS = {
  SHA1: 'sha1',
  SHA256: 'sha256',
} as const;

// active directory identity api
export const ENTRA_ID_API = {
  MICROSOFT_IDENTITY_PLATFORM: 'microsoft-identity-platform-v2.0',
  AZURE_ACTIVE_DIRECTORY: 'azure-active-directory-v1.0',
} as const;

/*
##### ACCOUNT STATE EVENT #####
*/

export const ACCOUNT_STATE_TRANSITION_TYPE = {
  SIGNUP: 'signup',
  CREATION: 'creation',
  UPGRADE: 'upgrade',
  DOWNGRADE: 'downgrade',
  START_TRIAL: 'start-trial',
  END_TRIAL: 'end-trial',
  END_TRIAL_NON_MAIN: 'end-trial-non-main',
  ROLE_CHANGE: 'role-change',
  STATUS_CHANGE: 'status-change',
} as const;

export const TRIALSTATUS = {
  NONE: 'none',
  CURRENT: 'current',
  FINISHED: 'finished',
} as const;
export type TrialStatus = typeof TRIALSTATUS[keyof typeof TRIALSTATUS];
export const DEFAULT_TRIALSTATUS = TRIALSTATUS.NONE;

/**
 * File Generation entity
 */

export const FILE_GEN_STATUS = {
  SUCCESS: 'success',
  ERROR: 'error',
  PENDING: 'pending',
} as const;

export const QR_META_TYPES = {
  DESKTOP: 'qr-bg-desktop',
  MOBILE: 'qr-bg-mobile',
  PLAIN: 'qr-plain',
} as const;

// theme languages default values
export const THEME_LANGUAGES_DEFAULTS: Array<ThemeLanguage> = [
  { lang: 'de', isInput: true, isFallback: false },
  { lang: 'en', isInput: false, isFallback: true },
];

export const LANGUAGE_ERROR_KEYS = {
  INVALID_CODE: 'invalid-language-iso-code',
  INVALID_INPUT_NUMBER: 'invalid-input-languages-number',
  INVALID_FALLBACK_NUMBER: 'invalid-fallback-languages-number',
} as const;

// CSV Actions
export const DEFAULT_CSV_ACTION_SETTINGS = { update: { useEmptyColumns: true } } as const;

// lead columns with multilned text
export const LEAD_MULTILINE_FIELDS = ['company', 'role', 'division', 'position'] as Array<
  keyof LeadDataProps
>;

export const ACCOUNT_MULTILINE_FIELDS = [
  'company',
  'role',
  'division',
  'position',
  'bioText',
] as Array<keyof Account>;

export const ACCOUNT_BIO_FIELDS: Array<{ id: keyof Account; tname: string }> = [
  { id: 'academicDegree', tname: 'academicDegree' },
  { id: 'academicDegree2', tname: 'academicDegree2' },
  { id: 'namePrefix', tname: 'namePrefix' },
  { id: 'firstName', tname: 'firstName' },
  { id: 'lastName', tname: 'lastName' },
  { id: 'position', tname: 'position' },
  { id: 'role', tname: 'role' },
  { id: 'division', tname: 'division' },
  { id: 'company', tname: 'company' },
  { id: 'workUrl', tname: 'workUrl' },
  { id: 'bioText', tname: 'bioText' },
  { id: 'mobilePhone', tname: 'mobilePhone.text' },
  { id: 'workPhone', tname: 'workPhone.text' },
  { id: 'homePhone', tname: 'homePhone.text' },
  { id: 'hotlinePhone', tname: 'hotlinePhone.text' },
  { id: 'assistantName', tname: 'assistantName.text' },
  { id: 'assistantPhone', tname: 'assistantPhone.text' },
  { id: 'assistantEmail', tname: 'assistantEmail.text' },
  { id: 'assistantGeneric', tname: 'assistantGeneric.text' },
  { id: 'workFax', tname: 'workFax.text' },
  { id: 'email', tname: 'email1' },
  { id: 'workEmail', tname: 'email2' },
  { id: 'workEmail2', tname: 'workEmail2' },
  { id: 'address1_addressee', tname: 'address.addressee' },
  { id: 'address1_addressLine1', tname: 'address.addressLine1' },
  { id: 'address1_addressLine2', tname: 'address.addressLine2' },
  { id: 'address1_postCode', tname: 'address.postCode' },
  { id: 'address1_city', tname: 'address.city' },
  { id: 'address1_country', tname: 'address.country' },
  { id: 'address1_label', tname: 'address.label' },
  { id: 'address2_addressee', tname: 'address.2.addressee' },
  { id: 'address2_addressLine1', tname: 'address.2.addressLine1' },
  { id: 'address2_addressLine2', tname: 'address.2.addressLine2' },
  { id: 'address2_postCode', tname: 'address.2.postCode' },
  { id: 'address2_city', tname: 'address.2.city' },
  { id: 'address2_country', tname: 'address.2.country' },
  { id: 'address2_label', tname: 'address.2.label' },
  { id: 'costCenter', tname: 'costCenter' },
];

export type AccountBioFields = {
  academicDegree?: string | null;
  academicDegree2?: string | null;
  namePrefix?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  position?: string | null;
  role?: string | null;
  division?: string | null;
  company?: string | null;
  workUrl?: string | null;
  bioText?: string | null;
  mobilePhone?: string | null;
  workPhone?: string | null;
  homePhone?: string | null;
  hotlinePhone?: string | null;
  assistantName?: string | null;
  assistantPhone?: string | null;
  assistantEmail?: string | null;
  assistantGeneric?: string | null;
  workFax?: string | null;
  email?: string | null;
  workEmail?: string | null;
  workEmail2?: string | null;
  address1_addressee?: string | null;
  address1_addressLine1?: string | null;
  address1_addressLine2?: string | null;
  address1_postCode?: string | null;
  address1_city?: string | null;
  address1_country?: string | null;
  address1_label?: string | null;
  address2_addressee?: string | null;
  address2_addressLine1?: string | null;
  address2_addressLine2?: string | null;
  address2_postCode?: string | null;
  address2_city?: string | null;
  address2_country?: string | null;
  address2_label?: string | null;
};

export const ACCOUNT_BIO_FIELDS_KEYS = ACCOUNT_BIO_FIELDS.map(x => x.id) as Array<keyof Account>;
