import withNav from '@/infrastructure/hoc/withNav';
import { OrganisationGeneralTab } from './GeneralTab';
import { PageContainer } from '@/components';
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useTranslation } from 'react-i18next';
import { VisibilityTab } from './VisibilityTab';

const SCREEN = {
  GENERAL: 'general',
  VISIBILITY: 'visibility',
};

const Organisation = () => {
  const { t } = useTranslation();
  return (
    <PageContainer
      whiteCardWrapper
      maxWidth='m'
      sx={{ mb: 12.5 }}
      tabs={{
        orientation: 'vertical',
        tabs: [
          {
            label: t('organisation.title'),
            icon: <DesignServicesOutlinedIcon />,
            iconPosition: 'start' as const,
            value: SCREEN.GENERAL,
            component: <OrganisationGeneralTab />,
          },
          {
            label: t('visibility.title'),
            icon: <VisibilityOutlinedIcon />,
            iconPosition: 'start' as const,
            value: SCREEN.VISIBILITY,
            component: <VisibilityTab />,
          },
        ],
      }}
    />
  );
};

export default withNav(
  Organisation,
  {
    tTitle: 'organisationPageHeader',
  },
  {},
);
