import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useUnsavedStatusSetter } from '@/utils/unsavedStatus';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  IconButton,
  Typography,
} from '@mui/material';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import LtActionButtonBar from '@/components/LtActionButtonBar';
import { writeThemeConfig } from '@/infrastructure/apis/md/customization/jsonConfigs';
import { THEME_CONFIG_KEYS } from '@/shared/constants';
import {
  Concept,
  getChildren,
  getConceptKey,
  getParent,
  HIDABLE_CONCEPTS,
  HidableConceptKey,
} from '@/shared/business-logic/features/visibility/constants';
import { ExpandMoreOutlined, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { ContentWrapper } from './ContentWrapper';
import { useVisibility } from '@/infrastructure/hooks/useVisibility';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import FeatureTeaser from '../../teaser/featureTeaser';
import TooltipInfo from '@/components/TooltipInfo';

const SingleConcept = ({
  concept,
  onToggle,
  hiddenConcepts,
  prefixKey,
}: {
  concept: Concept;
  onToggle: (key: HidableConceptKey) => void;
  hiddenConcepts: HidableConceptKey[];
  prefixKey?: string;
}) => {
  const { t } = useTranslation();
  const conceptKey = getConceptKey(prefixKey, concept.key);
  const isHidden = hiddenConcepts.includes(conceptKey);

  const action = (
    <TooltipInfo text={t('controlVisibility')} onHover>
      <IconButton
        aria-checked={!isHidden}
        role='checkbox'
        onClick={() => onToggle(conceptKey)}
        aria-label={t('ariaToggleVisibilityElement', { name: t(concept.tKey) })}
      >
        {isHidden ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
      </IconButton>
    </TooltipInfo>
  );

  const hasChildren = Boolean(concept.children?.length);
  const titleBox = <Typography variant='body1'>{t(concept.tKey)}</Typography>;

  return hasChildren ? (
    <Accordion variant='outlined' sx={{ borderRadius: '0.8rem !important' }}>
      <Box display={'flex'} alignItems={'center'} px={1.6}>
        <AccordionSummary
          expandIcon={<ExpandMoreOutlined />}
          aria-controls='panel1-content'
          id='panel1-header'
          sx={{ width: '100%', p: 0, height: '5rem' }}
        >
          {titleBox}
        </AccordionSummary>
        {action}
      </Box>
      {Boolean(concept.children?.length) && (
        <AccordionDetails>
          <Box display='flex' flexDirection='column' gap={1.2}>
            {/**Recursively call "SingleConcept" component to support multiple nested children */}

            {concept.children.map(subConcept => (
              <SingleConcept
                concept={subConcept}
                onToggle={onToggle}
                hiddenConcepts={hiddenConcepts}
                prefixKey={conceptKey}
              />
            ))}
          </Box>
        </AccordionDetails>
      )}
    </Accordion>
  ) : (
    <Card
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '5rem',
        px: 1.6,
      }}
    >
      {titleBox}
      {action}
    </Card>
  );
};

export const VisibilityTab = () => {
  const { t } = useTranslation();
  const { notify: toast } = useLtNotifications();
  const { getAccessTokenSilently } = useAuth0();
  const { isFeatureAllowed } = useTierInfo();
  const isVisibilityConfigAllowed = isFeatureAllowed('visibilityConfig');

  const { setIsUnsaved } = useUnsavedStatusSetter();

  const [hiddenConcepts, setHiddenConcepts] = useState<HidableConceptKey[]>([]);
  const { hiddenConcepts: initialHiddenConcepts, mutate: mutateVisibilityConfig } = useVisibility();

  useEffect(() => {
    if (!initialHiddenConcepts) return;
    setHiddenConcepts(initialHiddenConcepts);
  }, [initialHiddenConcepts]);

  const [saveLoading, setSaveLoading] = useState(false);

  const handleToggle = (key: HidableConceptKey) => {
    const parent = getParent(key);
    const children = getChildren(key);

    let newValues = hiddenConcepts.slice();
    if (newValues.includes(key)) {
      newValues = newValues.filter(value => !value.startsWith(key) && value !== parent);
    } else {
      newValues.push(key, ...children);
    }

    const sameLevelKeys = getChildren(parent);

    if (sameLevelKeys.every(child => newValues.includes(child))) {
      newValues.push(parent);
    }

    setHiddenConcepts(newValues);
    setIsUnsaved(true);
  };

  const handleSave = async () => {
    setSaveLoading(true);
    try {
      // API call to save the hidden concepts
      await writeThemeConfig(getAccessTokenSilently, {
        key: THEME_CONFIG_KEYS.VISIBILITY_CONFIG,
        value: { hiddenConcepts },
      });
      mutateVisibilityConfig(hiddenConcepts);
      setIsUnsaved(false);
      toast.success(t('changesSaved'));
    } catch (error) {
      toast.error(t('error.general'));
    }
    setSaveLoading(false);
  };

  return (
    <>
      <ContentWrapper title={t('visibility.title')}>
        <Typography variant='body2' sx={{ display: 'block', mb: 4 }}>
          {t('visibility.description')}
        </Typography>
        <FeatureTeaser
          text={t('upgradeTeaser.feature.visibilityConfig')}
          fullWidth
          condition={!isVisibilityConfigAllowed}
        >
          {HIDABLE_CONCEPTS.map(({ hidableConcepts, key, tKey }) => (
            <Box display='flex' flexDirection='column' gap={1.2} mb={2.4} mt={2}>
              <Typography variant='h3'>{t(tKey)}</Typography>
              {hidableConcepts.map((concept: Concept) => (
                <SingleConcept
                  hiddenConcepts={hiddenConcepts}
                  concept={concept}
                  onToggle={handleToggle}
                  prefixKey={key}
                />
              ))}
            </Box>
          ))}
        </FeatureTeaser>
      </ContentWrapper>
      {isVisibilityConfigAllowed && (
        <LtActionButtonBar
          location='bottom'
          noCancelOnDesktop
          renderRelativeBox
          onSave={handleSave}
          loading={saveLoading}
        />
      )}
    </>
  );
};
