import { useAppSelector } from '@/application/hooks';
import { LtDialog, QrCodeScanner } from '@/components';
import config from '@/config/config';
import useLtNotifications from '@/infrastructure/notifications/useLtNotifications';
import { Theme } from '@/shared/types/api';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
  onClose: () => void;
  onScanFinish: (code: string) => void;
  onEnterCodeClick: () => void;
  loading: boolean;
  open: boolean;
};

const hidden = { opacity: 0, pointerEvents: 'none' };

export const Content = ({ onEnterCodeClick, onScanFinish, loading }) => {
  const theme = useAppSelector<Theme>(state => state.account.theme);
  const [scanResult, setScanResult] = useState(null);
  const [prevScan, setPrevScan] = useState(null);
  const [newScan, setNewScan] = useState(false);
  const [nextTry, setNextTry] = useState(true);
  const { t } = useTranslation();
  const { notify: toast } = useLtNotifications();

  useEffect(() => {
    if (scanResult?.data) {
      let qrString = scanResult.data;
      // domain matching
      let ltDomain = config.REACT_APP_URL.split('://')[1];
      let themeDomain = theme?.domain.split('://')[1];
      let codeToTest = '';
      if (qrString.includes(ltDomain) || qrString.includes(themeDomain)) {
        let url = new URL(qrString);
        url.pathname.length > 1 && (codeToTest = url.pathname.split('/')[1]);
      }

      if (!loading && newScan && (codeToTest !== prevScan || nextTry)) {
        if (codeToTest.length > 0) {
          onScanFinish(codeToTest);
          setPrevScan(codeToTest);
        } else {
          toast.error(t('codeRead.missed'));
          setPrevScan('');
        }
        setTimeout(() => {
          setNextTry(true);
        }, 7000);
        setNewScan(false);
      }
      setNextTry(false);
    }
  }, [scanResult, t, loading, prevScan, nextTry, newScan, theme?.domain, onScanFinish, toast]);

  return (
    <>
      <Box position='relative'>
        <Box
          sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        >
          <CircularProgress />
        </Box>
        <Box sx={loading && hidden}>
          <QrCodeScanner
            onScan={res => {
              setScanResult(res);
              setNewScan(true);
            }}
          />
        </Box>
      </Box>
      <Typography variant='body1' sx={{ mt: 2, mb: 2, whiteSpace: 'break-spaces' }}>
        <Trans
          i18nKey='cards.scanCardPopup.description'
          components={[
            <Box
              component='button'
              aria-haspopup='dialog'
              onClick={onEnterCodeClick}
              sx={{
                textDecoration: 'underline',
                cursor: 'pointer',
                color: 'primary.main',
                '&:hover': { color: 'primary.dark' },
              }}
            />,
          ]}
        />
      </Typography>
    </>
  );
};

export const ScanCardPopup = ({
  onClose,
  onEnterCodeClick,
  onScanFinish,
  loading,
  open,
}: Props) => {
  const { t } = useTranslation();
  return (
    <LtDialog
      open={open}
      title={t('cards.scanCardPopup.title')}
      onCancel={onClose}
      onClose={onClose}
    >
      <Content onEnterCodeClick={onEnterCodeClick} onScanFinish={onScanFinish} loading={loading} />
    </LtDialog>
  );
};
