import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isFileValid, isNotEmptyArray } from '@/infrastructure/helper';
import { Image } from './index';
import { Box, Button, Typography, styled } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import { SingleListImage } from '../SingleListImage';
import { extension } from 'mime-types';
import ImageCropper from '@/components/ImageCropper';

interface Props {
  images: Image[];
  onChange: (arr: Image[]) => void;
}

const UploadImage = ({ images, onChange }: Props) => {
  let fileInput = useRef(null);
  const { t } = useTranslation();
  const [showCropper, setShowCropper] = useState('');
  const [imageCount, setImageCount] = useState(0);

  const onImageSelection = async () => {
    if (isNotEmptyArray(fileInput.current?.files)) {
      let uploadedFile = fileInput.current.files[0];
      if (uploadedFile) {
        const errMsg = isFileValid(uploadedFile, 'imageWithoutSvg', t);
        if (errMsg) {
          alert(errMsg);
          return;
        }
        let reader = new FileReader();
        reader.readAsDataURL(uploadedFile);
        reader.onload = () => {
          setShowCropper(reader.result as string);
        };
      }
    }
  };

  const onUploadClick = async (croppedImageUrl: string) => {
    let blob = await fetch(croppedImageUrl).then(r => r.blob());
    let ext = extension(blob.type);
    setShowCropper('');
    const fileName = `bannerOption${imageCount}.${ext}`;
    const file = new File([blob], fileName);
    setImageCount(imageCount + 1);
    onChange([...images, { fileName, url: croppedImageUrl, file }]);
  };

  const deleteClickHandler = (img: Image) => {
    onChange(images.filter(image => image.fileName !== img.fileName));
  };

  return (
    <>
      {showCropper && (
        <ImageCropper
          image={showCropper}
          onCropClick={url => onUploadClick(url)}
          onClose={() => setShowCropper('')}
          cropOptions={{ width: 100, x: 0, y: 0, aspect: 16 / 9 }}
        />
      )}
      <Box display='flex' justifyContent='space-between' alignItems='center' gap={2} mb={2}>
        <Typography variant='body1'> {t('uploadMoreImages')} </Typography>
        <Button
          variant='outlined'
          startIcon={<AddCircleOutline />}
          color='primary'
          onClick={() => fileInput.current.click()}
        >
          {t('addCover')}
        </Button>
      </Box>
      <input
        type='file'
        ref={fileInput}
        onClick={event => {
          (event.target as HTMLInputElement).value = null;
        }}
        onChange={onImageSelection}
        style={{ display: 'none' }}
        accept='image/*'
      />
      {!images?.length && (
        <Typography variant='body2'>{t('md.profileDesign.freeToChoose')}</Typography>
      )}
      <ImagesContainer>
        {images?.map((file, index) => (
          <SingleListImage
            src={file.url}
            key={index}
            onDeleteClick={() => deleteClickHandler(file)}
          />
        ))}
      </ImagesContainer>
    </>
  );
};

const ImagesContainer = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: '20px',
  rowGap: '1rem',
});

export default UploadImage;
