import { StaticProfileConfig } from '@/shared/types/api';
import { Button, Stack } from '@mui/material';
// import CookieSettings from './CookieSettings';

interface Props {
  imprint?: StaticProfileConfig['footer']['imprint'];
  privacyPolicy?: StaticProfileConfig['footer']['privacyPolicy'];
  renderCookieSettings?: boolean;
}

export default function Policies({ imprint, privacyPolicy, renderCookieSettings }: Props) {
  return (
    <Stack direction='row' spacing={2} justifyContent='center' alignItems='center'>
      {imprint && (
        <Button variant='text' href={imprint.url} target='_blank' rel='noopener noreferrer'>
          {imprint.label}
        </Button>
      )}

      {privacyPolicy && (
        <Button variant='text' href={privacyPolicy.url} target='_blank' rel='noopener noreferrer'>
          {privacyPolicy.label}
        </Button>
      )}

      {/* TBD if cookies need to be visible from profile page */}
      {/* {renderCookieSettings && <CookieSettings />} */}
    </Stack>
  );
}
