import { routePaths } from '@/infrastructure/constants';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { ContactsOutlined, EditOutlined, HomeOutlined, SendOutlined } from '@mui/icons-material';
import { FEATURE } from '@/shared/constants';
import { NavItem } from '@/infrastructure/hoc/types';
import { useVisibility } from '../useVisibility';

export const useProfileItems = (): NavItem[] => {
  const { isFeatureAllowed } = useTierInfo();
  const { isHidden } = useVisibility();

  return [
    {
      to: routePaths.HOME,
      icon: HomeOutlined,
      text: 'home',
      hide: isHidden('nonAdmin.home'),
    },
    {
      to: routePaths.EDITPROFILE,
      icon: EditOutlined,
      text: 'editProfile',
      hide: isHidden('nonAdmin.editProfile'),
    },
    {
      to: routePaths.SHARE_PROFILE,
      icon: SendOutlined,
      text: 'shareProfile.text',
      hide: isHidden('nonAdmin.shareProfile'),
    },
    {
      to: routePaths.CONTACTS.ROOT,
      icon: ContactsOutlined,
      text: 'myContacts',
      badge: !isFeatureAllowed(FEATURE.LEAD_GEN_INDIVIDUAL) ? 'Teams' : undefined,
      hide: isHidden('nonAdmin.singleUserContacts'),
    },
  ];
};
