import { useRef, useState } from 'react';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box, Popover, Tooltip } from '@mui/material';

type Props = {
  className?: string;
  label: string;
  options: Array<
    {
      text: string;
      tooltipText?: string;
    } & MenuItemProps
  >;
  sx?: React.ComponentProps<typeof Button>['sx'];
  menuItemProps?: React.ComponentProps<typeof MenuItem>; // fixed props for every menuItem
} & React.ComponentProps<typeof Button>;

export default function LtMultiActionButton({
  className,
  label,
  options,
  sx,
  menuItemProps: fixedMenuItemProps,
  ...buttonProps
}: Props) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Box className={className} ref={anchorRef} sx={sx}>
        <Button
          endIcon={<ArrowDropDownIcon />}
          onClick={handleToggle}
          aria-haspopup='listbox'
          {...buttonProps}
        >
          {label}
        </Button>
      </Box>
      <Popover
        id='1'
        sx={{
          zIndex: 10,
        }}
        open={open}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuList id='split-button-menu' autoFocusItem>
          {options.map(({ text, tooltipText, ...menuItemProps }) => (
            <MenuItem
              key={text}
              {...fixedMenuItemProps}
              {...menuItemProps}
              onClick={
                menuItemProps.onClick
                  ? e => {
                      menuItemProps.onClick(e);
                      setOpen(false);
                    }
                  : undefined
              }
            >
              <Box
                width='100%'
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                gap={3}
              >
                {text}
                {tooltipText && (
                  <Tooltip title={tooltipText} placement='right'>
                    <HelpOutlineIcon />
                  </Tooltip>
                )}
              </Box>
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </>
  );
}
