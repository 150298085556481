import { useAppSelector } from '@/application/hooks';
import { useAppTranslation } from '@/infrastructure/hooks/useAppTranslation';
import useDeferredLoader from '@/infrastructure/hooks/useDeferredLoader';
import useDesktopView from '@/infrastructure/hooks/useDesktopView';
import usePrivateThemeConfig from '@/infrastructure/hooks/usePrivateThemeConfig';
import useTierInfo from '@/infrastructure/hooks/useTierInfo';
import { DEFAULT_AUTH_CONNECTION, THEME_CONFIG_KEYS } from '@/shared/constants';
import { Account } from '@/shared/types/api';
import {
  USER_MORE_SETTINGS_IDS,
  USER_SETTINGS_IDS,
  UserSettingsConfig,
} from '@/shared/types/global';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import SettingsItem from './components/SettingsItem';
import { translate } from './utils';
import {
  AccountCircleOutlined,
  AddCircleOutlineOutlined,
  AutoGraphOutlined,
  CookieOutlined,
  CreditCardOutlined,
  DeleteOutlineOutlined,
  HelpOutline,
  LockOutlined,
  MailOutlineOutlined,
  ShoppingCartOutlined,
  SupervisedUserCircleOutlined,
  TranslateOutlined,
} from '@mui/icons-material';
import { routePaths } from '@/infrastructure/constants';
import { getPrefilledGoogleFormLink } from '@/shared/business-logic/lib/google-forms';
import { isSSOUserEmail } from '@/shared/util';
import { usePrivateFeatureFlag } from '@/infrastructure/hooks/useFeatureFlags';
import { getIsBeprintAvailable } from '@/infrastructure/hooks/useBeprint';
import { useVisibility } from '@/infrastructure/hooks/useVisibility';

const visibilityKeyMap = {
  'nfc-cards': 'nonAdmin.home.nfcCards',
  analytics: 'nonAdmin.home.analytics',
  'add-to-homescreen': 'nonAdmin.home.addToHomescreen',
};

type ClickHandlerIDS = 'cookies' | 'change-language' | 'delete-self' | 'help' | 'addToHomeScreen';
type Props = {
  mode: 'main' | 'more';
  clickHandlers?: {
    [k in ClickHandlerIDS]?: () => void;
  };
};
const Menu = (props: Props) => {
  const { mode, clickHandlers } = props;
  const { activeLanguage } = useAppTranslation();
  const { user, getAccessTokenSilently } = useAuth0();
  const { desktopView } = useDesktopView();
  const { isStarter, isTrial, isMainAccount } = useTierInfo();
  const isMainMenu = mode === 'main';
  const IDS = isMainMenu ? USER_SETTINGS_IDS : USER_MORE_SETTINGS_IDS;
  const { config, loading } = usePrivateThemeConfig<UserSettingsConfig>(
    isMainMenu
      ? THEME_CONFIG_KEYS.USER_SETTINGS_CONFIG
      : THEME_CONFIG_KEYS.USER_MORE_SETTINGS_CONFIG,
  );
  useDeferredLoader(loading, 'settings-config-loader');

  const account = useAppSelector<Account>(state => state.account);

  const flag_iam = usePrivateFeatureFlag('iam');
  const isSSOUser = flag_iam
    ? isSSOUserEmail(account.theme, account.authEmail)
    : !(
        account?.theme?.themeInternal?.authConnection === DEFAULT_AUTH_CONNECTION ||
        (account.theme.themeInternal.ssoDomains?.length &&
          !account.theme.themeInternal.ssoDomains.includes(
            user.email?.toLowerCase().split('@')?.[1],
          ))
      );

  const hideDeleteSelf = !(isStarter || (isTrial && isMainAccount));
  const hideNfcCardUI = account?.theme?.themeInternal?.hideNfcCardUI;

  const { isVisible } = useVisibility();

  const [settingsRows, setSettingsRows] = useState<Array<React.ReactNode>>([]);
  useEffect(() => {
    const initSettingsRows = async () => {
      const settings = loading
        ? null
        : (!config || !config.items
            ? IDS.map(id => ({ id, labels: {}, captions: {} }))
            : config.override
            ? config.items
            : IDS.map(
                id => config.items.find(item => item.id === id) || { id, labels: {}, captions: {} },
              )
          ).filter(
            ({ id }) =>
              !config?.hiddenItemIds?.includes(id) &&
              (visibilityKeyMap[id] ? isVisible(visibilityKeyMap[id]) : true),
          );
      const rows: React.ReactNode[] = [];
      if (!settings) return;
      for (const setting of settings) {
        switch (setting.id) {
          case 'change-email':
            rows.push(
              <SettingsItem
                text={translate(setting.labels, 'settings.changeEmail')}
                caption={translate(setting.captions, null, user.email)}
                icon={<MailOutlineOutlined sx={{ color: 'primary.light' }} />}
                link={routePaths.SETTINGS.AUTH}
                hide={isSSOUser}
              />,
            );
            break;
          case 'change-password':
            rows.push(
              <SettingsItem
                text={translate(setting.labels, 'settings.changePassword')}
                caption={translate(setting.captions)}
                icon={<LockOutlined sx={{ color: 'primary.light' }} />}
                link={routePaths.CHANGEPASSWORD}
                hide={isSSOUser}
              />,
            );
            break;
          case 'cookies':
            rows.push(
              <SettingsItem
                text={translate(setting.labels, 'settings.openCookieSettings')}
                caption={translate(setting.captions)}
                icon={<CookieOutlined sx={{ color: 'primary.light' }} />}
                onClick={clickHandlers?.['cookies']}
              />,
            );
            break;
          case 'change-language':
            rows.push(
              <SettingsItem
                text={translate(setting.labels, 'settings.changeLanguage')}
                caption={translate(
                  setting.captions,
                  null,
                  activeLanguage?.startsWith('de') ? 'Deutsch' : 'English',
                )}
                icon={<TranslateOutlined sx={{ color: 'primary.light' }} />}
                onClick={clickHandlers?.['change-language']}
              />,
            );
            break;
          case 'delete-self':
            rows.push(
              <SettingsItem
                text={translate(setting.labels, 'deleteAccount.title')}
                caption={translate(setting.captions)}
                icon={<DeleteOutlineOutlined sx={{ color: 'primary.light' }} />}
                onClick={clickHandlers?.['delete-self']}
                hide={hideDeleteSelf}
              />,
            );
            break;
          case 'management-dashboard':
            rows.push(
              <SettingsItem
                text={translate(setting.labels, 'moreSettings.teamMangement')}
                caption={translate(setting.captions, 'moreSettings.teamMangementCaption')}
                icon={<SupervisedUserCircleOutlined sx={{ color: 'primary.light' }} />}
                link={routePaths.MD.PROFILES}
              />,
            );
            break;
          case 'nfc-cards':
            rows.push(
              <SettingsItem
                text={translate(setting.labels, 'moreSettings.nfcCards')}
                caption={translate(setting.captions, 'moreSettings.nfcCardsCaption')}
                icon={<CreditCardOutlined sx={{ color: 'primary.light' }} />}
                link={routePaths.NFC_CARDS}
              />,
            );
            break;
          case 'analytics':
            rows.push(
              <SettingsItem
                text={translate(setting.labels, 'moreSettings.analytics')}
                caption={translate(setting.captions, 'moreSettings.analyticsCaption')}
                icon={<AutoGraphOutlined sx={{ color: 'primary.light' }} />}
                link={routePaths.ANALYTICS}
                hide={hideNfcCardUI}
              />,
            );
            break;
          case 'add-to-homescreen':
            rows.push(
              <SettingsItem
                text={translate(setting.labels, 'moreSettings.addApp')}
                caption={translate(setting.captions, 'moreSettings.addAppCaption')}
                icon={<AddCircleOutlineOutlined sx={{ color: 'primary.light' }} />}
                onClick={clickHandlers?.['addToHomeScreen']}
              />,
            );
            break;
          case 'help':
            if (desktopView) break; // dropdown cannot be opened on desktop currently
            rows.push(
              <SettingsItem
                text={translate(setting.labels, 'settings.help')}
                caption={translate(setting.captions, 'settings.helpCaption')}
                icon={<HelpOutline sx={{ color: 'primary.light' }} />}
                onClick={clickHandlers?.['help']}
              />,
            );
            break;
          case 'account-settings':
            rows.push(
              <SettingsItem
                text={translate(setting.labels, 'moreSettings.accountSettings')}
                caption={translate(setting.captions, 'moreSettings.accountSettingsCaption')}
                icon={<AccountCircleOutlined sx={{ color: 'primary.light' }} />}
                link={routePaths.SETTINGS.MAIN}
              />,
            );
            break;
          case 'prefilled-form-link':
            rows.push(
              'googleFormConfig' in setting && (
                <SettingsItem
                  text={translate(setting.labels, 'moreSettings.printCard')}
                  caption={translate(setting.captions, 'moreSettings.printCardCaption')}
                  icon={<ShoppingCartOutlined sx={{ color: 'primary.light' }} />}
                  external
                  link={getPrefilledGoogleFormLink(
                    setting.googleFormConfig,
                    account,
                    account.theme,
                  )}
                />
              ),
            );
            break;
          case 'external-link':
            rows.push(
              'linkConfig' in setting && (
                <SettingsItem
                  text={translate(setting.labels)}
                  caption={translate(setting.captions)}
                  icon={<ShoppingCartOutlined sx={{ color: 'primary.light' }} />}
                  external
                  link={setting.linkConfig.url}
                />
              ),
            );
            break;
          case 'paper-card-order-link':
            if (!(await getIsBeprintAvailable(getAccessTokenSilently)).isUserOrderAvailable) break;
            rows.push(
              <SettingsItem
                text={translate(setting.labels, 'settings.paperCardOrder')}
                caption={translate(setting.captions, 'settings.paperCardOrderCaption')}
                icon={<ShoppingCartOutlined sx={{ color: 'primary.light' }} />}
                link={routePaths.PAPER_CARDS}
              />,
            );
            break;
          default:
            break;
        }
      }
      setSettingsRows(rows);
    };
    initSettingsRows();
  }, [
    IDS,
    account,
    activeLanguage,
    clickHandlers,
    config,
    desktopView,
    getAccessTokenSilently,
    hideDeleteSelf,
    hideNfcCardUI,
    isSSOUser,
    isVisible,
    loading,
    user?.email,
  ]);

  return <>{settingsRows}</>;
};

export default Menu;
