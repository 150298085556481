import { Auth0ContextInterface } from '@auth0/auth0-react';
import { withSilentAccessToken } from '../helper';
import axios from 'axios';
import { PERMISSIONS } from '../constants';
import config from '@/config/config';
import { ApiFile } from '@/shared/types/api';

export const getFiles = (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  query?: { unitId?: number | null; applyHierarchy?: boolean },
) => {
  return withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.get(config.API_BASE_URL + 'theme/global-files', {
        params: query,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.READ.THEME_PROFILES],
  );
};

export const updateFiles = async (
  getAccessTokenSilently: Auth0ContextInterface['getAccessTokenSilently'],
  files: ApiFile[],
  unitId?: number | null,
) => {
  return withSilentAccessToken(
    getAccessTokenSilently,
    token =>
      axios.post(`${config.API_BASE_URL}theme/global-files`, files, {
        params: { unitId },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    [PERMISSIONS.WRITE.THEME_PROFILES],
  );
};
