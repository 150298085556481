import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';
import { Box, styled } from '@mui/material';

// TODO: profile page optimizations
// adapt resemblence

const StaticProfileSkeletonPage = () => {
  return (
    <SkeletonWrapper>
      <BannerSkeleton animation='wave' variant='rectangular' />
      <HeaderSkeleton>
        <CardHeader
          avatar={<Skeleton animation='wave' variant='circular' width={105} height={105} />}
        />
        <HeaderContentSkeleton>
          <React.Fragment>
            <Skeleton variant='text' width={200} height={20} />
            <Skeleton variant='text' width={130} height={15} />
            <Skeleton variant='text' width={100} height={15} />

            <HeaderButtonSkeleton variant='rectangular' width={150} height={32} />
          </React.Fragment>
        </HeaderContentSkeleton>
      </HeaderSkeleton>
      <ContentSkeleton>
        <CardContent>
          <React.Fragment>
            {/* <p>My Contact Details</p> */}

            <Skeleton width={150} height={20} />
            <Skeleton height={12} />
            <Skeleton height={12} />
            <Skeleton height={12} />
            <Skeleton height={12} />
          </React.Fragment>
        </CardContent>
      </ContentSkeleton>
      <ContentSkeleton>
        <CardContent>
          <React.Fragment>
            {/* <p>My Links</p> */}
            <Skeleton width={150} height={20} />
            <LinkSkeleton variant='rectangular' height={35} />
            <LinkSkeleton variant='rectangular' height={35} />
          </React.Fragment>
        </CardContent>
      </ContentSkeleton>
    </SkeletonWrapper>
  );
};

export default StaticProfileSkeletonPage;

const SkeletonWrapper = styled(Box)({
  /* OVERRIDES */
  '& .MuiCardHeader-root': {
    position: 'relative !important',
    top: '-70px !important',
  },

  '& .MuiCardHeader-avatar': {
    span: {
      border: '5px solid #fff !important',
      background: 'lightgray',
      left: '10px !important',
    },
  },

  '& .MuiCardContent-root': {
    padding: ' 20px !important',
  },
});

const BannerSkeleton = styled(Skeleton)({
  margin: '0 auto',
  maxWidth: '527px',
  maxHeight: '293px',
  height: '293px !important',
  zIndex: 1,
  borderBottomLeftRadius: '12px',
  borderBottomRightRadius: '12px',
});

const HeaderSkeleton = styled(Card)({
  margin: '0 auto',
  maxWidth: '501px',
  height: '180px !important',
  maxHeight: '180px',
  position: 'relative',
  background: '#ffffff',
  zIndex: 10,
  top: '-10rem',
  borderRadius: '10px !important',
  overflow: 'visible !important',
});

const HeaderButtonSkeleton = styled(Skeleton)({
  borderRadius: '10px',
  top: '-35px !important',
  right: '20px !important',
  margin: 0,
  position: 'absolute',
});

const HeaderContentSkeleton = styled(CardContent)({
  margin: '0 auto',
  maxWidth: '501px',
  position: 'relative',
  top: '-80px !important',
  marginTop: '0px !important',
  marginBottom: '0 !important',
  borderRadius: '10px !important',
  minHeight: '110px !important',
});

const ContentSkeleton = styled(Card)({
  margin: '0 auto',
  maxWidth: '501px',
  position: 'relative',
  top: '-100px !important',
  marginTop: '16px !important',
  marginBottom: '16px !important',
  borderRadius: '10px !important',
  minHeight: '110px !important',
});

const LinkSkeleton = styled(Skeleton)({
  borderRadius: '30px',
  margin: '5px 0px !important',
});
