import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { getContactFormTranslationKeyByField } from '@/shared/util';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  FormControlLabel,
  ListItemText,
  Switch,
  styled,
} from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { ContactFormField } from '@/shared/types/global';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

type Fields = {
  shown: ContactFormField[];
  notShown: ContactFormField[];
};

const titleMapT = {
  shown: 'contactFormEdit.shown',
  notShown: 'contactFormEdit.notShown',
};

const SingleDroppableColumn = ({
  fieldKey,
  fields,
  showRequiredSwitch,
  onChange,
}: {
  fieldKey: string;
  fields: ContactFormField[];
  showRequiredSwitch?: boolean;
  onChange?: (fields: ContactFormField[]) => void;
}) => {
  const handleRequiredToggle = (index: number) => {
    const field = fields[index];
    if (!field) return;
    const newFields = fields
      .slice(0, index)
      .concat({ name: field.name, required: !field.required })
      .concat(fields.slice(index + 1));
    onChange(newFields);
  };

  const { t } = useTranslation();
  return (
    <Droppable droppableId={fieldKey}>
      {(provided, snapshot) => (
        <Card sx={{ height: '100%' }}>
          <CardHeader
            titleTypographyProps={{ variant: 'h3' }}
            title={t(titleMapT[fieldKey as keyof typeof titleMapT])}
          />
          <Divider />
          <DroppableContainer ref={provided.innerRef} {...provided.droppableProps}>
            {fields.map(({ name, required }, index) => (
              <Draggable key={name} draggableId={name} index={index}>
                {(provided, snapshot) => (
                  <StyledListItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    onMouseDown={e => e.target.focus()}
                    id={`dnd-item-${name}`}
                  >
                    <DragIndicatorIcon sx={{ mr: 1 }} />
                    <ListItemText primary={t(getContactFormTranslationKeyByField(name))} />
                    {showRequiredSwitch && (
                      <FormControlLabel
                        sx={{ ml: 'auto' }}
                        control={
                          <Switch
                            onFocus={e => e.stopPropagation()}
                            checked={required}
                            onChange={() => handleRequiredToggle(index)}
                          />
                        }
                        label={t('contactFormEdit.required')}
                      />
                    )}
                  </StyledListItem>
                )}
              </Draggable>
            ))}

            {provided.placeholder}
          </DroppableContainer>
        </Card>
      )}
    </Droppable>
  );
};

interface Props {
  fields: Fields;
  onFieldsChange: (fields: Fields) => void;
}

const ContactFieldsSorter = ({ fields, onFieldsChange }: Props) => {
  const handleDragEnd = (result: any) => {
    if (!result.source || !result.destination) {
      // dragged from/to wrong pane or to outside any pane
      return;
    }
    if (
      result.source.droppableId === result.destination.droppableId &&
      result.source.index === result.destination.index
    )
      return;

    const sourceArr = fields[result.source.droppableId as keyof typeof fields].slice();
    const sourceIndex = result.source.index;
    const [removed] = sourceArr.splice(sourceIndex, 1);

    const destinationArr =
      result.source.droppableId === result.destination.droppableId
        ? sourceArr
        : fields[result.destination.droppableId as keyof typeof fields].slice();
    const destinationIndex = result.destination.index;
    destinationArr.splice(destinationIndex, 0, removed);

    const newFields = {
      ...fields,
      [result.source.droppableId]: sourceArr,
      [result.destination.droppableId]: destinationArr,
    };

    onFieldsChange({
      shown: newFields.shown,
      notShown: newFields.notShown?.map(({ name }) => ({ name })),
    });
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Box display='flex' gap={2}>
        <Box flex={1}>
          <SingleDroppableColumn
            fieldKey='shown'
            fields={fields.shown}
            showRequiredSwitch
            onChange={shownFields => onFieldsChange({ ...fields, shown: shownFields })}
          />
        </Box>
        <Box>
          <ChevronLeft sx={{ display: 'block', mt: 2 }} />
          <ChevronRight sx={{ display: 'block', mt: 2 }} />
        </Box>
        <Box flex={1}>
          <SingleDroppableColumn fieldKey='notShown' fields={fields.notShown} />
        </Box>
      </Box>
    </DragDropContext>
  );
};

export default ContactFieldsSorter;

const DroppableContainer = styled(Box)({
  width: '100%',
  height: '100%',
});

const StyledListItem = styled('li', {
  shouldForwardProp: () => true,
})(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#fff',
  minHeight: '4.8rem',
  padding: '0 1.6rem',
  cursor: 'grab',
  wordWrap: 'break-word',
  hyphens: 'auto',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  position: 'relative',
  ':after': {
    content: '""',
    position: 'absolute',
    width: 'calc(100% + 2px)',
    height: 'calc(100% + 2px)',
    top: '-1px',
    left: '-1px',
    border: `1px solid ${theme.palette.divider}`,
    pointerEvents: 'none',
  },
  '&:focus': {
    backgroundColor: theme.palette.action.hover,
  },
}));
