import QrScanner from 'qr-scanner';
import { useState, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import vCard from 'vcard-parser';

const MINIMUM_CONFIDENCE = 15;

export const mockData = {
  firstName: 'string',
  lastName: 'string',
  title: 'string',
  birthday: new Date().toISOString(),
  position: 'string',
  role: 'string',
  company: 'string',

  division: 'string',
  email: 'string',
  workEmail: 'string',
  workPhone: 'string',
  mobilePhone: 'string',
  address: 'string',
  postCode: 'string',
  city: 'string',
  country: 'string',
  notes: 'string',
} as const;

export const ASPECT_RATIO = 1.414;
export const PAGE_CONTAINER_SELECTOR = '.scanner-page-container';
//10mb
export const MAX_FILE_SIZE = 10 * 1024 * 1024;

export const MAX_FILE_SELECT_SIZE = 2;

const FramePaddingOffset = 24;

const filterEmpty = (data: Record<string, any>) => {
  return Object.fromEntries(Object.entries(data).filter(([_, value]) => Boolean(value)));
};

export const transformScanResult = data => filterEmpty(data);

export const useFrameDimensions = () => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  useEffect(() => {
    const container = document.querySelector(PAGE_CONTAINER_SELECTOR);
    const deviceWidth = container?.clientWidth || 0;
    const frameWidth = deviceWidth - FramePaddingOffset * 2;
    const frameHeight = frameWidth / ASPECT_RATIO;
    setDimensions({ width: frameWidth, height: frameHeight });
  }, []);

  return dimensions;
};

export enum CameraAvailabilityEnum {
  NOT_AVAILABLE,
  AVAILABLE,
  LOADING,
}

export const getCameras = async (): Promise<MediaDeviceInfo[]> => {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const cameras = devices.filter(x => x.kind === 'videoinput');
    return cameras;
  } catch (error) {
    return [];
  }
};

export const getStream = async (id?: string | null) => {
  const stream = await navigator.mediaDevices.getUserMedia({
    video: {
      deviceId: id ? { exact: id } : undefined,
      facingMode: id ? undefined : 'environment',
      width: { ideal: 1920 },
    },
    audio: false,
  });
  return stream;
};

export const stopStream = (stream?: MediaStream | null) => {
  if (!stream) return;
  stream.getTracks().forEach(track => track.stop());
};

export const getTorchAvailability = (stream?: MediaStream | null): boolean => {
  if (!stream) return false;
  try {
    const tracks = stream.getTracks();
    for (const track of tracks) {
      const caps = track.getCapabilities();
      //@ts-ignore
      if (caps.torch) return true;
    }
  } catch (error) {
    return false;
  }
};

type MediaTrackConstraintsSetWithTorch = MediaTrackConstraintSet & { torch?: boolean };

const isTorchOnForTrack = (track: MediaStreamTrack): boolean => {
  try {
    const caps = track.getCapabilities();
    //@ts-ignore
    if (!caps.torch) return false;

    return !!track
      .getConstraints()
      .advanced?.some((adv: MediaTrackConstraintsSetWithTorch) => adv.torch);
  } catch (error) {
    return false;
  }
};

export const toggleTorch = (stream?: MediaStream | null) => {
  if (!stream) return;
  const tracks = stream.getTracks();
  try {
    for (const track of tracks) {
      const isTorchEnabled = isTorchOnForTrack(track);
      if (isTorchEnabled) {
        //@ts-ignore
        track.applyConstraints({ advanced: [{ torch: false }] });
      } else {
        //@ts-ignore
        track.applyConstraints({ advanced: [{ torch: true }] });
      }
    }
  } catch (error) {
    // do nothing
  }
};

const getImage = async (dataUrl: string): Promise<HTMLImageElement> => {
  const img = new Image();
  img.src = dataUrl;
  return await new Promise(resolve => {
    img.onload = () => {
      resolve(img);
    };
    img.onerror = () => {
      resolve(null);
    };
  });
};

export const getCropResults = async (dataUrl, boundingBox) => {
  const img = await getImage(dataUrl);
  if (!img) return;

  const x = boundingBox.x * img.width;
  const y = boundingBox.y * img.height;
  const width = boundingBox.width * img.width;
  const height = boundingBox.height * img.height;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = width;
  canvas.height = height;
  ctx.drawImage(img, x, y, width, height, 0, 0, width, height);
  const blob = await new Promise<Blob>(res => canvas.toBlob(blob => res(blob)));

  const croppedFile = new File([blob], `${uuid()}.jpg`, { type: 'image/jpeg' });

  const croppedDataUrl = canvas.toDataURL('image/jpeg');
  canvas.remove();
  return { croppedFile, croppedDataUrl };
};

const mapper = (fieldArr: { value: string }) => fieldArr.value;

export const extractFromVcard = (result: string) => {
  try {
    const res = vCard.parse(result);
    const {
      n = [],
      tel = [],
      email: eml = [],
      url = [],
      bday = [],
      adr = [],
      org = [],
      title = [],
      lang = [],
    } = res;
    const [[firstName = null, lastName = null] = []] = n.map(mapper);
    const [birthday = null] = bday.map(mapper);
    const phones = tel.map(mapper);
    const emails = eml.map(mapper);
    const urls = url.map(mapper);
    const [
      [
        ,
        addressLine2 = null,
        addressLine1 = null,
        city = null,
        ,
        postCode = null,
        country = null,
      ] = [],
    ] = adr.map(mapper);
    const [company = null] = org.map(mapper);
    const [position = null] = title.map(mapper);
    const [preferredLang = null] = lang.map(mapper);

    const out = {
      firstName,
      lastName,
      company,
      position,
      birthday,
      addressLine1,
      addressLine2,
      city,
      postCode,
      country,
      preferredLang,
    };

    return {
      ...Object.entries(out).reduce(
        (prev, [curKey, curVal]) =>
          //Always assume that data returned from vcard is correct
          Boolean(curVal) ? { ...prev, [curKey]: { value: curVal, confidence: 99.99 } } : prev,
        {},
      ),
      emails,
      phones,
      urls,
    };
  } catch (error) {
    return null;
  }
};

export const isVCard = (scanResult: string) => scanResult?.startsWith('BEGIN:VCARD');

export const scanQrCodeIfPresent = async (file: File): Promise<string | null> => {
  try {
    const { data } = await QrScanner.scanImage(file, { returnDetailedScanResult: true });
    return data;
  } catch (error) {
    return null;
  }
};

type Attribute = {
  value: string;
  confidence?: number;
};

export const mergeAttributes = (dataArr: Record<string, Attribute | string[]>[]) => {
  const emails = [];
  const phones = [];
  const urls = [];

  const otherAttributes: Record<string, Attribute> = {};

  for (const data of dataArr) {
    const { emails: emailsArr, phones: phonesArr, urls: urlsArr, ...rest } = data;
    emails.push(...((emailsArr || []) as []));
    phones.push(...((phonesArr || []) as []));
    urls.push(...((urlsArr || []) as []));

    const otherAttributesValues = rest as Record<string, Attribute>;

    for (const [key, value] of Object.entries(otherAttributesValues)) {
      if (value.confidence && value.confidence < MINIMUM_CONFIDENCE) continue;
      if (otherAttributes[key]) {
        if (value.confidence > otherAttributes[key].confidence) {
          otherAttributes[key] = value;
        }
      } else {
        otherAttributes[key] = value;
      }
    }
  }

  const [email, workEmail] = emails;
  const [workPhone, mobilePhone] = phones;
  const [website] = urls;

  const result = Object.entries(otherAttributes).reduce((prev, [curKey, curVal]) => {
    if (curVal?.value) {
      return { ...prev, [curKey]: curVal.value };
    }
    return prev;
  }, {});

  return filterEmpty({
    ...result,
    email,
    workEmail,
    workPhone,
    mobilePhone,
    website,
  });
};
