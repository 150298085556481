import { LOCAL_STORAGE } from '@/infrastructure/constants';
import { ColumnDefinition } from '@/views/pages/md/profiles/table/utils/constants';

export const CONTACTS_TABLE_COLUMNS: ColumnDefinition[] = [
  { key: 'createdOn', tkey: 'createdOn' },
  { key: 'position', tkey: 'position' },
  { key: 'company', tkey: 'company' },
  { key: 'email', tkey: 'email1' },
  { key: 'notes', tkey: 'notes', hidden: true },
  { key: 'workPhone', tkey: 'workPhone.text', hidden: true },
  { key: 'namePrefix', tkey: 'namePrefix', hidden: true },
  { key: 'academicDegree', tkey: 'academicDegree', hidden: true },
  { key: 'firstName', tkey: 'firstName', hidden: true },
  { key: 'lastName', tkey: 'lastName', hidden: true },
  { key: 'role', tkey: 'role', hidden: true },
  { key: 'division', tkey: 'division', hidden: true },
  { key: 'workEmail', tkey: 'workEmail', hidden: true },
  { key: 'mobilePhone', tkey: 'mobilePhone.text', hidden: true },
  { key: 'addressee', tkey: 'address.addressee', hidden: true },
  { key: 'addressLine1', tkey: 'address.addressLine1', hidden: true },
  { key: 'addressLine2', tkey: 'address.addressLine2', hidden: true },
  { key: 'postCode', tkey: 'address.postCode', hidden: true },
  { key: 'city', tkey: 'address.city', hidden: true },
  { key: 'country', tkey: 'address.country', hidden: true },
  { key: 'website', tkey: 'website', hidden: true },
  { key: 'birthday', tkey: 'birthday', hidden: true },
  { key: 'gdprLegalBasis', tkey: 'gdpr', hidden: true },
];

export const MD_CONTACTS_TABLE_COLUMNS: ColumnDefinition[] = [
  { key: 'createdOn', tkey: 'mdLeads.metOn' },
  { key: 'position', tkey: 'position' },
  { key: 'company', tkey: 'company' },
  { key: 'email', tkey: 'email1' },
  { key: 'metBy', tkey: 'mdLeads.metBy' },
  { key: 'namePrefix', tkey: 'namePrefix', hidden: true },
  { key: 'academicDegree', tkey: 'academicDegree', hidden: true },
  { key: 'role', tkey: 'role', hidden: true },
  { key: 'division', tkey: 'division', hidden: true },
  { key: 'workEmail', tkey: 'workEmail', hidden: true },
  { key: 'workPhone', tkey: 'workPhone.text', hidden: true },
  { key: 'mobilePhone', tkey: 'mobilePhone.text', hidden: true },
  { key: 'addressee', tkey: 'address.addressee', hidden: true },
  { key: 'addressLine1', tkey: 'address.addressLine1', hidden: true },
  { key: 'addressLine2', tkey: 'address.addressLine2', hidden: true },
  { key: 'postCode', tkey: 'address.postCode', hidden: true },
  { key: 'city', tkey: 'address.city', hidden: true },
  { key: 'country', tkey: 'address.country', hidden: true },
  { key: 'notes', tkey: 'notes', hidden: true },
  { key: 'website', tkey: 'website', hidden: true },
  { key: 'birthday', tkey: 'birthday', hidden: true },
  { key: 'gdprLegalBasis', tkey: 'gdpr', hidden: true },
];

//Keys that are not dependent on the allowed keys
const MD_ADDITIONAL_KEYS = ['createdOn', 'metBy'];

export const initCols = (
  allowedKeys: string[],
  columnsDefault: ColumnDefinition[],
  localStorageKey: string,
) => {
  const res = localStorage.getItem(LOCAL_STORAGE[localStorageKey]);
  const uniqueAllowedKeys = Array.from(new Set(allowedKeys));
  uniqueAllowedKeys.push('createdOn');

  const columns: ColumnDefinition[] = res ? JSON.parse(res) : columnsDefault;

  return columns.filter(x => uniqueAllowedKeys.includes(x.key));
};

export const initColumnsDef = (allowedKeys: string[]) =>
  initCols(allowedKeys, CONTACTS_TABLE_COLUMNS, LOCAL_STORAGE.ltContactsTableColumns);

export const initMdColumnsDef = (allowedKeys: string[]) =>
  initCols(
    [...allowedKeys, ...MD_ADDITIONAL_KEYS],
    MD_CONTACTS_TABLE_COLUMNS,
    LOCAL_STORAGE.ltMdContactsTableColumns,
  );

export const saveContactsTableColumns = (cols, localStorageKey) => {
  localStorage.setItem(localStorageKey, JSON.stringify(cols));
};

export const resetCols = (
  allowedKeys: string[],
  columnsDefault: ColumnDefinition[],
  localStorageKey: string,
) => {
  localStorage.removeItem(LOCAL_STORAGE[localStorageKey]);
  return initCols(allowedKeys, columnsDefault, localStorageKey);
};

export const resetMdCols = (allowedKeys: string[]) =>
  resetCols(
    [...allowedKeys, ...MD_ADDITIONAL_KEYS],
    MD_CONTACTS_TABLE_COLUMNS,
    LOCAL_STORAGE.ltMdContactsTableColumns,
  );

export const resetColumnsDef = (allowedKeys: string[]) =>
  resetCols(allowedKeys, CONTACTS_TABLE_COLUMNS, LOCAL_STORAGE.ltContactsTableColumns);
