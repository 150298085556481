import { Box, styled } from '@mui/material';
import { useLayoutEffect, useRef, useState } from 'react';
import EditBanner from './edit';
import { ProfileEditProps } from '../../types';
import { debounce } from '@/utils/debounce';

const BOTTOM_INSET_PERCENTAGE = 0.25;

interface Props {
  bannerImgUrl: string | null; // if null or '' no banner image is rendered;
  editProps?: ProfileEditProps['bannerImageEditProps'];
  renderBottomInset?: boolean;
}

export default function Banner({ bannerImgUrl, editProps, renderBottomInset }: Props) {
  const renderImage = !!bannerImgUrl;
  const isEdit = !!editProps;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const [imageHeight, setImageHeight] = useState<number>(null); //px
  useLayoutEffect(() => {
    const adjustAspectRatio = () => {
      if (wrapperRef.current) {
        const { width } = wrapperRef.current.getBoundingClientRect();
        setImageHeight((width * 9) / 16); // keep 16/9 aspect ratio fixed
      }
    };
    adjustAspectRatio();
    window.addEventListener('resize', debounce(adjustAspectRatio, 500));
    return () => window.removeEventListener('resize', adjustAspectRatio);
  }, []);

  return (
    <>
      <Wrapper ref={wrapperRef}>
        {renderImage ? (
          <BannerImage
            component='img'
            src={bannerImgUrl}
            aria-hidden='true'
            sx={{ height: imageHeight ? `${imageHeight}px` : undefined }}
          />
        ) : (
          <Placeholder />
        )}

        {isEdit && (
          <EditWrapper>
            <EditBanner {...editProps} />
          </EditWrapper>
        )}
      </Wrapper>
      {renderBottomInset && imageHeight && (
        <Box sx={{ mb: `${-1 * BOTTOM_INSET_PERCENTAGE * imageHeight}px` }} />
      )}
    </>
  );
}

const Wrapper = styled(Box)({
  position: 'relative',
  width: '100%',
});

const BannerImage: typeof Box = styled(Box)({
  width: '100%',
  objectFit: 'cover',
});

const Placeholder = styled(Box)(({ theme }) => ({
  height: theme.spacing(15),
  width: '100%',
}));

const EditWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
}));
